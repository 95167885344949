import React, { useState,useEffect,useRef } from 'react';
import Layout from "./Components/Layout";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link,useNavigate } from "react-router-dom";
import { useLocalStorage } from './useLocalStorage';
import Button from 'react-bootstrap/Button';
import imgLupa from '../src/img/Home/lupa.svg';
import imgSend from '../src/img/Home/send.svg';
import Calendar from './Components/Calendar';
import imgBack from '../src/img/CrearGrupo/arrow_left.svg';
import imgGym from '../src/img/CrearGrupo/gym.png';
import imgBicii from '../src/img/CrearGrupo/bicii.png';
import SubirFoto from './Components/SubirFoto';
import {FaCamera} from 'react-icons/fa'; // npm i react-icons
import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import LoadingSpinner from "../src/Components/LoadingSpinner";
import EventosBusqueda from './Components/EventosBusqueda';
import CanvasFoto from './Components/CanvasFoto';
import CanvasFoto2 from './Components/CanvasFoto2';



function MiPerfil(){

const navigate = useNavigate();
let itop = 0;

const [items2, setItems2] = useState();
const [counter, setCounter] = useState(0);
const [isLoading, setIsLoading] = useState(false);
const [isVisible, setIsVisble] = useState('');
const [caractetisticas, setcaractetisticas] = useState();
const [vcaracteristica, setvcaracteristica] = useLocalStorage('vcaracteristica',[]);
const [vWidth, setWidth] = useState('');  
const [vHeight, setHeight] = useState('');
const [imagenSeleccionadaPerfilGrupo, setimagenSeleccionadaPerfilGrupo] = useLocalStorage('imagenSeleccionadaPerfilGrupo','');
const [imagenSeleccionadaPerfilGrupoADM, setimagenSeleccionadaPerfilGrupoADM] = useLocalStorage('imagenSeleccionadaPerfilGrupoADM','');
const [imagen, setImagen] = useLocalStorage('imagen',[]);
const [imagen2, setImagen2] = useLocalStorage('imagen2',[]);
const [vnombregrupo, setvnombregrupo] = useLocalStorage('vnombregrupo','');
const [perfil, setPerfil] = useState('11');
const [numerodocumento, setnumerodocumento] = useState('');
const [nombre, setNombre] = useState('');
const [apellido, setApellido] = useState('');
const [celular, setCelular] = useState('');
const [correo, setCorreo] = useState('');
const [contraseña, setContraseña] = useState('');
const [tipodocumento, settipodocumento] = useState('1');
const [idusuario, setIdusuario] = useLocalStorage('idusuario','')
const [contraseniaOld, setContraseniaOld] = useState('');


    useEffect(() => {
        if(idusuario != '')
        {
        ObtenerInfoUsuario(idusuario);
        }
    },[idusuario])


    const handleRedirectHome = () =>{

        navigate(-1);
    
      }

    
   function formatCadena(cadena)
   {
      let newValor = cadena.replace(/[^A-Za-z ñ]/,"");
      //console.log(newValor);
  
      return newValor;
   }

    /*Funciones de imagenes*/

    function niceBytes(a)
    {
      var marker = 1024; // Change to 1000 if required
      var decimal = 3; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1
      return(a / megaBytes).toFixed(decimal);
    }
  
   async function convertImage(e)
  {
  
      const files = e.target.files;
      const filePeso = 0;
  
      for (let index = 0; index < files.length; index++) {
          const reader = new FileReader();
      let nWidth = 0;
      let nHeigth = 0;
      
      
      let src = URL.createObjectURL(e.target.files[index]);
      const divcanvas = document.getElementById('wimagen');
      //reader.readAsDataURL(e.target.files[0]);
  
      if(niceBytes(e.target.files[index].size) >= 32.0)
      {
          alert("Imagen excede el tamaño permitido. El peso debe ser menor a 32Mb.")
          return;
      }
  
      reader.readAsDataURL(e.target.files[index]);
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          const {
            height,
            width
          } = image;
  
          setHeight(height);
          setWidth(width);
          if(src.length > 1)
          {
            setImagen(imagen => [...imagen,{ id:Math.random() + index,src:src, height:height, width: width}])

          }
        };
      };  
      }
      
      const fileAnun = document.getElementById('fileAnuncio');
      fileAnun.value = '';

      
  }

  async function convertImage2(e)
  {
  
      const files = e.target.files;
      const filePeso = 0;
  
      for (let index = 0; index < files.length; index++) {
          const reader = new FileReader();
      let nWidth = 0;
      let nHeigth = 0;
      
      
      let src = URL.createObjectURL(e.target.files[index]);
      const divcanvas = document.getElementById('wimagen');
      //reader.readAsDataURL(e.target.files[0]);
  
      console.log(niceBytes(e.target.files[index].size));
  
      if(niceBytes(e.target.files[index].size) >= 32.0)
      {
          alert("Imagen excede el tamaño permitido. El peso debe ser menor a 32Mb.")
          return;
      }
  
      reader.readAsDataURL(e.target.files[index]);
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          const {
            height,
            width
          } = image;
  
          setHeight(height);
          setWidth(width);
          if(src.length > 1)
          {

            setImagen2(imagen2 => [...imagen2,{ id:Math.random() + index,src:src, height:height, width: width}])
            
          }
        };
      };  
      }
      
      const fileAnun = document.getElementById('fileAnuncio2');
      fileAnun.value = '';
      
  }
  

  const fileInputRef = useRef(null);
  const handleOpenFileClick = () => {
    fileInputRef.current.click();
  };

  const fileInputRef2 = useRef(null);
  const handleOpenFileClick2 = () => {
    fileInputRef2.current.click();
  };

  const handleSubmit = (e) => {
    
    e.preventDefault();
    RegistroAcceso(perfil,numerodocumento,nombre,apellido,celular,correo, contraseña);
    
 }; 

 const RegistroAcceso = async (perfil,numerodocumento,nombre,apellido,celular,correo, contraseña) => {


    if(tipodocumento == '')
    {
        alert("Debe seleccionar un tipo de documento.");
        return;
    }

    if(numerodocumento == '')
    {
        return;
    }


    const data = JSON.stringify({
        perfil : 11,numerodocumento: numerodocumento,nombre : nombre,apellido : apellido,celular : celular,correo:correo, contrasenia:contraseña,tipodocumento:tipodocumento,tipousuario:1,idusuarios:idusuario,
       })


    await fetch('https://conectate-peru.com/api/ApiActualizacionUsuario.php', {
       method: 'POST',
       body: data,
       headers: {
          'Content-type': 'application/json; charset=UTF-8',
       },
    })
       .then((response) => response.json())
       .then((data) => {
          //setPosts((posts) => [data, ...posts]);
          
          //const respuesta = JSON.parse(data);
          const respuesta = data.usuarioid;
    
          if(respuesta == 0)
          {
             alert("Ocurrio un problema al actualizar contraseña, intente nuevamente o contactese con el administrador.");  
             return; 
          }else{
            
                alert("Los Datos fueron actualizados correctamente."); 
                //clear();
                
          }
       })
       .catch((err) => {
          console.log(err.message);
       });
 };

 function soloNumeros(number)
 {
    
    let newValor = number.replace(/\D/g, "");
    
    return newValor;
 }

 const clear = () => {
    setPerfil('');
    setApellido('');
    setNombre('');
    setnumerodocumento('');
    setCelular('');
    setCorreo('');
    setContraseña('');
  }

  const ObtenerInfoUsuario = async (pidusuario) =>
  {
        let fd = new FormData() 
        fd.append("pidusuario",pidusuario)
     
        
        const res3 = await axios.post('https://conectate-peru.com/api/ApiObtenerUsuariof.php', fd);
        
        
        res3.data.items.map((item, index) => {
          
         setNombre(item.Nombre)
         setApellido(item.ApellidoPaterno)
         setnumerodocumento(item.NumeroDocumento)
         settipodocumento(item.idTipo_Documento)
         setCelular(item.celular)
         setCorreo(item.correo)
         setContraseña(item.contrasenia)
         setContraseniaOld(item.contrasenia)

        })
  }

  const handleFocus = (e) => {
    

    if(e.target.name == 'password')
    {
        setContraseña('')
    }

  };

  // Función para manejar el foco perdido
  const handleBlur = (e) => {
    
    if(e.target.name == 'password')
    {   
        if(contraseña == '')
        {
            setContraseña(contraseniaOld)
        }
        
    }
  };

return(<>

    <Layout>
        <Container style={{paddingTop:'calc(5em + 5vw)',paddingLeft:'15px'}}>
            <Row>
                <Col sm={3}>
                    <img src={imgBack} style={{float:'right',cursor:'pointer'}} onClick={handleRedirectHome}></img>
                </Col>
                <Col sm={6}>
                <p style={{fontSize:'12pt',opacity:'0.5'}}>Mi perfil</p>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
            <Row>
            <Col sm={3}>
                    
                </Col>
                <Col sm={6}>
                <Form onSubmit={handleSubmit}>

                <Container style={{paddingBottom:'20px',paddingTop:'20px'}}>

                    <Row>
                        <Col sm={3}>
                        </Col>
                        <Col sm={6}>
                        <Form.Group className="sm-3" controlId="exampleForm.ControlInput2">
                                <Form.Label style={{fontSize:'10pt',color:'black'}}>Tipo de Documento</Form.Label>
                                <Form.Select 
                                value={tipodocumento}
                                onChange={(e) => settipodocumento(e.target.value)}
                                style={{fontSize:'10pt',color:'black'}}
                                disabled
                                >
                                <option Select value={4}>RUC</option>
                                    <option value={1}>DNI</option>
                                    <option value={2}>CARNET DE EXTRANJERIA</option>
                                    <option value={3}>PASAPORTE</option>
                                    
                                    </Form.Select>
                                </Form.Group>
                        </Col>
                        <Col sm={3}>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                        </Col>
                        <Col sm={6}>
                        <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={{fontSize:'10pt',color:'black'}}>Número de documento</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={numerodocumento}
                                    onChange={(e) => setnumerodocumento(soloNumeros(e.target.value))}
                                    required
                                    style={{fontSize:'9pt',color:'black'}}
                                    disabled
                                />
                                </Form.Group>
                        </Col>
                        <Col sm={3}>
                        </Col>
                    </Row>
                    <Row>
                    <Col sm={3}>
                        </Col>
                        <Col sm={6}>
                        <Form.Group className="sm-3" controlId="exampleForm.ControlInput2">
                        <Form.Label style={{fontSize:'10pt',color:'black'}}>Nombre</Form.Label>
                        <Form.Control
                            type="text"
                            value={nombre}
                            onChange={(e) => setNombre(formatCadena(e.target.value))}
                            minLength="4"
                            required
                            style={{fontSize:'9pt',color:'black'}}
                            disabled
                        />
                        </Form.Group>
                        </Col>
                        <Col sm={3}>
                        </Col>
                    </Row>
                    <Row>
                    <Col sm={3}>
                        </Col>
                        <Col sm={6}>
                        <Form.Group className="sm-3" controlId="exampleForm.ControlInput3">
                        <Form.Label style={{fontSize:'10pt',color:'black'}}>Apellido</Form.Label>
                        <Form.Control
                            type="text"
                            value={apellido}
                            minLength="4"
                            onChange={(e) => setApellido(formatCadena(e.target.value))}
                            required
                            style={{fontSize:'9pt',color:'black'}}
                            disabled
                        />
                        </Form.Group>
                        </Col>
                        <Col sm={3}>
                        </Col>
                    </Row>
                    <Row>
                    <Col sm={3}>
                        </Col>
                        <Col sm={6}>
                        <Form.Group className="sm-3" controlId="exampleForm.ControlInput4">
                        <Form.Label style={{fontSize:'10pt',color:'black'}}>Celular</Form.Label>
                        <Form.Control
                            type="text"
                            minLength="8"
                            name='celular'
                            value={celular}
                            onChange={(e) => setCelular(soloNumeros(e.target.value))}
                            required
                            style={{fontSize:'9pt',color:'black'}}
                        />
                        </Form.Group>
                        </Col>
                        <Col sm={3}>
                        </Col>
                    </Row>
                    <Row>
                    <Col sm={3}>
                        </Col>
                        <Col sm={6}>
                        <Form.Group className="sm-3" controlId="exampleForm.ControlInput5">
                        <Form.Label style={{fontSize:'10pt',color:'black'}}>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="nombre@dominio.com"
                            value={correo}
                            onChange={(e) => setCorreo(e.target.value)}
                            required
                            style={{fontSize:'9pt',color:'black'}}
                            disabled
                        />
                        </Form.Group>
                        </Col>
                        <Col sm={3}>
                        </Col>
                    </Row>
                    <Row>
                    <Col sm={3}>
                        </Col>
                        <Col sm={6}>
                        <Form.Group className="sm-3" controlId="exampleForm.ControlInput6">
                        <Form.Label style={{fontSize:'10pt',color:'black'}}>Contraseña</Form.Label>
                        <Form.Control
                            type="password"
                            name='password'
                            value={contraseña}
                            minLength="6"
                            onChange={(e) => setContraseña(e.target.value)}
                            required
                            style={{fontSize:'9pt',color:'black'}}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                        />
                        </Form.Group>
                        </Col>
                        <Col sm={3}>
                        
                        </Col>
                    </Row>
                </Container>        
                <br></br>
                <Container>
                <Row>
                <Col sm={3}>
                        </Col>
                    <Col sm={6}>
                <center>
                
                <Button variant="primary3" type='submit'>
                            <p style={{fontSize:'12pt'}}> Guardar</p>
                        </Button>
                    </center> 
                    </Col>
                    <Col sm={3}>
                        </Col>
                </Row>
                </Container>


                </Form>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>

            <Row style={{paddingTop:'20px'}}>
                <Col sm={3}>
                    
                </Col>
                <Col sm={6}>
                {/*<center><img src={imgBicii} style={{width:'150px'}}></img></center>*/}
                </Col>
                <Col sm={3}>
                
                </Col>
            </Row>


        </Container>
    </Layout>



</>);


}

export default MiPerfil;