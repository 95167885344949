// VideoPlayer.js
import React from 'react';
import ReactPlayer from 'react-player';


const VideoPlayer = () => {
  return (
    <>
    
    <ReactPlayer
      url="/videos/home/conectateperu.mp4"  // Reemplaza con la ruta de tu video local
      className="react-player"
      
      playing muted loop
    />
    <br></br>
    <br></br>
    </>
  );
};

export default VideoPlayer;
