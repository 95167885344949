import React,{useState,useEffect} from "react";
import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Carousel from "react-bootstrap/Carousel";
import { Link,useNavigate } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import imgTagVarios from "../img/Home/tag_varios.svg";
import Button from 'react-bootstrap/Button';
import { useLocalStorage } from "../useLocalStorage";

const zoomOutProperties = {
    duration: 5000,
    transitionDuration: false,
    infinite: false,
    indicators: false,
    scale: 0.4,
    arrows: true
    };

function EventosDetalles({ListaImagenes,Titulo,FechaRegistro,MiGrupo})
{

const navigate = useNavigate();
const [items, setItems] = useState();
const [index, setIndex] = useState(0);
const [letrasAleatorias, setLetrasAleatorias] = useState([]);
const [bmigrupo, setmigrupo] = useLocalStorage('bmigrupo',false)
const [usuarioActivo,setusuarioActivo] = useState(() => {

  const UsuActivo = sessionStorage.getItem("UsuarioActivo");
 
  
  if(UsuActivo == null)
  {
      return;
  }
  
  const UsuActivoS  = JSON.parse(UsuActivo);

  return UsuActivoS || "";
  });

const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleRedirect = (tipo) => {
         

      if(usuarioActivo == null || usuarioActivo == '')
      {
        alert("Debe iniciar sesión para unirse a grupo.");
      }else{
        if(tipo == 1)
          {
            navigate('/CrearEvento');
          }else{
            navigate('/SolicitudGrupo');
          }
      }

   
  
  }


async function fetchApi()
{
  
    //const jsonNew = JSON.parse(ListaImagenes);
    //setItems(jsonNew.items);
    generarLetrasAleatorias();
    

}

const generarLetrasAleatorias = () => {
    const letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const letrasArray = letras.split('');
    const letrasAleatoriasArray = [];

    for (let i = 0; i < 3; i++) {
      const indiceAleatorio = Math.floor(Math.random() * letrasArray.length);
      letrasAleatoriasArray.push(letrasArray[indiceAleatorio]);
    }

    setLetrasAleatorias(letrasAleatoriasArray);
  };

useEffect(() => {
    fetchApi();
    },[])

    return (
        <>
         <Container style={{padding:'20px 0 0 0',overflow:'hidden'}}>
            <Row>
              <Col xs={6}>
              <Container style={{padding:'0 0 0 0'}}>
              <Row>
                  <Col xs={12}>
                    <p style={{fontSize:"18pt",padding:'0 0 0 0',textAlign:"left"}}><b>{Titulo}</b></p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Container>
                      <Row>
                        <Col xs={2}>
                        <center>
                                    <div class="circle blue"><center><p style={{padding:'5px 0 0 0'}}>{((usuarioActivo == null || usuarioActivo == '') ? <></> : usuarioActivo.substring(0,1).toUpperCase()) }</p></center></div>
                                    </center>
                        </Col>
                        <Col xs={10}>
                        <p style={{fontSize:"12pt",padding:'0 0 0 0',textAlign:"left"}}>Creado  por: <b>{(usuarioActivo == null || usuarioActivo == '') ? <></> : usuarioActivo.substring(0,usuarioActivo.indexOf("@"))}</b></p>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
                <Row>
                  <Col  xs={12} style={{paddingTop:'10px'}}>
                    <p style={{fontSize:"8pt",padding:'0 0 0 0',textAlign:"left"}}><b>{FechaRegistro}</b></p>
                  </Col>
                </Row>
               
                <Row>
                  <Col xs={12}>
                    <p style={{fontSize:"8pt",padding:'0 0 0 0',textAlign:"left"}}></p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    {MiGrupo == true ? <>
                      <Button variant='primary8' onClick={(e) => handleRedirect(1)}>
                                    <b style={{fontSize:'12pt'}}>Programar un Evento</b>
                                    </Button>
                    </> : <>
                    <Button variant='primary3' onClick={(e) => handleRedirect(2)}>
                                    <b style={{fontSize:'12pt'}}>Unirme</b>
                                    </Button>
                    </>}
                  
                  </Col>
                </Row>
               
              </Container>
              </Col>
              <Col xs={6}>
              <center>
                  <LazyLoadImage
                 effect="blur"
                 src={ListaImagenes}
                 style={{ width: "calc(280px + 2vmin)",
                 height: "calc(170px + 2vmin)",
                 objectFit: "cover" }}
                 ></LazyLoadImage>
                  </center>
              </Col>
            </Row>
            </Container>  
        </>
    );


}




export default EventosDetalles;