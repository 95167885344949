import React, { useState,useEffect } from 'react';
import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import LoadingSpinner from "./LoadingSpinner";
import { Link,useNavigate } from "react-router-dom";
import Eventos from './Eventos';
import Grupos from './Grupos';
import { useLocalStorage } from '../useLocalStorage';


function TopGrupos()
{

    let itop = 0;

    


     const [items2, setItems2] = useState();
     const [counter, setCounter] = useState(0);
     const [isLoading, setIsLoading] = useState(false);
     const [isVisible, setIsVisble] = useState('');
     const [idusuario, setIdusuario] = useLocalStorage('idusuario','')

     async function fetchApi(top){
        setIsLoading(true);
        let fd = new FormData() 
        fd.append("pidusuario",idusuario)
        fd.append("top",top)

        const res = await axios.post('https://conectate-peru.com/api/ApiListarGrupos.php',fd);
        setItems2(res.data.items);
        //setIsVisble(res.data.items[0].visible);
        setIsLoading(false);

     }

     const navigate = useNavigate();
    
     const handleRedirect = (idAnuncio,Latitud, Longitud) => {
         
         sessionStorage.setItem("CodAnuncioActualSeleccion",JSON.stringify(idAnuncio));
         sessionStorage.setItem("LatitudActualSeleccion",JSON.stringify(Latitud));
         sessionStorage.setItem("LongitudActualSeleccion",JSON.stringify(Longitud));
         navigate('/DetalleAnuncio');
 
     }

    useEffect(() => {
        fetchApi(itop);
        
        },[])


        const handleClick1  = (e) => 
        {

            if(counter >= 0)
            {
            setCounter(counter + 1);

            fetchApi(counter + 1);
            }
            
            //console.log(counter);
        
        }

        const handleClick2  = (e) => 
        {

            if(counter > 0)
            {
            setCounter(counter - 1);
            
            fetchApi(counter - 1);
            }
            //console.log(counter);
            
        }
    
    
    return (
        <>
        
        <div className='d-flex justify-content-center align-items-center h-200'>
            
            {isLoading ? <LoadingSpinner/> :
            <>{/*isLoading*/}
            <i onClick={(e) => handleClick2(e)} class="arrow left" disabled={true} style={{visibility:isVisible}}></i>
            <ul id='topmerkasanew'>
            { !items2 ? 'Sin Grupos que mostrar.' : items2.map( (item,index) => {
                
                return(
                    <li   key={index} className='toplinew'>
                    <Grupos NombreGrupo = {item.NombreGrupo} ImagenGrupoAdm = {item.ImagenGrupo} DescripcionGrupo = {item.DescripcionGrupo} FechaCreacion = {item.FechaCreacion} IdGrupo ={item.Id} ></Grupos>
                    </li>
                
                )
            })}
            
            </ul>
            <i onClick={(e) => handleClick1(e)}  class="arrow right" disabled={true} style={{visibility:isVisible}}></i>
            </>
            }


           
        </div></>
    );
}

export default TopGrupos;