import React, { useState,useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Home from "./Home";
import Login from './Login';
import Registro from './Registro';
import HomeUser from './HomeUser';
import CrearGrupo from './CrearGrupo';
import CreandoGrupo from './CreandoGrupo';
import CreandoGrupo2 from './CreandoGrupo2';
import CreandoGrupo3 from './CreandoGrupo3';
import CreandoGrupo4 from './CreandoGrupo4';
import Detalle from './Detalle';
import GrupoCreadoDetalle from './GrupoCreadoDetalle';
import CrearEvento from './CrearEvento';
import EventoDetalle from './EventoDetalle';
import EventoDetallePago from './EventoDetallePago';
import ListaGrupos from './ListaGrupos';
import SolicitudGrupo from './Components/SolicitudGrupo';
import CrearCupon from './CrearCupon';
import EventoDetalle2 from './EventoDetalle2';
import MiPerfil from './MiPerfil';
import CambioContrasenia from './CambioContrasenia';
import ListaGrupoUsuario from './ListaGrupoUsuario';
import ListaEventoUsuario from './ListaEventoUsuario';
import CrearEventoUpdate from './CrearEventoUpdate';
import GrupoUpdate from './GrupoUpdate';

import 'bootstrap/dist/css/bootstrap.min.css';

/*if (window.location.protocol === 'http:') {
window.location.href = 'https://' + window.location.host + window.location.pathname;
}*/

const root = ReactDOM.createRoot(document.getElementById("root"));



root.render(
 
<>
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home/>} exact />
        <Route path="/Login" element={<Login/>} />
        <Route path="/Registro" element={<Registro/>} />
        <Route path="/HomeUser" element={<HomeUser/>} />
        <Route path="/CrearGrupo" element={<CrearGrupo/>} />
        <Route path="/CreandoGrupo" element={<CreandoGrupo/>} />
        <Route path="/CreandoGrupo2" element={<CreandoGrupo2/>} />
        <Route path="/CreandoGrupo3" element={<CreandoGrupo3/>} />
        <Route path="/CreandoGrupo4" element={<CreandoGrupo4/>} />
        <Route path="/Detalle" element={<Detalle/>} />
        <Route path="/GrupoCreadoDetalle" element={<GrupoCreadoDetalle/>} />
        <Route path="/CrearEvento" element={<CrearEvento/>} />
        <Route path="/EventoDetalle" element={<EventoDetalle/>} exact/>
        <Route path="/EventoDetallePago" element={<EventoDetallePago/>} />
        <Route path="/ListaGrupos" element={<ListaGrupos/>} />
        <Route path="/SolicitudGrupo" element={<SolicitudGrupo/>} />
        <Route path="/CrearCupon" element={<CrearCupon/>} />
        <Route path="/EventoDetalle2" element={<EventoDetalle2/>} />
        <Route path="/MiPerfil" element={<MiPerfil/>} />
        <Route path="/CambioContrasenia" element={<CambioContrasenia/>} />
        <Route path="/ListaGrupoUsuario" element={<ListaGrupoUsuario/>} />
        <Route path="/ListaEventoUsuario" element={<ListaEventoUsuario/>} />
        <Route path="/CrearEventoUpdate" element={<CrearEventoUpdate/>} />
        <Route path="/GrupoUpdate" element={<GrupoUpdate/>} />
      </Routes>
    </HashRouter>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
