import React, { useState,useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Link,useNavigate } from "react-router-dom";
import Canvas from '../Components/Canvas';
import { useLocalStorage } from '../useLocalStorage';

import {FaCheckCircle, FaTrash,BiFilterAlt } from 'react-icons/fa'; // npm i react-icons
import axios from 'axios'; // npm i axios
import LoadingSpinner from "../Components/LoadingSpinner";


function SubirFoto ()
{

    const url = 'https://conectate-peru.com/api/ApiListarAnunciosImg.php';
    const [lista, setLista] =  useState();
    const [descripcion, setDescripcion] = useState('');  
    const [imagen, setImagen] = useLocalStorage('imagen',[]);
    const [lstimagen, setlstimagen] =  useState([]);
    
    const [vWidth, setWidth] = useState('');  
    const [vHeight, setHeight] = useState('');
    const [isLoading, setIsLoading] = useState(false);


async function getImagenes(){
    setIsLoading(true);
    //const CodAnuncio = sessionStorage.getItem("CodAnuncioActual");
    const CodAnuncio = localStorage.getItem("codigoActual");

    if(CodAnuncio == null || CodAnuncio == '')
   {
       handleRedirectSinSesion();
       return;
   }
   
   const CodAnuncioS = JSON.parse(CodAnuncio);

   let fd = new FormData() 
   fd.append("idanuncio",CodAnuncioS)

 /*axios.post(*/
   const res = await axios.post('https://conectate-peru.com/api/ApiListarAnunciosImg.php', fd);

   setLista(res.data.items)
   setIsLoading(false);

} 

const handleRedirectSinSesion = () => {

    navigate('/');

}

const handleClickCircle = (tipo) =>
{
    if(tipo == 2)
    {
        const CodigoActual = sessionStorage.getItem("CodAnuncioActual");

        if(CodigoActual != null && CodigoActual != 'undefined')
        {
            navigate('/RegistroAnuncioCaracteristicas');
        }
    }
}

    function deleteImagen(id){ 

    //const CodAnuncio = sessionStorage.getItem("CodAnuncioActual");
    const CodAnuncio = localStorage.getItem("codigoActual");

    console.log(id)

    if(CodAnuncio == null)
    {
    return;
    }

    const CodAnuncioS = JSON.parse(CodAnuncio);
    const imgFinal = document.getElementById('canvas-'+id);
    const lif = document.getElementById('li-'+id);
    const ulf =document.getElementById('listaAnunciosfotos');

    let ol = document.querySelector("ul");

    let context = imgFinal.getContext('2d');

    if(context != null)
    {
    context.clearRect(0, 0, vWidth, vHeight);
    }

    //let valor = parseInt(id);
    handleRemoveItem(id);

    }

const handleRemoveItem = (id) => {
    setImagen(imagen.filter(item => item.id !== id));
  };

  function niceBytes(a)
  {
    var marker = 1024; // Change to 1000 if required
    var decimal = 3; // Change as required
    var kiloBytes = marker; // One Kilobyte is 1024 bytes
    var megaBytes = marker * marker; // One MB is 1
    return(a / megaBytes).toFixed(decimal);
  }

 async function convertImage(e)
{

    const files = e.target.files;
    const filePeso = 0;

    for (let index = 0; index < files.length; index++) {
        const reader = new FileReader();
    let nWidth = 0;
    let nHeigth = 0;
    
    
    let src = URL.createObjectURL(e.target.files[index]);
    const divcanvas = document.getElementById('wimagen');
    //reader.readAsDataURL(e.target.files[0]);

    //console.log(niceBytes(e.target.files[index].size));

    if(niceBytes(e.target.files[index].size) >= 32.0)
    {
        alert("Imagen excede el tamaño permitido. El peso debe ser menor a 32Mb.")
        return;
    }

    reader.readAsDataURL(e.target.files[index]);
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = () => {
        const {
          height,
          width
        } = image;

        setHeight(height);
        setWidth(width);
        if(src.length > 1)
        {
            setImagen(imagen => [...imagen,{ id:Math.random() + index,src:src, height:height, width: width}])
        }
      };
    };  
    }
    
    const fileAnun = document.getElementById('fileAnuncio');
    fileAnun.value = '';
    
}


useEffect(() => {
    //getImagenes();
    window.scrollTo(0, 0);
  },[])



  const navigate = useNavigate();

  async function handleRedirect ()  {

    if(window.confirm('Estas apunto de publicar. ¿Confirma la publicación?'))
    {
        //const CodAnuncio = sessionStorage.getItem("CodAnuncioActual");
        const CodAnuncio = localStorage.getItem("codigoActual");
        
        if(CodAnuncio == null || CodAnuncio == '')
        {
            handleRedirectSinSesion();
            return;
        }
        
        if(imagen.length <= 3)
        {
            alert("Para publicar debe subir al menos 4 fotos.");
            return;
        }

        if(imagen.length > 20)
        {
            alert("Cantidad permitida de fotos por anuncio es 20.");
            return;
        }

        /*console.log(imagen);

        return;*/
        imagen.map(async (item,index)  => {

            setTimeout(async() => {
                const CodAnuncioS = JSON.parse(CodAnuncio);
                const imgFinal = document.getElementById('canvas-'+item.id);
                let context = imgFinal.getContext('2d');
                
                let fd = new FormData() 
                fd.append("pidanuncio",CodAnuncioS)
                fd.append("isportada",index)
                fd.append("imagenbs4", imgFinal.toDataURL("image/webp"))
        
                const res = await axios.post('https://speedaixdev.com/api/ApiRegistroAnuncioImgf.php', fd);
            }, 2000);

        });

        sessionStorage.removeItem("CoordinatesLatRegAnun");
        sessionStorage.removeItem("CoordinatesLngRegAnun");
        sessionStorage.removeItem("DireccionRegAnun");
        sessionStorage.removeItem("CodAnuncioActual");
        sessionStorage.removeItem("tipotransaccionActual");

        localStorage.removeItem("tipoinmueble");
        localStorage.removeItem("tipotransaccion");
        localStorage.removeItem("descripcion");
        localStorage.removeItem("direccion");
        localStorage.removeItem("latitud");
        localStorage.removeItem("longitud");
        localStorage.removeItem("ciudad");
        localStorage.removeItem("distrito");
        localStorage.removeItem("codigoActual");
        localStorage.removeItem("precio");
        localStorage.removeItem("preciodesde");
        localStorage.removeItem("preciohasta");
        localStorage.removeItem("preciodesde");
        localStorage.removeItem("monedaprecio");
        localStorage.removeItem("terreno");
        localStorage.removeItem("construccion");
        localStorage.removeItem("anioconstruccion");
        localStorage.removeItem("titulo");
        localStorage.removeItem("dormitorios");
        localStorage.removeItem("banios");
        localStorage.removeItem("parqueos");
        localStorage.removeItem("expensas");
        localStorage.removeItem("monedaexpensas");
        localStorage.removeItem("monedagarantia");
        localStorage.removeItem("caractetisticas");
        localStorage.removeItem("nroPisosEdificio");
        localStorage.removeItem("nroDepartamentoPiso");
        localStorage.removeItem("fechaEngrega");
        localStorage.removeItem("estadoproyecto");
        localStorage.removeItem("addressh");
        localStorage.removeItem("address");
        localStorage.removeItem("coordinates");
        localStorage.removeItem("barrio");
        localStorage.removeItem("vdepartamento");
        localStorage.removeItem("vprovincia");
        localStorage.removeItem("vmunicipio");
        
        setTimeout(() => {
            navigate('/');
        }, 2000);

    }

}

    return (
        <>
            <Container>
                <Row>
                    <Col sm={12}>
                    <center>
                    <div id='wimagen'>                   
                    <ul id='listaAnunciosfotos'>
                    {!imagen ? 'Cargando imagenes' : imagen.map( (item,index) => {
                        /*<Canvas src={item.src} width={item.width} height={item.height} ></Canvas>*/
                        return(<><li key={item.id} id={'li-'+item.id} className='anuncioslif'>
                                    <Container>
                                        <Row>
                                            <Col sm={12}>
                                            <Canvas src={item.src} width={item.width} height={item.height} index={item.id}></Canvas>
                                            </Col>
                                            <Col sm={12}>
                                                <center>
                                                <button  className="btn btn-outline-danger btn-sm btnEliminarFoto" 
                                                                            onClick={() => deleteImagen(item.id)} ><FaTrash />
                                        </button> 
                                                </center>
                                           
                                            </Col>
                                        </Row>
                                    </Container>
                   
                                </li></>
                        )
                    }) 
                    }
                    </ul>

                    <h4>Vista Previa</h4>
                    </div>
                    <form className="card p-2 mt-2 border-secondary" encType="multipart/form-data">
                    <div className="form-group">
                    <center>
                    <label htmlFor="img">Cargue imagenes para su anuncio</label> 
                    </center>
                    <br></br>
                    <center>
                    <input id="fileAnuncio" type="file" multiple  className="form-control-file" accept="image/png,image/jpeg,image/jpe"
                    onChange={(e) => convertImage(e)}/>
                    </center>
                    </div> 
                    <br></br>
                    <center>
                    <button type='button' className='btnGuardarAnuncio' onClick={handleRedirect}>Publicar</button>
                    </center>
                    </form>
                    </center>
                    </Col>
                   
               
                </Row>
                
            </Container>
        
        </>
    );
}


export default SubirFoto;