import React, { useState,useEffect } from 'react';
import './Calendar.css'; // Asegúrate de importar tus estilos CSS
import imgArrowLeft from "../../src/img/HomeUser/arrow_left.svg"
import imgArrowRight from "../../src/img/HomeUser/arrow_right.svg"

function Calendar() {
  const daysOfWeek = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'];
  const months = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];

  const today = new Date();

  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [currentDay, setCurrentDay] = useState(today.getDate());
  const [selectedDay, setSelectedDay] = useState(null);

  const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
  const getFirstDayOfMonth = (year, month) => new Date(year, month, 1).getDay();

  const daysInMonth = getDaysInMonth(currentYear, currentMonth);
  const firstDay = getFirstDayOfMonth(currentYear, currentMonth);

  const prevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const nextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const weeksInMonth = Math.ceil((firstDay + daysInMonth) / 7);
  const calendarDays = [...Array(weeksInMonth * 7)].map((_, index) => {
    const dayNumber = index - firstDay + 1;
    return dayNumber > 0 && dayNumber <= daysInMonth ? dayNumber : null;
  });

  const handleDayClick = (day) => {
    if (day !== null) {
        setSelectedDay(day);
    }
  };

  useEffect(() => {
    setSelectedDay(currentDay);
  },[])


  return (
    <div className="calendar">
      <header>
        <button onClick={prevMonth}><img src={imgArrowLeft}></img></button>
        <h5>{months[currentMonth]} {currentYear}</h5>
        <button onClick={nextMonth}><img src={imgArrowRight}></img></button>
      </header>
      <table>
        <thead>
          <tr>
            {daysOfWeek.map(day => (
              <th key={day}>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody>
        {Array.from({ length: weeksInMonth }, (_, weekIndex) => (
            <tr key={weekIndex}>
              {Array.from({ length: 7 }, (_, dayIndex) => {
                const dayNumber = weekIndex * 7 + dayIndex - firstDay + 1;
                const isSelected = selectedDay === dayNumber;
                return (
                  <td
                    key={dayIndex}
                    className={`${dayNumber > 0 && dayNumber <= daysInMonth ? '' : 'empty-cell'} ${isSelected ? 'selected' : ''}`}
                    onClick={() => handleDayClick(dayNumber)}
                  >
                    {dayNumber > 0 && dayNumber <= daysInMonth ? dayNumber : ''}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Calendar;