import React from "react";
import LogoAmarillo from '../img/Home/logo.svg';

export default function Footer(){


return (
<div>
<br></br>
<br></br>
<footer id="footer1" class="text-center text-lg-start bg-light text-muted footerhome">
 
  <section id="footer2" class="d-flex justify-content-center justify-content-lg-between p-4">
  </section>

  <section id="footer3" class="">
    <div class="container text-center text-md-start mt-5">
    
      <div class="row mt-3">
        
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
        
          <h6 class="text-uppercase fw-bold mb-4">
            <i>
              <img src={LogoAmarillo} width='180px' height='180px' style={{marginTop:'-80px'}}></img>
              </i>
          </h6>
          <p>
           
          </p>
        </div>

        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
 
          <h6 class="text-uppercase fw-bold mb-4">
            SERVICIOS
          </h6>
          {/*<p>
            <a href="#!" class="text-reset">Venta</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Alquiler</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Proyectos</a>
</p>*/}
        </div>



        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">

          <h6 class="text-uppercase fw-bold mb-4">
            CONÉCTATE PERÚ
          </h6>
          <p>
            <a href="#!" class="text-reset">Sobre nosotros</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Misión</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Visión</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Valores</a>
          </p>
        </div>

        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

          <h6 class="text-uppercase fw-bold mb-4">CONTÁCTANOS</h6>
          <p><i class="fas fa-home me-3"></i> Administradores</p>
          <p>
            <i class="fas fa-envelope me-3"></i>
            admin@conectate-peru.com
          </p>
          <p><i class="fas fa-home me-3"></i> Usuarios</p>
          <p>
            <i class="fas fa-envelope me-3"></i>
            user@conectate-peru.com
          </p>
          <p><i class="fas fa-phone me-3"></i> + 01 234 567 88</p>
        </div>
   
      </div>
  
    </div>
  </section>



  <div class="text-center p-4" style={{backgroundColor: '#000', opacity:8, color:'white',fontsize:'6pt'}}>
    Conéctate-Perú | Copyright © 2023. Derechos reservados
  </div>

</footer>
</div>
)


}