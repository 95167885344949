import React, { useState,useEffect } from 'react';
import Layout from "./Components/Layout";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link,useNavigate } from "react-router-dom";
import { useLocalStorage } from './useLocalStorage';
import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import 'react-tabs/style/react-tabs.css';


function EventoDetallePago(){

const navigate = useNavigate();
let itop = 0;



const [items2, setItems2] = useState();
const [counter, setCounter] = useState(0);
const [isLoading, setIsLoading] = useState(false);
const [isVisible, setIsVisble] = useState('');
const [caractetisticas, setcaractetisticas] = useState();
const [vcaracteristica, setvcaracteristica] = useLocalStorage('vcaracteristica','');
const [ImagenPortada, setImagenPortada] = useLocalStorage('ImagenPortada','');
const [Titulo, setTitulo] = useState('');
const [Descripcion, setDescripcion] = useState('');
const [FechaInicio, setFechaInicio] = useState('');
const [HoraInicio, setHoraInicio] = useState('');
const [Latitud, setLatitud] = useState('');
const [Longitud, setLongitud] = useState('');
const [Participantes, setParticipantes] = useState('');
const [MonedaPrecio, setMonedaPrecio] = useState('');
const [Direccion, setDireccion] = useLocalStorage('Direccion','');
const [letrasAleatorias, setLetrasAleatorias] = useState([]);
const [items, setItems] = useState();
const [fecha,setfecha] = useState(new Date())
const [fecharegistro, setfecharegistro] = useLocalStorage('fecharegistro','')
const [codigogrupoactual, setcodigogrupoactual] = useLocalStorage('codigogrupoactual','');
const [idusuario, setIdusuario] = useLocalStorage('idusuario','')
const [tienecosto, settienecosto] = useLocalStorage('tienecosto','')
const [precio, setprecio] = useLocalStorage('precio','')
const [eventoActual, setEventoActual] = useState(null)
const [cantidadAsistentes, setCantidadAsistentes] = useState(0)
const [bInscrito, setbinscrito] = useLocalStorage('bInscrito',false)
const [bYape, setbYape] = useState(false)
const [bPlin, setbPlin] = useState(false)
const [bTransferencia, setbTransferencia] = useState(false)
const [bYapeu, setbYapeu] = useState(false)
const [bPlinu, setbPlinu] = useState(false)
const [bTransferenciau, setbTransferenciau] = useState(false)
const [numerocuenta, setnumerocuenta] = useState('')
const [bseleccionoPago, setseleccionopago] = useLocalStorage('bseleccionoPago',false)
const [usuarioActivo,setusuarioActivo] = useState(() => {

    const UsuActivo = sessionStorage.getItem("UsuarioActivo");
   
    
    if(UsuActivo == null)
    {
        return;
    }
    
    const UsuActivoS  = JSON.parse(UsuActivo);
  
    return UsuActivoS || "";
    });
  


const getEvento = async () => {
        
        const vEventoActual = JSON.parse(sessionStorage.getItem("EventoActual"));

        setEventoActual(vEventoActual)

        if(vEventoActual != '')
        {
            let fd = new FormData() 
            fd.append("pid",vEventoActual)

            const response = await axios.post('https://conectate-peru.com/api/ApiListarEventosId.php',fd);

            if(response.data != 0)
            {

            response.data.items.map((item,index) =>{

                const options = { year: 'numeric', month: 'long', day: 'numeric' };

                const fechaFormateada = fecha.toLocaleDateString('es-ES', options);

                setfecharegistro(fechaFormateada)            
                setTitulo(item.Titulo)
                setDescripcion(item.Descripcion)
                setFechaInicio(item.FechaInicio)
                setHoraInicio(item.HoraInicio)
                setLatitud(item.Latitud)
                setLongitud(item.Longitud)
                setParticipantes(item.Participantes)
                setMonedaPrecio(item.MonedaPrecio)
                setImagenPortada(item.ImagenPortada)
                setDireccion(item.Direccion)
                settienecosto(item.Costo)
                setprecio(item.Precio)
                sessionStorage.setItem("LatitudActualSeleccion",JSON.stringify(item.Latitud));
                sessionStorage.setItem("LongitudActualSeleccion",JSON.stringify(item.Longitud));
                sessionStorage.setItem("ImagenPortadaActualSeleccion",JSON.stringify(item.ImagenPortada));
                setbYape((item.Yape == 1 ? true : false))
                setbPlin((item.Plin == 1 ? true : false))
                setbTransferencia((item.Transferencia == 1 ? true : false))
                setnumerocuenta(item.NumeroCuenta)
                
            })           
            }
        }
  }

  const getCantidadAsistentes = async () => {
        
    const vEventoActual = JSON.parse(sessionStorage.getItem("EventoActual"));

    setEventoActual(vEventoActual)

    if(vEventoActual != '')
    {
        let fd = new FormData() 
        fd.append("pidevento",vEventoActual)

        const response = await axios.post('https://conectate-peru.com/api/ApiObtenerCantidadAsistencias.php',fd);

        if(response.data != 0)
        {

            response.data.items.map((item,index) =>{

            setCantidadAsistentes(parseInt(item.CantidadEvento))
            
            })           
        }
    }
}


const SeleccionarPago  = (e) => 
{
   if(window.confirm("¿Confirma el medio de pago?"))
   {
    setbinscrito(true); 
    navigate("/EventoDetalle");
   }
}


useEffect(() => {
    window.scrollTo(0, 0);
    getEvento()
    getCantidadAsistentes()
    generarLetrasAleatorias()
    },[])

    const generarLetrasAleatorias = () => {
        const letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const letrasArray = letras.split('');
        const letrasAleatoriasArray = [];
    
        for (let i = 0; i < 3; i++) {
          const indiceAleatorio = Math.floor(Math.random() * letrasArray.length);
          letrasAleatoriasArray.push(letrasArray[indiceAleatorio]);
        }
    
        setLetrasAleatorias(letrasAleatoriasArray);
      };

    const handleClick1  = (e) => 
    {

        if(counter >= 0)
        {
        setCounter(counter + 4);

        //fetchApi(counter + 4);
        }
        
        //console.log(counter);
    
    }

    const handleClick2  = (e) => 
    {

        if(counter > 0)
        {
        setCounter(counter - 4);
        
        //fetchApi(counter - 4);
        }
        //console.log(counter);
        
    }

    const handleRedirectHome = () =>{

        navigate("/HomeUser");
    
      }

      const handleGoPaso2 = () =>{

        navigate("/CreandoGrupo2");
    
      }

return(<>

    <Layout>
        <Container style={{paddingTop:'calc(5em + 5vw)'}}>
            
            <Row>
                <Col sm={3}>
                   
                </Col>
                <Col sm={6}>
                <Container style={{padding:'0 0 0 0'}}>
                    <Row>
                        <Col sm={6}>
                            <Container style={{padding:'0 0 0 0'}}>
                                <Row>
                                    <Col sm={12}>
                                    <img style={{width:'300px',borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}} src={ImagenPortada}></img>
                                    </Col>
                                    <Col sm={12}>
                                    <Container style={{padding:'0 0 0 0'}}>
                                    <Row>
                                        <Col sm={12} style={{paddingTop:'20px'}}>
                                            <p style={{fontSize:'18pt'}}><b>{Titulo}</b></p>
                                        </Col> 
                                    </Row>
                                </Container>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col sm={6}>
                            <Container >
                                <Row>
                                    <Col sm={12}>
                                        <center><p><b style={{fontSize:'14pt'}}>Detalle de tu pedido</b></p></center>
                                    </Col>
                                    <Col sm={12} style={{paddingTop:'0px'}}>
                                        <p>EVENTO</p>
                                    </Col>
                                    <Col sm={12} style={{borderBottom:'1px solid #92BB00'}}>
                                        <p style={{fontSize:'10pt'}}><b>{Titulo}</b></p>
                                    </Col>
                                    <Col sm={12} style={{paddingTop:'20px'}}>
                                        <p><b style={{fontSize:'12pt'}}>Resumen del Pedido</b></p>
                                    </Col>
                                    <Col sm={12} style={{borderBottom:'1px solid #92BB00',paddingTop:'15px'}}>
                                    <Container style={{padding:'0 0 0 0'}}>
                                        <Row>
                                            <Col sm={6}>
                                            <p style={{fontSize:'10pt'}}><b>Costo x mes:</b></p>
                                            </Col>
                                            <Col sm={6}>
                                            <p style={{fontSize:'10pt',textAlign:'right'}}><b>{MonedaPrecio} {precio}</b></p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                            <p style={{fontSize:'10pt',opacity:'0.5'}}><b>IGV:</b></p>
                                            </Col>
                                            <Col sm={6}>
                                            <p style={{fontSize:'10pt',textAlign:'right',opacity:'0.5'}}><b>{MonedaPrecio} {parseInt(parseInt(precio)*0.18)}</b></p>
                                            </Col>
                                        </Row>
                                    </Container>
                                    </Col>
                                    <Col sm={12}>
                                    <Container style={{padding:'0 0 0 0'}}>
                                        <Row>
                                            <Col sm={6}>
                                            <p style={{fontSize:'10pt'}}><b style={{color:'#92BB00'}}>Total:</b></p>
                                            </Col>
                                            <Col sm={6}>
                                            <p style={{fontSize:'10pt',textAlign:'right'}}><b style={{color:'#92BB00'}}>{MonedaPrecio} {parseInt(parseInt(precio)*1.18)}</b></p>
                                            </Col>
                                        </Row>

                                    </Container>
                                    </Col>
                                    <Col sm={12} style={{paddingTop:'15px'}}>
                                    <p><b style={{fontSize:'14pt'}}>Métodos de pago</b></p>
                                    </Col>
                                    <Col sm={12}>
                                        <Container style={{padding:'0 0 0 0'}}>
                                            {bYape == true ? <>
                                                <Row>
                                                <Col sm={6}>
                                                    <p style={{fontSize:'10pt'}}><b>Yape</b></p>
                                                </Col>
                                                <Col sm={6}>
                                                <Form.Check
                                                    type="radio"
                                                    checked={bYapeu}
                                                    onChange={(e) => SeleccionarPago(e)}
                                                    style={{fontSize:'11pt',color:'black',textAlign:'right'}}
                                                />
                                                </Col>
                                            </Row>
                                            </> : <></>}
                                            {bPlin == true ? <>
                                                <Row>
                                                <Col sm={6}>
                                                <p style={{fontSize:'10pt'}}><b>Plin</b></p>
                                                </Col>
                                                <Col sm={6}>
                                                <Form.Check
                                                    type="radio"
                                                    checked={bPlinu}
                                                    onChange={(e) => SeleccionarPago(e)}
                                                    style={{fontSize:'11pt',color:'black',textAlign:'right'}}
                                                />
                                                </Col>
                                            </Row>
                                            </> : <></>}
                                            {bTransferencia==true?<>
                                                <Row>
                                                <Col sm={6}>
                                                    <p style={{fontSize:'10pt'}}><b>Transferencia Bancaria</b></p>
                                                </Col>
                                                <Col sm={6}>
                                                <Form.Check
                                                    type="radio"
                                                    checked={bTransferenciau}
                                                    onChange={(e) => SeleccionarPago(e)}
                                                    style={{fontSize:'11pt',color:'black',textAlign:'right'}}
                                                />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6}>
                                                    <p style={{fontSize:'10pt'}}><b>Cuenta:</b></p>
                                                </Col>
                                                <Col sm={6}>
                                                    <p style={{fontSize:'10pt'}}>{numerocuenta}</p>
                                                </Col>
                                            </Row>
                                            </>:<></>}
                                        </Container>
                                    </Col>
                                              
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
                </Col>
                <Col sm={3}>
                
                </Col>
            </Row>
        </Container>
    </Layout>



</>);


}

export default EventoDetallePago;