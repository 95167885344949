import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useLocalStorage } from '../useLocalStorage';


const CouponGenerator = ({ onCouponGenerated }) => {
  const [couponCode, setCouponCode] = useState('');
  const [cantidaddescuento, setcantidaddescuento] = useState('');
  const [idusuario, setIdusuario] = useLocalStorage('idusuario','')

  const generateCoupon = () => {
    // Lógica simple para generar un código aleatorio de 8 caracteres
    const randomCode = Math.random().toString(36).substring(2, 10).toUpperCase();
    setCouponCode(randomCode);
    onCouponGenerated(randomCode); // Pasa el código generado al componente principal o al lugar necesario
    RegistroCupon(randomCode);
  };


  const handleSubmit = (e) => {
    
    e.preventDefault();
    generateCoupon();
    
 }; 


 const clear = () => {
    //setCouponCode('');
    setcantidaddescuento('');
  }
 const RegistroCupon = async (pcouponCode) => {
  

    const data = JSON.stringify({
        cupon : pcouponCode,usucre: idusuario,usuapl : 0,porcentaje : cantidaddescuento,tipo : 1,
       })

    //console.log(data);

    await fetch('https://conectate-peru.com/api/ApiRegistroCupon.php', {
       method: 'POST',
       body: data,
       headers: {
          'Content-type': 'application/json; charset=UTF-8',
       },
    })
       .then((response) => response.json())
       .then((data) => {
    
          const respuesta = data;

          console.log(respuesta);

          if(respuesta == 0)
          {
             alert("El cupón no fue registrado, intente nuevamente refrescando el navegador o notifíquelo al administrador.");  
             return; 
          }else{
            
                //alert("El cupón fue registrado correctamente."); 
                clear();
                
          }
       })
       .catch((err) => {
          console.log(err.message);
       });
 };



  function soloNumeros(number)
   {
      
      let newValor = number.replace(/\D/g, "");
      
      return newValor;
   }

  return (
<Form onSubmit={handleSubmit}>
    <Container>
        <Row>
            <Col sm={4}>
            </Col>
            <Col sm={4}>
            <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                <Form.Label style={{fontSize:'10pt',color:'black'}}>Descuento asociado al cupon</Form.Label>
                <Form.Control
                    type="text"
                    value={cantidaddescuento}
                    onChange={(e) => setcantidaddescuento(soloNumeros(e.target.value))}
                    required
                    style={{fontSize:'9pt',color:'black'}}
                />
     </Form.Group>
      
            </Col>
            <Col sm={4}>
            </Col>
        </Row>
        <Row style={{paddingTop:'30px'}}>
            <Col sm={4}>
            </Col>
            <Col sm={4}>
            
                <button type='submit'>Generar Cupón</button>
                {couponCode && <p>Código generado: {couponCode}</p>}
            </Col>
            <Col sm={4}>
            </Col>
        </Row>
    </Container>

    </Form>
    
  );
};

export default CouponGenerator;
