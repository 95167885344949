import React, { useState,useEffect } from 'react';
import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import LoadingSpinner from "./LoadingSpinner";
import { Link,useNavigate } from "react-router-dom";
import Eventos from './Eventos';
import { useLocalStorage } from '../useLocalStorage';


function EventosDetalleGrupo()
{

    let itop = 0;

    


     const [items2, setItems2] = useState();
     const [counter, setCounter] = useState(0);
     const [isLoading, setIsLoading] = useState(false);
     const [isVisible, setIsVisble] = useState('');     
     const [eventoActual, setEventoActual] = useState(null)
     const [codigogrupoactual, setcodigogrupoactual] = useLocalStorage('codigogrupoactual','');

     async function fetchApi(top){
        setIsLoading(true);
        let fd = new FormData() 
        fd.append("idgrupo",codigogrupoactual)

        const res = await axios.post('https://conectate-peru.com/api/ApiListarEventosGrupo.php',fd);
        setItems2(res.data.items);
       
        setIsLoading(false);

        const vEventoActual = JSON.parse(sessionStorage.getItem("EventoActual"));
        setEventoActual(vEventoActual)

     }

     const navigate = useNavigate();
    
     const handleRedirect = (idAnuncio,Latitud, Longitud) => {
         
         sessionStorage.setItem("CodAnuncioActualSeleccion",JSON.stringify(idAnuncio));
         sessionStorage.setItem("LatitudActualSeleccion",JSON.stringify(Latitud));
         sessionStorage.setItem("LongitudActualSeleccion",JSON.stringify(Longitud));
         navigate('/DetalleAnuncio');
 
     }

    useEffect(() => {
        fetchApi(itop);
        
        },[])


        const handleClick1  = (e) => 
        {

            if(counter >= 0)
            {
            setCounter(counter + 4);

            fetchApi(counter + 4);
            }
            
            //console.log(counter);
        
        }

        const handleClick2  = (e) => 
        {

            if(counter > 0)
            {
            setCounter(counter - 4);
            
            fetchApi(counter - 4);
            }
            //console.log(counter);
            
        }
    
    
    return (
        <>        
        <div className='d-flex justify-content-center align-items-center h-200'>
            
            {isLoading ? <LoadingSpinner/> :
            <>{/*isLoading*/}
            <ul id='listaAnunciosf'>
            { !items2 ? <p style={{fontSize:'10pt',textAlign:'left'}}>No hay datos para mostrar</p> : items2.map( (item,index) => {
                
                return(
                    <li   key={index} className='anunciosli'>
                    <Eventos ImagenPortada = {item.ImagenPortada} FechaInicio = {item.FechaInicio} FechaFin = {item.FechaFin} HoraInicio = {item.HoraInicio} HoraFin = {item.HoraFin} Titulo = {item.Titulo} Direccion = {item.Direccion} Participantes = {item.Participantes} IdEvento={item.Id} ></Eventos>
                    </li>
                
                )
            })}
            
            </ul>
            </>
            }


           
        </div></>
    );
}

export default EventosDetalleGrupo;