import React, { Component,useState,useEffect } from 'react'
import { Link,useNavigate } from "react-router-dom";
import KRGlue from '@lyracom/embedded-form-glue'
import axios from 'axios'
import esloganizipay from '../img/CrearGrupo/esloganizipay.png';
import { useLocalStorage } from '../useLocalStorage';
import Button from 'react-bootstrap/Button';

 function FormIziPay ({ valor })  
 {
    const [message, setMessage] = useState(null);
    const [orderStatus, setOrderStatus] = useState(null);
    const [amount, setAmount] = useState(null);
    //const [orderId, setOrderId] = useState(null);
    const [orderId, setOrderId] = useLocalStorage("orderId",null);
    const [bpago, setBpago] = useLocalStorage('bpago', false);
    const navigate = useNavigate();

    //variables

    const [items2, setItems2] = useState();
    const [counter, setCounter] = useState(0);
    const [isLoading, setIsLoading] = useLocalStorage('isLoading',true)
    const [isVisible, setIsVisble] = useState('');
    const [caractetisticas, setcaractetisticas] = useState();
    const [vcaracteristica, setvcaracteristica] = useLocalStorage('vcaracteristica',[]);
    const [vWidth, setWidth] = useState('');  
    const [vHeight, setHeight] = useState('');
    const [imagenSeleccionadaPerfilGrupo, setimagenSeleccionadaPerfilGrupo] = useLocalStorage('imagenSeleccionadaPerfilGrupo','');
    const [imagenSeleccionadaPerfilGrupoADM, setimagenSeleccionadaPerfilGrupoADM] = useLocalStorage('imagenSeleccionadaPerfilGrupoADM','');
    const [imagen, setImagen] = useLocalStorage('imagen',[]);
    const [imagen2, setImagen2] = useLocalStorage('imagen2',[]);
    const [vnombregrupo, setvnombregrupo] = useLocalStorage('vnombregrupo','');
    const [parametro1, setparametro1] = useLocalStorage('parametro1','');
    const [parametro2, setparametro2] = useLocalStorage('parametro2','');
    const [parametro3, setparametro3] = useLocalStorage('parametro3','');
    const [parametro4, setparametro4] = useLocalStorage('parametro4',false);
    const [vplan, setvplan] = useLocalStorage('vplan','')
    const [show, setShow] = useState(false);
    const [codigogrupoactual, setcodigogrupoactual] = useLocalStorage('codigogrupoactual','');
    const [idusuario, setIdusuario] = useLocalStorage('idusuario','')
    const [couponCode, setCouponCode] = useLocalStorage('couponCode','');
    const [valorPlanDecimal, setValorPlanDecimal] = useLocalStorage('valorPlanDecimal','')

    const limpiar = () => {

        localStorage.removeItem('imagenSeleccionadaPerfilGrupoADM')
        localStorage.removeItem('vcaracteristica')
        localStorage.removeItem('parametro1')
        localStorage.removeItem('parametro3')
        localStorage.removeItem('parametro4')
        localStorage.removeItem('imagen')
        localStorage.removeItem('imagen2')
        localStorage.removeItem('bpago')
        localStorage.removeItem('parametro2')
        localStorage.removeItem('vplan')
        localStorage.removeItem('imagenSeleccionadaPerfilGrupo')
        localStorage.removeItem('vnombregrupo')
    }
    
    const RegistroGrupo = async () => {

       
        if(vnombregrupo == '')
        {
            alert("Debe ingresar un nombre de grupo para continuar.");
            return;
        }

    
        const data = JSON.stringify({
            pnombregrupo : vnombregrupo,pdescripciongrupo: parametro1,pnormasgrupo : parametro2,ppreguntasgrupo : parametro3,pgrupocerrado : (parametro4 == true ? 1 : 0),pimagengrupo:imagenSeleccionadaPerfilGrupo, ppimagengrupoadm:imagenSeleccionadaPerfilGrupoADM,pidusuario:idusuario,pcouponCode:couponCode,porderId:orderId,pmonto:valorPlanDecimal
           })
    
        
        if(bpago == true)
        {
            await fetch('https://conectate-peru.com/api/ApiRegistroGrupo.php', {
                method: 'POST',
                body: data,
                headers: {
                   'Content-type': 'application/json; charset=UTF-8',
                },
             })
                .then((response) => response.json())
                .then((data) => {
                   //setPosts((posts) => [data, ...posts]);
                   
                   //const respuesta = JSON.parse(data);
                   //alert("Grupo creado correctamente.")
                   const respuesta = data;
                   setcodigogrupoactual(respuesta)
                   limpiar()
                   navigate('/GrupoCreadoDetalle')
                })
                .catch((err) => {
                   console.log(err.message);
                });
        }
        
     };

    useEffect(() => {
    if(bpago == false){
        return;
    }

    RegistroGrupo()

    },[bpago])

    useEffect(() => {
      
      
      let formToken = 'formtoken';
  
      axios
        .post('https://conectate-peru.com/api/ApiCheckout.php', {
          paymentConf: { amount: valor, currency: 'PEN' }
        })
        .then(resp => {
          formToken = resp.data.formtoken;
          let endpoint = resp.data.endpoint;
          let publicKey = resp.data.publickey;
          return KRGlue.loadLibrary(endpoint, publicKey);
        })
        .then(({ KR }) =>
          KR.setFormConfig({
            formToken: formToken,
            'kr-language': 'es-ES'
          })
        )
        .then(({ KR }) =>
          KR.onSubmit(paymentData => {
            axios
              .post('https://conectate-peru.com/api/ApiValidate.php', paymentData)
              .then(response => {
                console.log(response);
                if (response.status === 200) {
                  //console.log(response.data.details);
                  setMessage('Pago Realizado correctamente!');
                  //setOrderStatus(response.data.response);
                  setOrderId(response.data.details.orderId);
                  //setAmount(response.data.details.orderEffectiveAmount);
                  setBpago(true);
                }
              });
            return false;
          })
        )
        .then(({ KR, result }) => KR.addForm('#myPaymentForm'))
        .then(({ KR, result }) => KR.showForm(result.formId))
        .catch(error =>
          setMessage(error + ' (see console for more details)')
        );
    }, [valor]);
  
    return (
      <div className="form">
        <img src={esloganizipay} alt="Izipay Logo" />
        <br />
        <br />
        <div className="container">
          <div id="myPaymentForm"></div>
          <div data-test="payment-message">{message}</div>
          <div data-test="payment-response">{orderStatus}</div>
          {/*<div data-test="payment-id">{orderId}</div>*/}
          <div data-test="payment-amount">{amount}</div>
        </div>
      </div>
    );
  };
  
  export default FormIziPay;