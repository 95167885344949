import React, { useState,useEffect,useRef } from 'react';
import Layout from "./Components/Layout";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link,useNavigate } from "react-router-dom";
import { useLocalStorage } from './useLocalStorage';
import Button from 'react-bootstrap/Button';
import imgLupa from '../src/img/Home/lupa.svg';
import imgSend from '../src/img/Home/send.svg';
import Calendar from './Components/Calendar';
import imgBack from '../src/img/CrearGrupo/arrow_left.svg';
import imgGym from '../src/img/CrearGrupo/gym.png';
import imgBicii from '../src/img/CrearGrupo/bicii.png';
import imgProfesioal from '../src/img/CrearGrupo/profesional.png';
import imgVisa from '../src/img/CrearGrupo/visa.png';
import imgPagoExitoso from '../src/img/CrearGrupo/pago_exitoso.svg';
import SubirFoto from './Components/SubirFoto';
import {FaCamera} from 'react-icons/fa'; // npm i react-icons
import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import LoadingSpinner from "../src/Components/LoadingSpinner";
import EventosBusqueda from './Components/EventosBusqueda';
import CanvasFoto from './Components/CanvasFoto';
import CanvasFoto2 from './Components/CanvasFoto2';
import imgPlanes from '../src/img/CrearGrupo/planes.svg';
import imgChecked from '../src/img/CrearGrupo/checked.svg';
import imgClose from '../src/img/CrearGrupo/close.svg';
import Modal from 'react-bootstrap/Modal';
import BotonPagoIzipay from './Components/BotonPagoIzipay';
import FormIziPay from './Components/FormIziPay';
import CouponForm from './Components/CouponForm';

function Detalle(){

const navigate = useNavigate();
let itop = 0;

const [items2, setItems2] = useState();
const [counter, setCounter] = useState(0);
const [isLoading, setIsLoading] = useLocalStorage('isLoading',true)
const [isVisible, setIsVisble] = useState('');
const [caractetisticas, setcaractetisticas] = useState();
const [vcaracteristica, setvcaracteristica] = useLocalStorage('vcaracteristica',[]);
const [vWidth, setWidth] = useState('');  
const [vHeight, setHeight] = useState('');
const [imagenSeleccionadaPerfilGrupo, setimagenSeleccionadaPerfilGrupo] = useLocalStorage('imagenSeleccionadaPerfilGrupo','');
const [imagenSeleccionadaPerfilGrupoADM, setimagenSeleccionadaPerfilGrupoADM] = useLocalStorage('imagenSeleccionadaPerfilGrupoADM','');
const [imagen, setImagen] = useLocalStorage('imagen',[]);
const [imagen2, setImagen2] = useLocalStorage('imagen2',[]);
const [vnombregrupo, setvnombregrupo] = useLocalStorage('vnombregrupo','');
const [parametro1, setparametro1] = useLocalStorage('parametro1','');
const [parametro2, setparametro2] = useLocalStorage('parametro2','');
const [parametro3, setparametro3] = useLocalStorage('parametro3','');
const [parametro4, setparametro4] = useLocalStorage('parametro4',false);
const [vplan, setvplan] = useLocalStorage('vplan','')
const [show, setShow] = useState(false);
const [bpago, setbpago] = useLocalStorage('bpago',false);
const [codigogrupoactual, setcodigogrupoactual] = useLocalStorage('codigogrupoactual','');
const [idusuario, setIdusuario] = useLocalStorage('idusuario','')
const [valorPlan, setValorPlan] = useState(4800);
const [valorBaseFijo, setVAlorBaseFijo] = useState(48.00)
const [valorBaseFijo2, setVAlorBaseFijo2] = useState(400.00)
const [valorPlanPrecioBase, setValorPlanPrecioBase] = useState(40.68)
const [valorPlanDecimal, setValorPlanDecimal] = useLocalStorage('valorPlanDecimal',48)
const [valorPlanIgv, setValorPlanIgv] = useState(7.32)
const [valorDescuento, setValorDescuento] = useState(0)
const [couponCode, setCouponCode] = useLocalStorage('couponCode','');
const [couponActivo, setCouponActivo] = useState(0);



  const planes = [['Eventos y asistentes ilimitados',0,1],
                  ['Promoción de grupos y eventos a miembros interesados',1,1],
                  ['Organización simple de eventos en línea y en persona',1,1],
                  ['Asignar co organizadores ilimitados',0,1],
                  ['Tarifas de entrada y cuotas de grupo',0,1],
                  ['Plataforma para administrar una red de grupos',1,1],
                  ['Acceso a los correos electrónicos de los asistentes',1,1],
                  ['Ayuda especializada de expertos de la comunidad',0,1]]




    const handleClick1  = (e) => 
    {

        if(counter >= 0)
        {
        setCounter(counter + 4);

        //fetchApi(counter + 4);
        }
        
        //console.log(counter);
    
    }

    const handleClick2  = (e) => 
    {

        if(counter > 0)
        {
        setCounter(counter - 4);
        
        //fetchApi(counter - 4);
        }
        //console.log(counter);
        
    }

    const limpiar = () => {

        localStorage.removeItem('imagenSeleccionadaPerfilGrupoADM')
        localStorage.removeItem('vcaracteristica')
        localStorage.removeItem('parametro1')
        localStorage.removeItem('parametro3')
        localStorage.removeItem('parametro4')
        localStorage.removeItem('imagen')
        localStorage.removeItem('imagen2')
        localStorage.removeItem('bpago')
        localStorage.removeItem('parametro2')
        localStorage.removeItem('vplan')
        localStorage.removeItem('imagenSeleccionadaPerfilGrupo')
        localStorage.removeItem('vnombregrupo')
    }

    const handleRedirectHome = () =>{

       setValorPlanPrecioBase(40.68);
       setValorPlan(4800)
       setValorPlanDecimal(48)
       setValorPlanIgv(7.32)

        navigate(-1);
    
      }
    
    //useEffect(() => {
     //   if(bpago == false){
      //      return;
       // }

        //RegistroGrupo()

    //},[bpago])

     
    useEffect(() => {
       if(vplan == 1){
            return;
       }

       setValorPlanPrecioBase(338.98);
       setValorPlan(40000)
       setValorPlanDecimal(400)
       setValorPlanIgv(61.02)

    },[])

    const RegistroGrupo = async () => {

       
        if(vnombregrupo == '')
        {
            alert("Debe ingresar un nombre de grupo para continuar.");
            return;
        }
        
        let valor = valorPlan;

    
        const data = JSON.stringify({
            pnombregrupo : vnombregrupo,pdescripciongrupo: parametro1,pnormasgrupo : parametro2,ppreguntasgrupo : parametro3,pgrupocerrado : (parametro4 == true ? 1 : 0),pimagengrupo:imagenSeleccionadaPerfilGrupo, ppimagengrupoadm:imagenSeleccionadaPerfilGrupoADM,pidusuario:idusuario,
           })
    
        
        if(bpago == true)
        {
            await fetch('https://conectate-peru.com/api/ApiRegistroGrupo.php', {
                method: 'POST',
                body: data,
                headers: {
                   'Content-type': 'application/json; charset=UTF-8',
                },
             })
                .then((response) => response.json())
                .then((data) => {
                   //setPosts((posts) => [data, ...posts]);
                   
                   //const respuesta = JSON.parse(data);
                   //alert("Grupo creado correctamente.")
                   const respuesta = data;
                   setcodigogrupoactual(respuesta)
                   limpiar()
                   navigate('/GrupoCreadoDetalle')
                })
                .catch((err) => {
                   console.log(err.message);
                });
        }
        
     };
    
   function formatCadena(cadena)
   {
      let newValor = cadena.replace(/[^A-Za-z ñ]/,"");
      //console.log(newValor);
  
      return newValor;
   }

   async function aplicarCupon()
   {

    if(couponCode != '')
    {
        if(couponActivo == 1)
        {
            alert("Solo se puede aplicar una sola vez el cupón!")
            return;
        }

        let valor = 0;

        let fd = new FormData() 
        fd.append("cupon",couponCode)

        const res = await axios.post('https://conectate-peru.com/api/ApiObtenerCupon.php',fd);
        
        if (res.data.items && res.data.items.length > 0) {
            res.data.items.map((item, index) => (
              item.Estado != 1 ? 
                alert("El cupón no está disponible, contactarse con el administrador para generar uno nuevo.") 
                : (valor = item.Porcentaje)
            ));
            alert("Se aplico correctamente el cupón. El cupón estará disponible hasta que se realice el pago.")
          } else {
            // Manejar el caso en que res.data.items está vacío
            alert("No existe el cupón ingresado!");
          }


        if(valor == 0)
        {
            return;
        }else{
            setCouponActivo(1);
        }

        
        let resultado = (valor / 100);

        let resultadoFinal = (valorPlanPrecioBase * resultado).toFixed(2);
        console.log("Descuento decimal:"+resultadoFinal);

        let valorDescuento = (valorPlanPrecioBase - resultadoFinal).toFixed(2);
        console.log("Precio con descuento: " + valorDescuento);

        setValorDescuento(resultadoFinal); // aqui va bien

        let igv = (valorDescuento * 1.18).toFixed(2);

        console.log("Precio plan"+igv);

        setValorPlanDecimal(igv);

        let igvResultado = (igv - valorDescuento).toFixed(2);

        console.log("Valor plan Igv:"+igvResultado);

        setValorPlanIgv(igvResultado);

        let numeroSinPunto = parseInt(igv.toString().replace('.', ''));
        
        console.log("Valor plan sin punto:",numeroSinPunto);
        
        setValorPlan(numeroSinPunto);
    }
    else{
        alert("Debe ingresar un cupón!");
    }
   }
  

  const handleSvgClick = () => {
    setShow(true)
  };

  const handleClose = () => {

    if(bpago == true)
    {
        //RegistroGrupo()
    }
    else{

        setShow(false)
    }

    };

  const handlePagoVisa = () => {
    setbpago(true);
  }
 
  
  

  const handleInputChange = (e) => {
    setCouponCode(e.target.value);
  };

return(<>

    <Layout>
        <Container style={{paddingTop:'calc(5em + 5vw)',paddingLeft:'15px'}}>
            <Row>
                <Col sm={3}>
                    <img src={imgBack} style={{float:'right',cursor:'pointer'}} onClick={handleRedirectHome}></img>
                </Col>
                <Col sm={6}>
                <p style={{fontSize:'12pt',opacity:'0.5'}}>Casi terminamos</p>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                   
                </Col>
                <Col sm={6}>
                <Container>
                    <Row>
                        <Col sm={6}>
                            <Container>
                                <Row style={{backgroundColor:(vplan == 1 ? 'rgba(146, 187, 0, 0.1)' : 'rgba(255, 232, 232, 0.8)'),borderRadius:'20px'}}>
                                    <Col sm={12} style={{paddingTop:'20px'}}>
                                    <center><p><b>Conéctate Plus</b></p></center> 
                                    </Col>
                                    <Col sm={12}>
                                    <center><p ><b style={{color: (vplan == 1) ? '#92BB00' : '#FF2D52'}}>S/  {(vplan == 1) ? valorBaseFijo : valorBaseFijo2}.00 x mes</b></p></center> 
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col sm={6}>
                           <center><p>Detalle del pedido</p></center> 
                           <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Plan</Form.Label>
                        <Form.Control
                            type="text"
                            value={(vplan == 1) ? 'Conéctate Plus' : 'Conéctate Experience'}
                            readOnly={true}
                            style={{fontSize:'10pt',color:'black',borderTop:'0',borderTopRightRadius:'0',borderTopLeftRadius:'0',borderRight:'0',borderLeft:'0',borderBottomLeftRadius:'0',borderBottomRightRadius:'0',borderBottom:(vplan == 1 ? '2px solid #92BB00' : '2px solid #FF2D52'),outline:'none'}}
                        />
                        </Form.Group>
                        </Col>
                    </Row>
                </Container>
                </Col>
                <Col sm={3}>
                    
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                   
                </Col>
                <Col sm={6}>
                <Container>
                    <Row>
                        <Col sm={6} style={{paddingTop:'10px'}}>
                            {/*<Container>
                                <Row style={{backgroundColor:(vplan == 2 ? 'rgba(255, 232, 232, 0.8)' : 'transparent'),borderRadius:'20px'}}>
                                    <Col sm={12} style={{paddingTop:'20px'}}>
                                    <center><p><b>Conéctate Experience</b></p></center> 
                                    </Col>
                                    <Col sm={12}>
                                    <center><p ><b style={{color:'#FF2D52'}}>S/ 120.00 x mes</b></p></center>
                                    </Col>
                                </Row>
</Container>*/}
                         <Container>
                            <Row>
                                <Col sm={12}>
                                <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label style={{fontSize:'10pt',color:'black'}}>Ingrese cupón de descuento</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={couponCode}
                                        onChange={handleInputChange}
                                        required
                                        style={{fontSize:'9pt',color:'black'}}
                                    />
                                </Form.Group>
                                </Col>
                                
                            </Row>
                            <Row style={{paddingTop:'10px'}}>
                                <Col sm={12}>
                                <Button variant='primary10' onClick={aplicarCupon}>
                                    <b>Aplicar Cupón</b>
                                    </Button>
                                </Col>
                            </Row>
    </Container>
                        </Col>
                        <Col sm={6} style={{paddingTop:'20px'}}>
                        
                        <Container>
                            <Row>
                                <Col sm={12}>
                                <p><b>Resumen del pedido</b></p>
                                </Col>
                            </Row>
                            <Row style={{borderTop:'0',borderTopRightRadius:'0',borderTopLeftRadius:'0',borderRight:'0',borderLeft:'0',borderBottomLeftRadius:'0',borderBottomRightRadius:'0',borderBottom:(vplan == 1 ? '2px solid #92BB00' : '2px solid #FF2D52'),outline:'none'}}>
                                <Col sm={8}>
                                    <p style={{fontSize:'10pt',color:(vplan == 1 ? '#92BB00' : '#FF2D52')}}>Total</p>
                                </Col>
                                <Col sm={4}>
                                    {(vplan == 1) ? <p style={{fontSize:'10pt',textAlign:'right',color:(vplan == 1 ? '#92BB00' : '#FF2D52')}} >S/. {valorPlanDecimal}.00</p> : <p style={{fontSize:'10pt',textAlign:'right',color:(vplan == 1 ? '#92BB00' : '#FF2D52')}}>S/. {valorPlanDecimal}.00</p>}
                                </Col>
                            </Row>
                            <Row style={{paddingTop:'10px'}}>
                                <Col sm={8}>
                                    <p style={{fontSize:'10pt'}}>Costo x mes</p>
                                </Col>
                                <Col sm={4}>
                                    {(vplan == 1) ? <p style={{fontSize:'10pt',textAlign:'right'}}>S/. {valorPlanPrecioBase}</p> : <p style={{fontSize:'10pt',textAlign:'right'}}>S/. {valorPlanPrecioBase}</p>}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={8}>
                                    <p style={{opacity:'0.5',fontSize:'8pt'}}>Descuento</p>
                                </Col>
                                <Col sm={4}>
                                   {(valorDescuento != 0 ) ? <p style={{opacity:'0.5',fontSize:'8pt',textAlign:'right'}}>S/. - {valorDescuento}</p>  : <p style={{opacity:'0.5',fontSize:'8pt',textAlign:'right'}}>S/. 0</p>} 
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={8}>
                                    <p style={{opacity:'0.5',fontSize:'8pt'}}>IGV</p>
                                </Col>
                                <Col sm={4}>
                                    {(vplan == 1) ? <p style={{opacity:'0.5',fontSize:'8pt',textAlign:'right'}}>S/. {valorPlanIgv}</p> : <p style={{opacity:'0.5',fontSize:'8pt',textAlign:'right'}}>S/. {valorPlanIgv}</p>}
                                </Col>
                            </Row>
                        </Container>
                        </Col>
                    </Row>
                </Container>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                   
                </Col>
                <Col sm={6}>
                <Container>
                    <Row>
                        <Col sm={4}>
                        </Col>
                        <Col sm={2}>
                  
                        </Col>
                        <Col sm={6}>
                        <center>
                <Button variant='primary3' onClick={handleSvgClick}>
                                    <b>Ir a pagar</b>
                                    </Button>
                </center>
                        </Col>
                    </Row>
                </Container>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
        </Container>

        <Modal centered={true} show={show} onHide={handleClose}>
        <Modal.Header style={{border:'none'}} closeButton>
        </Modal.Header>
        <Modal.Body>
           {//<center><img src={bpago == true ? imgPagoExitoso : imgVisa} style={{width:(bpago == 1 ? '80px' : '450px'),cursor:'pointer'}} onClick={handlePagoVisa} ></img></center>
           }
           <center><FormIziPay valor={valorPlan}></FormIziPay></center>
           
        </Modal.Body>
      </Modal>

    </Layout>



</>);


}

export default Detalle;