import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
  } from 'react-places-autocomplete';
  import axios from 'axios'; // npm i axios

  var map;

  const API_KEY = 'AIzaSyBPsQ2tRu5JrJU_9yFFhsh1tKEq1JyUsOE';

 export default function SearchGoogleMaps()
  {
    const [address,setAddress] = useState('');
    const [coordinates,setCoordinstes] = useState({
        lat:null,
        lng:null
    })
    const [listaAuncios, setListaAnuncios] = useState([]);

    const handleSelect = async value => {
    const results = await geocodeByAddress(value);
    
    const ll = await getLatLng(results[0]);
    
    setAddress(value);
    setCoordinstes(ll);

    }

    const handleClean = () =>
    {
      document.getElementById("txtBusqueda").value = '';
    }

    useEffect(() => {
        
        if(coordinates.lat == null){
            return;
        }
        sessionStorage.setItem("CoordinatesLat",JSON.stringify(coordinates.lat));
        sessionStorage.setItem("CoordinatesLng",JSON.stringify(coordinates.lng));
        sessionStorage.setItem("address",JSON.stringify(address));
        
    },[coordinates.lat,coordinates.lng])

    const searchOptions = {
      componentRestrictions: { country: ['pe'] },
      types: ['address']
    }

    return(
        <>
        <PlacesAutocomplete
        searchOptions={searchOptions}
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="form-control borderInputSearch" style={{height:'100%',fontSize:'8pt'}}>
            <input id="txtBusqueda" onFocus={handleClean} type='search' style={{height:'100%'}}
              {...getInputProps({
                placeholder: 'Busca un lugar ...',
                //className: 'location-search-input',
                className:'location-search-input borderInputSearch'
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Buscando...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                      
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      </>
    )

  }