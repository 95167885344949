import React, { useState,useEffect } from 'react';
import Layout from "./Components/Layout";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link,useNavigate } from "react-router-dom";
import { useLocalStorage } from './useLocalStorage';
import Button from 'react-bootstrap/Button';
import imgLupa from '../src/img/Home/lupa.svg';
import imgSend from '../src/img/Home/send.svg';
import Calendar from './Components/Calendar';
import imgBack from '../src/img/CrearGrupo/arrow_left.svg';
import imgGym from '../src/img/CrearGrupo/gym.png';
import imgSinEventos from '../src/img/CrearGrupo/nou.png';

import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import LoadingSpinner from "../src/Components/LoadingSpinner";
import EventosBusqueda from './Components/EventosBusqueda';
import EventosDetalles from './Components/EventosDetalles';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import EventosDetalleGrupo from './Components/EventosDetalleGrupo';


function GrupoCreadoDetalle(){

const navigate = useNavigate();
let itop = 0;

const [items2, setItems2] = useState();
const [counter, setCounter] = useState(0);
const [isLoading, setIsLoading] = useState(false);
const [isVisible, setIsVisble] = useState('');
const [caractetisticas, setcaractetisticas] = useState();
const [vcaracteristica, setvcaracteristica] = useLocalStorage('vcaracteristica','');
const [imagenSeleccionadaPerfilGrupo, setimagenSeleccionadaPerfilGrupo] = useLocalStorage('imagenSeleccionadaPerfilGrupo','');
const [imagenSeleccionadaPerfilGrupoADM, setimagenSeleccionadaPerfilGrupoADM] = useLocalStorage('imagenSeleccionadaPerfilGrupoADM','');
const [imagen, setImagen] = useLocalStorage('imagen',[]);
const [imagen2, setImagen2] = useLocalStorage('imagen2',[]);
const [vnombregrupo, setvnombregrupo] = useLocalStorage('vnombregrupo','');
const [parametro1, setparametro1] = useLocalStorage('parametro1','');
const [parametro2, setparametro2] = useLocalStorage('parametro2','');
const [parametro3, setparametro3] = useLocalStorage('parametro3','');
const [parametro4, setparametro4] = useLocalStorage('parametro4',false);
const [tabIndex, setTabIndex] = useLocalStorage('tabIndex',0);
const [items, setItems] = useState();
const [fecha,setfecha] = useState(new Date())
const [fecharegistro, setfecharegistro] = useLocalStorage('fecharegistro','')
const [codigogrupoactual, setcodigogrupoactual] = useLocalStorage('codigogrupoactual','');
const [idusuario, setIdusuario] = useLocalStorage('idusuario','')
const [idusuarioselect, setIdusuarioselect] = useState('')
const [bmigrupo, setmigrupo] = useLocalStorage('bmigrupo',false)



const getGrupo = async () => {

        if(codigogrupoactual != '')
        {
            let fd = new FormData() 
            fd.append("pidusuario",idusuario)
            fd.append("pid",codigogrupoactual)

            const response = await axios.post('https://conectate-peru.com/api/ApiListarGruposUsuarioId.php',fd);

            if(idusuario != "" && response.data != 0)
            {
            
            
            response.data.items.map((item,index) =>{

                const options = { year: 'numeric', month: 'long', day: 'numeric' };

                setfecha(item.FechaRegistro)

                const fechaFormateada = fecha.toLocaleDateString('es-ES', options);

                setvnombregrupo(item.NombreGrupo)
                setparametro1(item.DescripcionGrupo)
                setparametro2(item.NormasGrupo)
                setparametro3(item.PreguntasGrupo)
                setparametro4(item.GrupoCerrado)
                setimagenSeleccionadaPerfilGrupo(item.ImagenGrupo)
                setimagenSeleccionadaPerfilGrupoADM(item.ImagenGrupoAdm)
                setfecharegistro(fechaFormateada)
                setIdusuarioselect(item.idusuario)

                if(parseInt(item.idusuario) == parseInt(idusuario))
                {
                    setmigrupo(true);
                }

            })           
            }
        }
  }


useEffect(() => {
    window.scrollTo(0, 0);
    getGrupo()
    },[])


    const handleRedirectHome = () =>{

        setmigrupo(false)
        navigate(-1);
    
      }

      const handleCLickTab = (index) =>
      {
          setTabIndex(index)
      }
   


return(<>

    <Layout>
        <Container style={{paddingTop:'calc(5em + 5vw)'}}>
            <Row>
                <Col sm={3}>
                    <img src={imgBack} style={{float:'right',cursor:'pointer'}} onClick={handleRedirectHome}></img>
                </Col>
                <Col sm={6}>
                <p style={{fontSize:'12pt',color:'#FF2D52'}}>Volver</p>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                   
                </Col>
                <Col sm={6}>
                <EventosDetalles ListaImagenes={imagenSeleccionadaPerfilGrupo} Titulo={vnombregrupo} FechaRegistro={fecharegistro} MiGrupo ={bmigrupo}></EventosDetalles>
                </Col>
                <Col sm={3}>
                
                </Col>
            </Row>

            <Row style={{paddingTop:'20px'}}>
                <Col sm={3}>
                    
                </Col>
                <Col sm={6}>
                <Tabs id='tabprincipal' selectedIndex={tabIndex} onSelect={(index) => handleCLickTab(index)} style={{borderBottom: '1px', borderColor: 'black',fontSize:'10pt'}}>
      <TabList>
        <Tab value={1}>La comunidad</Tab>
        <Tab value={2}>Eventos</Tab>
        <Tab value={3}>Fotos</Tab>
        <Tab value={4}>Chat</Tab>
      </TabList>
      <TabPanel value={1}>
        <Container>
            <br></br>
            <Row>
                <Col sm={12}>
                    <p style={{fontSize:'12pt'}}><b>Descubre esta experiencia</b></p>
                </Col>
                <Col sm={12}>
                    <p style={{fontSize:'10pt'}}>{parametro1}</p>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <p style={{fontSize:'12pt'}}><b>Miembros</b></p>
                </Col>
                <Col sm={12}>
                <p style={{fontSize:'10pt'}}>No hay datos para mostrar</p>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <p style={{fontSize:'12pt'}}><b>Proximos Eventos</b></p>
                </Col>
                <Col sm={12}>
                <center>
                <img src={imgSinEventos} style={{width:'120px'}}></img>
                </center>
                </Col>
                
            </Row>
        </Container>
      </TabPanel>
      <TabPanel value={2}>
      <div style={{width:'100%'}}>
      <EventosDetalleGrupo></EventosDetalleGrupo>
      </div>
      </TabPanel>
      <TabPanel value={3}>
         <div style={{height:'200px'}}>
            <p>No hay datos para mostrar</p>
        </div>
      </TabPanel>
      <TabPanel value={4}>   
      <div style={{height:'200px'}}>
            <p>No hay datos para mostrar</p>
        </div>     
      </TabPanel>
    </Tabs>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
          



        </Container>
    </Layout>



</>);


}

export default GrupoCreadoDetalle;