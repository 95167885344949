import React, { useState,useEffect } from 'react';
import Layout from "./Components/Layout";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import imgLogo from '../src/img/Login/logo.svg';
import imgEscaladaNieve from '../src/img/Login/escalada_nieve.jpg';
import { Link,useNavigate } from "react-router-dom";
import { useLocalStorage } from './useLocalStorage';
import {FaCheckCircle, FaTrash,BiFilterAlt,FaEyeSlash,FaEye } from 'react-icons/fa'; // npm i react-icons
import axios from 'axios'; // npm i axios
import Modal from 'react-bootstrap/Modal';

function Login()
{
    const [show, setShow] = useState(false);
    const [bEyes, setEyes] = useState(false);
    const [show2, setShow2] = useState(false);
    const [correo, setCorreo] = useState('');
    const [contraseña, setContraseña] = useState('');
    const [idusuario, setIdusuario] = useLocalStorage('idusuario','')
    const [idtipousuario, setIdtipousuario] = useLocalStorage('idtipousuario','')
    const [nombreUsuario, setnombreUsuario] = useLocalStorage('nombreUsuario','')
    const [apellidoUsuario, setapellidoUsuario] = useLocalStorage('apellidoUsuario','')
    

    const handleClose2 = () => setShow2(false);
    const navigate = useNavigate();

    const handleClickEyes = () => {
  
      if(bEyes == true)
      {
        setEyes(false);
      }
      else
      {
        setEyes(true);
      }
    }
  
    const ValidaAcceso = async (correo, contraseña) => {
  
      if(correo == '' && contraseña == '')
      {
          return;
      }
  
      await fetch('https://conectate-peru.com/api/ApiValidaAcceso.php', {
         method: 'POST',
         body: JSON.stringify({
            correo: correo,
            clave: contraseña,
         }),
         headers: {
            'Content-type': 'application/json; charset=UTF-8',
         },
      })
         .then((response) => response.json())
         .then((data) => {
            //setPosts((posts) => [data, ...posts]);
  
            const respuesta = JSON.parse(data);
            console.log(respuesta);
  
            if(respuesta == 0 || correo == '' || contraseña == '')
            {
               alert("Usuario y/o contraseña incorrecta, Verifique sus datos.");   
            }else if (respuesta != 0){
  
              sessionStorage.setItem("UsuarioActivo",JSON.stringify(correo));
              setIdusuario(respuesta);
  
              setCorreo('');
              setContraseña('');
              
              //window.location.reload(true);
            }
  
       
         })
         .catch((err) => {
            console.log(err.message);
         });
   };

   const RecuperarContrasenia = async (correo) => {
  
    if(correo == '')
    {
        return;
    }

    await fetch('https://conectate-peru.com/api/ApiSendMailRecuperarContrasenia.php', {
       method: 'POST',
       body: JSON.stringify({
          correo: correo,
       }),
       headers: {
          'Content-type': 'application/json; charset=UTF-8',
       },
    })
       .then((response) => response.json())
       .then((data) => {
          //setPosts((posts) => [data, ...posts]);

          const respuesta = JSON.parse(data);
          console.log(respuesta);

          if(respuesta == 0 || correo == '')
          {
             alert("Usuario es incorrecto o no existe, Verifique sus datos.");   
          }else if (respuesta != 0){

            //sessionStorage.setItem("UsuarioActivo",JSON.stringify(correo));
            //setIdusuario(respuesta);

            setCorreo('');
            alert("Verifique su bandeja de entrada, recibira los pasos para el cambio de contraseña.");   
            //setContraseña('');
            
            //window.location.reload(true);
          }

     
       })
       .catch((err) => {
          console.log(err.message);
       });
 };


 const handleSubmit = (e) => {
  
  e.preventDefault();
  ValidaAcceso(correo,contraseña);

}; 
  
   const handleSubmit2 = (e) => {
  
      e.preventDefault();
      RecuperarContrasenia(correo);
  
   }; 

   const handleClickRecuperar = () => {
    setShow2(true)
  };

   const handleRedirectRegistro = () =>{

    navigate('/Registro');

  }

  useEffect(() => {
    if(idusuario != '')
    {
      ObtenerInfoUsuario(idusuario);
    }
  },[idusuario])

  useEffect(() => {
    // Obtener la fecha actual
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    
    // Obtener la marca de tiempo almacenada en localStorage
    const lastOpenedDate = localStorage.getItem('lastOpenedDate');
    
    // Si no hay una marca de tiempo almacenada o si la marca de tiempo almacenada no es del mismo día que hoy, entonces es la primera vez que se abre en el día
    if (!lastOpenedDate || (new Date(lastOpenedDate).getDate() !== currentDay || new Date(lastOpenedDate).getMonth() !== currentMonth || new Date(lastOpenedDate).getFullYear() !== currentYear)) {
      // Aquí puedes realizar cualquier acción que necesites realizar cuando la página se abra por primera vez en el día
      console.log('La página se abrió por primera vez hoy.');
      
      if(!lastOpenedDate)
      {
        sessionStorage.clear();
        localStorage.clear();
      }

      // Actualizar la marca de tiempo en localStorage
      localStorage.setItem('lastOpenedDate', currentDate);
    }else{
        sessionStorage.clear();
        localStorage.clear();
    }
  }, []);

  const ObtenerInfoUsuario = async (pidusuario) =>
  {
        let fd = new FormData() 
        fd.append("pidusuario",pidusuario)
     
        
        const res3 = await axios.post('https://conectate-peru.com/api/ApiObtenerUsuariof.php', fd);
        
        
        res3.data.items.map((item, index) => {
          
          setnombreUsuario(item.Nombre);
          setapellidoUsuario(item.ApellidoPaterno);
          setIdtipousuario(item.idTipo_Usuario);

          if(item.idTipo_Usuario == "3") // Solo administrador
          {
            navigate('/CrearCupon');
          }else
          {
            navigate('/HomeUser');
          }
 
        })
  }



return(
  <div className="split-container">
  <div className="split-left d-none d-sm-block">
    <Container style={{ padding: '0' }}>
      <Row>
        <Col sm={12}>
          <img
            src={imgEscaladaNieve}
            style={{ width: '100%', height: '100%', objectFit: 'cover', alignItems: 'center', justifyContent: 'center' }}
            alt="Escalada en Nieve"
          />
        </Col>
      </Row>
    </Container>
  </div>
  <div className="split-right">
    <Container style={{ paddingTop: 'calc(5em + 5vw)' }}>
      <Row>
        <Col sm={12}>
          <center>
            <img src={imgLogo} alt="Logo"></img>
          </center>
        </Col>
      </Row>
      <Row style={{ paddingTop: '40px' }}>
        <Col sm={12}>
          <center>
            <p style={{ fontSize: '12pt' }}><b>Inicia sesión</b></p>
          </center>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} sm={10} md={8} lg={6}>
          <Form onSubmit={handleSubmit}>
            <Container style={{ padding: '0' }}>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="exampleForm2.ControlInput1">
                    <Form.Label style={{ fontSize: '10pt' }}><b>Usuario</b></Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="nombre@dominio.com"
                      autoFocus
                      value={correo}
                      onChange={(e) => setCorreo(e.target.value)}
                      style={{ fontSize: '9pt', color: 'black' }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="exampleForm2.ControlInput2">
                    <Form.Label style={{ fontSize: '10pt' }}><b>Contraseña</b></Form.Label>
                    <Form.Control
                      type={bEyes ? "text" : "password"}
                      value={contraseña}
                      onChange={(e) => setContraseña(e.target.value)}
                      style={{ fontSize: '9pt', color: 'black' }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <p><a style={{ fontSize: '8pt', cursor:'pointer' }} onClick={handleClickRecuperar} >¿Olvidaste tu contraseña?</a></p>
                </Col>
              </Row>
            </Container>
            <br />
            <Container>
              <Row>
                <Col xs={12}>
                  <center>
                    <Button variant="primary3" type='submit'>
                      <b style={{ fontSize: '12pt' }}>Aceptar</b>
                    </Button>
                  </center>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={12}>
                  <center>
                    <p style={{ fontSize: '12pt', cursor: 'pointer' }} onClick={handleRedirectRegistro}><b>Registrarme</b></p>
                  </center>
                </Col>
              </Row>
            </Container>
          </Form>
        </Col>
      </Row>
    </Container>
    <Modal  centered={true}  show={show2} onHide={handleClose2}>
        <Modal.Header style={{border:'none'}} closeButton >
          <Container>
            <br></br>
            <Row>
              <Col sm={12}>
              <center><Modal.Title style={{fontSize:'15pt',height:'30px'}}><p><b>Recuperar Contraseña</b></p></Modal.Title></center>
              </Col>
            </Row>
          </Container>
          <br></br>
        </Modal.Header>
        <Modal.Body style={{borderRadius:'5px'}}>
        <br></br>
        <br></br>
          <Form onSubmit={handleSubmit2}>
            <Container style={{padding:'0 0 0 0'}}>
              <Row>
                <Col sm={2}>

                </Col>
                <Col sm={8}>
                <Container style={{padding:'0 0 0 0'}}>
                  <Row style={{paddingBottom:'30px'}}>
                    <Col sm={12}>
                        <Form.Group className="sm-3" controlId="exampleForm2.ControlInput1">
                    <Form.Label style={{fontSize:'12pt'}}><b>Ingrese correo electrónico</b></Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="nombre@dominio.com"
                      autoFocus
                      value={correo}
                      onChange={(e) => setCorreo(e.target.value)}
                      style={{fontSize:'12pt',color:'black'}}
                    />
                  </Form.Group>
                    </Col>
                  </Row>
                  <Row style={{paddingBottom:'70px'}}>
                    
                  </Row>
                </Container>
         
                </Col>
                <Col sm={2}>

                </Col>
              </Row>
            </Container>
            <Container>
            <Row>
                <Col sm={12}>
               <center>
               <Button variant="primary4" type='submit'>
                      <b style={{fontSize:'9pt'}}>Recuperar</b>  
                    </Button>
                </center> 
                </Col>
            </Row>
        </Container>
          </Form>
          <br></br>
        </Modal.Body>
      </Modal>
  </div>
</div>
);

}

export default Login;