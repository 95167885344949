import React from 'react';
import PropTypes from 'prop-types'; // ADDED
import {drawImage} from 'canvas-object-fit';

const getBase64StringFromDataURL = (dataURL) =>
    dataURL.replace('data:', '').replace(/^.+,/, '');


const CanvasFoto2 = ( {src,height,width,index} ) => { // CHANGED
  const canvas = React.useRef();
  const base64 = '';

  React.useEffect(() => {                             
    const context = canvas.current.getContext('2d'); 
    const image = new Image();
    image.src = src;

    image.onload = () => {
      //context.drawImage(image,0,0);
      drawImage(context, image, 0, 0,width,height,{objectFit: 'cover'});
    };
    //draw(context); // ADDED
  });
  return (

 <canvas
      ref={canvas}
      width={width}
      height={height}
      className='canvasperfil'
      id={'canvas-foto2'}
      key={index}
    />
  )
};
// ADDED
CanvasFoto2.propTypes = {
  height: PropTypes.func.isRequired,
  width: PropTypes.func.isRequired,
};
export default CanvasFoto2;