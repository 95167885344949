import React,{useState,useEffect} from "react";
import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import { Link,useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Carousel from "react-bootstrap/Carousel";
import LoadingSpinner from "./LoadingSpinner";
import imgTagVarios from "../img/Home/tag_varios.svg";
import { useLocalStorage } from "../useLocalStorage";


function BusquedaGrupos({IdGrupo,NombreGrupo,DescripcionGrupo,GrupoCerrado,ImagenGrupo,UsuarioAdmin})
{

const [items, setItems] = useState();
const [index, setIndex] = useState(0);
const [letrasAleatorias, setLetrasAleatorias] = useState([]);
const [fecha, setfecha] = useState(new Date())
const [fechaFormat, setFechaformateada] = useState('')
const [cantidadMiembros, setCantidadMiembros] = useState(0)
const [codigogrupoactual, setcodigogrupoactual] = useLocalStorage('codigogrupoactual','');
const navigate = useNavigate();

const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleRedirect = () => {
    
    setcodigogrupoactual(JSON.parse(IdGrupo))
    navigate('/GrupoCreadoDetalle');
  
  }


async function fetchApi()
{
  
   // const jsonNew = JSON.parse(ListaImagenes);
   // setItems(jsonNew.items);
   /*const options = { year: 'numeric', month: 'long', day: 'numeric' };
   setfecha(FechaInicio)
   const fechaFormateada = fecha.toLocaleDateString('es-ES', options);
   setFechaformateada(fechaFormateada);*/

    generarLetrasAleatorias();

}

const generarLetrasAleatorias = () => {
    const letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const letrasArray = letras.split('');
    const letrasAleatoriasArray = [];

    for (let i = 0; i < 3; i++) {
      const indiceAleatorio = Math.floor(Math.random() * letrasArray.length);
      letrasAleatoriasArray.push(letrasArray[indiceAleatorio]);
    }

    setLetrasAleatorias(letrasAleatoriasArray);
  };

useEffect(() => {
    fetchApi();
    },[])

    return (
        <>
         <Container style={{padding:'30px 0 0 0',overflow:'hidden'}}>
            <Row>
              <Col xs={6}>
                  <center>
                  <LazyLoadImage
                 effect="blur"
                 src={ImagenGrupo}
                 style={{ width: "calc(280px + 2vmin)",
                 height: "calc(170px + 2vmin)",
                 objectFit: "cover" ,borderTopRightRadius:'10px',borderTopLeftRadius:'10px'}}
                 onClick={(e) => handleRedirect()}
                 ></LazyLoadImage>
                  </center>
              </Col>
              <Col xs={6}>
              <Container style={{padding:'0 0 0 0'}}>
                <Row>
                  <Col xs={12}>
                    <p style={{fontSize:"18pt",padding:'0 0 0 0',textAlign:"left"}}><b>{NombreGrupo}</b></p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <p style={{fontSize:"8pt",padding:'0 0 0 0',textAlign:"left"}}>{DescripcionGrupo}</p>
                  </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                       
                    {letrasAleatorias.map((letra, index) => (
                        
                        (index == 0) ? <><div class="circle red"><center><p style={{padding:'5px 0 0 0'}}>{letra}</p></center></div></> : ((index == 1) ? <><div class="circle green"><center><p style={{padding:'5px 0 0 0'}}>{letra}</p></center></div></> : <><div class="circle blue"><center><p style={{padding:'5px 0 0 0'}}>{letra}</p></center></div></> )
                        
                        ))}
                    </Col>
                  <Col xs={8}>
                    <p style={{fontSize:"12pt",padding:'0 0 0 0',textAlign:"left"}}>{cantidadMiembros} miembros</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <p style={{fontSize:"12pt",padding:'0 0 0 0',textAlign:"left"}}>{GrupoCerrado == 1 ? 'Cerrado' : 'Público'}</p>
                  </Col>
                </Row>
               
              </Container>
              </Col>
            </Row>
            </Container>  
        </>
    );


}




export default BusquedaGrupos;