import React, { useState,useEffect } from 'react';
import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import LoadingSpinner from "./LoadingSpinner";
import { Link,useNavigate } from "react-router-dom";
import Eventos from './Eventos';


function TopEventos2({isHome})
{

    let itop = 0;

    


     const [items2, setItems2] = useState();
     const [counter, setCounter] = useState(0);
     const [isLoading, setIsLoading] = useState(false);
     const [isVisible, setIsVisble] = useState('');     
     const [eventoActual, setEventoActual] = useState(null);

     async function fetchApi(top){
        setIsLoading(true);
        let fd = new FormData() 
        fd.append("top",top)

        const res = await axios.post('https://conectate-peru.com/api/ApiListarEventos.php',fd);
        setItems2(res.data.items);
        setIsVisble(res.data.items[0].visible);
        setIsLoading(false);
        
        const vEventoActual = JSON.parse(sessionStorage.getItem("EventoActual"));
        setEventoActual(vEventoActual)
     }

     const navigate = useNavigate();

    useEffect(() => {
        fetchApi(itop);
        
        },[])
    
    useEffect(() => {

        let isDragging = false; // Variable para rastrear si se está arrastrando
        let startX; // Posición inicial del touch al iniciar el arrastre
        let scrollLeft; // Posición inicial del scroll al iniciar el arrastre
        let currentPosition; // Posición actual del carrusel antes de salir
        
        const carrusel = document.getElementById('litopgrupos');
        
        carrusel.addEventListener('mousedown', (event) => {
            isDragging = true; // Comienza el arrastre
            startX = event.clientX; // Guarda la posición inicial del mouse
            scrollLeft = carrusel.scrollLeft; // Guarda la posición inicial del scroll
        });
        
        carrusel.addEventListener('touchstart', (event) => {
            isDragging = true; // Comienza el arrastre
            startX = event.touches[0].clientX; // Guarda la posición inicial del touch
            scrollLeft = carrusel.scrollLeft; // Guarda la posición inicial del scroll
        });
        
        carrusel.addEventListener('mouseup', (event) => {
            if (isDragging) {
                isDragging = false; // Termina el arrastre
                return; // Si se estaba arrastrando, no hacer nada más
            }
        
            // Si no se estaba arrastrando, verificar si fue un clic
            const clickX = event.clientX;
            const deltaX = Math.abs(clickX - startX);
        
            if (deltaX < 5) {
                // Si la distancia del clic es menor que 5px, avanzar al siguiente elemento
                const currentItemIndex = Math.floor(scrollLeft / carrusel.offsetWidth);
                const nextItemIndex = currentItemIndex + 1;
                carrusel.scrollTo({
                    left: nextItemIndex * carrusel.offsetWidth,
                    behavior: 'smooth'
                });
            }
        });
        
        carrusel.addEventListener('mousemove', (event) => {
            if (!isDragging) return; // Si no se está arrastrando, salir
        
            const x = event.clientX; // Posición actual del mouse
            const deltaX = x - startX; // Diferencia entre la posición inicial y la actual del mouse
            carrusel.scrollLeft = scrollLeft - deltaX; // Actualiza la posición del scroll
        });
        
        carrusel.addEventListener('touchmove', (event) => {
            if (!isDragging) return; // Si no se está arrastrando, salir
        
            const x = event.touches[0].clientX; // Posición actual del touch
            const deltaX = x - startX; // Diferencia entre la posición inicial y la actual del touch
            carrusel.scrollLeft = scrollLeft - deltaX; // Actualiza la posición del scroll
        });
        
        carrusel.addEventListener('mouseleave', () => {
            // Guardar la posición actual del carrusel al salir
            //currentPosition = carrusel.scrollLeft;
            isDragging = false;
        });
        
        window.addEventListener('mouseup', () => {
            // Restaurar la posición del carrusel cuando el mouse se levanta fuera del carrusel
            carrusel.scrollTo({
                left: currentPosition,
                behavior: 'smooth'
            });
        });
        
        window.addEventListener('touchend', () => {
            // Restaurar la posición del carrusel cuando el touch se levanta fuera del carrusel
            carrusel.scrollTo({
                left: currentPosition,
                behavior: 'smooth'
            });
        });


    }, []); // El segundo argumento [] asegura que este efecto solo se ejecute una vez después del montaje inicial

        const handleClick1  = (e) => 
        {

            if(counter >= 0)
            {
            setCounter(counter + 4);

            fetchApi(counter + 4);
            }
            
            //console.log(counter);
        
        }

        const handleClick2  = (e) => 
        {

            if(counter > 0)
            {
            setCounter(counter - 4);
            
            fetchApi(counter - 4);
            }
            //console.log(counter);
            
        }

        
    return (
        <>
        <div style={{ position: 'relative', top: '0', paddingBottom: 'calc(0.5em + 0.5vw)',border:'none' }}>
            <ul id='litopgrupos' className="carrusel2" style={{ textAlign: items2 && items2.length === 1 ? 'center' : 'left' }}>
                    {!items2 ? 'Cargando Top Conectate....' : items2.map((item, index) => (
                    <li key={index} className='toplinew2'>
                        <Eventos
                        ImagenPortada={item.ImagenPortada}
                        FechaInicio={item.FechaInicio}
                        FechaFin={item.FechaFin}
                        HoraInicio={item.HoraInicio}
                        HoraFin={item.HoraFin}
                        Titulo={item.Titulo}
                        Direccion={item.Direccion}
                        Participantes={item.Participantes}
                        IdEvento={item.Id}
                        IsHome={isHome}
                        />
                    </li>
                    ))}
                </ul>
            </div>
        </>
    );
}

export default TopEventos2;