import React, { useState,useEffect } from 'react';
import Layout from "./Components/Layout";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link,useNavigate } from "react-router-dom";
import { useLocalStorage } from './useLocalStorage';
import Button from 'react-bootstrap/Button';
import imgLupa from '../src/img/Home/lupa.svg';
import imgSend from '../src/img/Home/send.svg';
import Calendar from './Components/Calendar';
import imgBack from '../src/img/CrearGrupo/arrow_left.svg';
import imgGym from '../src/img/CrearGrupo/gym.png';
import imgSinEventos from '../src/img/CrearGrupo/nou.png';
import imgShare from '../src/img/Evento/compartir.svg';
import imgDisable from '../src/img/Evento/chat_disable.svg';
import imgEnable from '../src/img/Evento/chat_enable.svg';
import imgCalendar from '../src/img/Evento/calendar.svg';

import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import LoadingSpinner from "./Components/LoadingSpinner";
import EventosBusqueda from './Components/EventosBusqueda';
import EventosDetalles from './Components/EventosDetalles';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import MapaDetalleAnuncio from './Components/MapaDetalleAnuncio';
import TopEventosDetalle from './Components/TopEventosDetalle';
import TopEventosDetalle2 from './Components/TopEventosDetalle2';
import TopEventos2 from './Components/TopEventos2';


function EventoDetalle(){

const navigate = useNavigate();
let itop = 0;



const [items2, setItems2] = useState();
const [counter, setCounter] = useState(0);
const [isLoading, setIsLoading] = useState(false);
const [isVisible, setIsVisble] = useState('');
const [caractetisticas, setcaractetisticas] = useState();
const [vcaracteristica, setvcaracteristica] = useLocalStorage('vcaracteristica','');
const [ImagenPortada, setImagenPortada] = useLocalStorage('ImagenPortada','');
const [Titulo, setTitulo] = useState('');
const [Descripcion, setDescripcion] = useState('');
const [FechaInicio, setFechaInicio] = useState('');
const [HoraInicio, setHoraInicio] = useState('');
const [Latitud, setLatitud] = useState('');
const [Longitud, setLongitud] = useState('');
const [Participantes, setParticipantes] = useState('');
const [MonedaPrecio, setMonedaPrecio] = useState('');
const [Direccion, setDireccion] = useLocalStorage('Direccion','');
const [letrasAleatorias, setLetrasAleatorias] = useState([]);
const [items, setItems] = useState();
const [fecha,setfecha] = useState(new Date())
const [fecharegistro, setfecharegistro] = useLocalStorage('fecharegistro','')
const [codigogrupoactual, setcodigogrupoactual] = useLocalStorage('codigogrupoactual','');
const [idusuario, setIdusuario] = useLocalStorage('idusuario','')
const [tienecosto, settienecosto] = useLocalStorage('tienecosto','')
const [precio, setprecio] = useLocalStorage('precio','')
const [eventoActual, setEventoActual] = useState(null)
const [cantidadAsistentes, setCantidadAsistentes] = useState(0)
const [bInscrito, setbinscrito] = useLocalStorage('bInscrito',false)
const [bseleccionoPago, setseleccionopago] = useLocalStorage('bseleccionoPago',false)
const [usuarioActivo,setusuarioActivo] = useState(() => {

    const UsuActivo = sessionStorage.getItem("UsuarioActivo");
   
    
    if(UsuActivo == null)
    {
        return;
    }
    
    const UsuActivoS  = JSON.parse(UsuActivo);
  
    return UsuActivoS || "";
    });
  


const getEvento = async () => {
        
        const vEventoActual = JSON.parse(sessionStorage.getItem("EventoActual"));

        setEventoActual(vEventoActual)

        if(vEventoActual != '')
        {
            let fd = new FormData() 
            fd.append("pid",vEventoActual)

            const response = await axios.post('https://conectate-peru.com/api/ApiListarEventosId.php',fd);

            if(response.data != 0)
            {

            response.data.items.map((item,index) =>{

                const options = { year: 'numeric', month: 'long', day: 'numeric' };

                const fechaFormateada = fecha.toLocaleDateString('es-ES', options);

                setfecharegistro(fechaFormateada)            
                setTitulo(item.Titulo)
                setDescripcion(item.Descripcion)
                setFechaInicio(item.FechaInicio)
                setHoraInicio(item.HoraInicio)
                setLatitud(item.Latitud)
                setLongitud(item.Longitud)
                setParticipantes(item.Participantes)
                setMonedaPrecio(item.MonedaPrecio)
                setImagenPortada(item.ImagenPortada)
                setDireccion(item.Direccion)
                settienecosto(item.Costo)
                setprecio(item.Precio)
                sessionStorage.setItem("LatitudActualSeleccion",JSON.stringify(item.Latitud));
                sessionStorage.setItem("LongitudActualSeleccion",JSON.stringify(item.Longitud));
                sessionStorage.setItem("ImagenPortadaActualSeleccion",JSON.stringify(item.ImagenPortada));
                
            })           
            }
        }
  }

  const getCantidadAsistentes = async () => {
        
    const vEventoActual = JSON.parse(sessionStorage.getItem("EventoActual"));

    setEventoActual(vEventoActual)

    if(vEventoActual != '')
    {
        let fd = new FormData() 
        fd.append("pidevento",vEventoActual)

        const response = await axios.post('https://conectate-peru.com/api/ApiObtenerCantidadAsistencias.php',fd);

        if(response.data != 0)
        {

            response.data.items.map((item,index) =>{

            setCantidadAsistentes(parseInt(item.CantidadEvento))
            
            })           
        }
    }
}

const registroAsistencia = async () => {

    if(usuarioActivo == null || usuarioActivo == '')
    {
        navigate("/Login")
        return;
    }


    await fetch('https://conectate-peru.com/api/ApiRegistroAsistenciaEvento.php', {
         method: 'POST',
         body: JSON.stringify({
            pidusuario: idusuario,
            pidevento: eventoActual,
         }),
         headers: {
            'Content-type': 'application/json; charset=UTF-8',
         },
      })
         .then((response) => response.json())
         .then((data) => {
            //setPosts((posts) => [data, ...posts]);
  
            const respuesta = JSON.parse(data.idAsistencia);
           
            if(respuesta == 0)
            {
               alert("Usted ya se encuentra registrado en este evento. Intente con otro evento.");   
            }else if (respuesta != 0){
              
                if(tienecosto == 1)
                {
                    navigate("/EventoDetallePago")
                }
                else{
                    setbinscrito(true);
                }
              //navigate('/HomeUser');
              //window.location.reload(true);
            }
  
       
         })
         .catch((err) => {
            console.log(err.message);
         });
}


useEffect(() => {
    window.scrollTo(0, 0);
    getEvento()
    getCantidadAsistentes()
    generarLetrasAleatorias()
    },[])

    const generarLetrasAleatorias = () => {
        const letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const letrasArray = letras.split('');
        const letrasAleatoriasArray = [];
    
        for (let i = 0; i < 3; i++) {
          const indiceAleatorio = Math.floor(Math.random() * letrasArray.length);
          letrasAleatoriasArray.push(letrasArray[indiceAleatorio]);
        }
    
        setLetrasAleatorias(letrasAleatoriasArray);
      };

    const handleClick1  = (e) => 
    {

        if(counter >= 0)
        {
        setCounter(counter + 4);

        //fetchApi(counter + 4);
        }
        
        //console.log(counter);
    
    }

    const handleClick2  = (e) => 
    {

        if(counter > 0)
        {
        setCounter(counter - 4);
        
        //fetchApi(counter - 4);
        }
        //console.log(counter);
        
    }

    const handleRedirectHome = () =>{

        setbinscrito(false);
        navigate(-1);
    
      }

      const handleGoPaso2 = () =>{

        navigate("/CreandoGrupo2");
    
      }

return(<>

    <Layout>
    <Container style={{paddingTop: 'calc(5em + 5vw)',paddingLeft:'15px'}}>
  <Row>
    <Col sm={1} xs={1}>
      <img src={imgBack} style={{float: 'left', cursor: 'pointer'}} onClick={handleRedirectHome}></img>
    </Col>
    <Col sm={2} xs={6} style={{textAlign:'left'}}>
      <p style={{fontSize: '12pt', color: '#FF2D52'}}>Volver</p>
    </Col>
    <Col sm={9} xs={5}></Col>
  </Row>

  {bInscrito && (
    <Container>
      <Row>
        <Col xs={3}></Col>
        <Col xs={6}>
          <center>
            <p>
              <b style={{fontSize: '20pt'}}>¡Felicidades ya estás inscrito a este evento!</b>
            </p>
          </center>
        </Col>
        <Col xs={3}></Col>
      </Row>
    </Container>
  )}

  <Row>
    <Col xs={12} sm={6}>
      <Container style={{padding: '0 0 0 0'}}>
        <Row>
          <Col xs={12}>
            <img
              style={{width: '100%', borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}
              src={ImagenPortada}
              alt="Event"
            ></img>
          </Col>
          <Col xs={12}>
            <Container style={{padding: '0 0 0 0'}}>
              <Row>
                <Col xs={12} style={{paddingTop: '20px'}}>
                  <p style={{fontSize: '18pt'}}>
                    <b>{Titulo}</b>
                  </p>
                </Col>
                <Col xs={12}>
                  <Container style={{padding: '0 0 0 0'}}>
                    <Row>
                      <Col xs={1}>
                        <div class="circle blue">
                          <center>
                            <p style={{padding: '5px 0 0 0'}}>
                              {((usuarioActivo == null || usuarioActivo == '' || usuarioActivo == 'undefined') ? (
                                <>U</>
                              ) : (
                                usuarioActivo.substring(0, 1).toUpperCase()
                              ))}
                            </p>
                          </center>
                        </div>
                      </Col>
                      <Col xs={11}>
                        <p style={{fontSize: '12pt', padding: '0 0 0 0', float: 'left'}}>
                          Creado por: <b>{usuarioActivo}</b>
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                {!bInscrito && (
                  <>
                    <Col xs={12} style={{paddingTop: '10px'}}>
                      <p style={{fontSize: '16pt'}}>
                        <b>Descubre esta experiencia</b>
                      </p>
                    </Col>
                    <Col xs={12}>
                      <p style={{fontSize: '16pt'}}>{Descripcion}</p>
                    </Col>
                  </>
                )}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Col>

    <Col xs={12} sm={6}>
      <Container>
        <Row>
          <Col xs={12} style={{borderBottom: '1px solid #D8D8D8', paddingTop: '15px'}}>
            <p style={{fontSize: '10pt'}}>Fecha: <b>{fecharegistro} {HoraInicio}</b></p>
          </Col>
          <Col xs={12} style={{borderBottom: '1px solid #D8D8D8', paddingTop: '15px'}}>
            <p style={{fontSize: '10pt'}}>Dirección: <b>{Direccion}</b></p>
          </Col>
          <Col xs={12}>
            <Container style={{borderBottom: '1px solid #D8D8D8', paddingTop: '15px'}}>
              <Row>
                <Col xs={6}>
                  <p style={{fontSize: '9pt'}}>
                    <img src={imgShare} alt="Share Icon"></img> Compartir
                  </p>
                </Col>
                <Col xs={6}>
                  <p style={{fontSize: '9pt'}}>
                    <img src={imgDisable} alt="Chat Icon"></img> Chat
                  </p>
                </Col>
              </Row>
            </Container>
          </Col>
          {!bInscrito && (
            <>
              <Col xs={12}>
                <Container>
                  <Row style={{borderBottom: '1px solid #D8D8D8', paddingTop: '15px'}}>
                    <Col xs={6}>
                      {letrasAleatorias.map((letra, index) => (
                        (index == 0) ? (
                          <div class="circle red" key={index}>
                            <center>
                              <p style={{padding: '5px 0 0 0'}}>{letra}</p>
                            </center>
                          </div>
                        ) : (
                          (index == 1) ? (
                            <div class="circle green" key={index}>
                              <center>
                                <p style={{padding: '5px 0 0 0'}}>{letra}</p>
                              </center>
                            </div>
                          ) : (
                            <div class="circle blue" key={index}>
                              <center>
                                <p style={{padding: '5px 0 0 0'}}>{letra}</p>
                              </center>
                            </div>
                          )
                        )
                      ))}
                    </Col>
                    <Col xs={6}>
                      <p>{Participantes} asistentes</p>
                    </Col>
                  </Row>
                </Container>
              </Col>

              <Col xs={12} style={{paddingTop: '30px'}}>
                {tienecosto == 1 ? (
                  <>
                    <p>
                      <b style={{fontSize: '26pt'}}>{MonedaPrecio} {precio}</b>
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      <b style={{fontSize: '26pt'}}>Gratis</b>
                    </p>
                  </>
                )}
              </Col>
              <Col xs={12}>
                <Container style={{padding: '0 0 0 0'}}>
                  <Row>
                    <Col xs={8}>
                      <p style={{fontSize: '10pt', paddingTop: '5px'}}>¿Cuántos van contigo?:</p>
                    </Col>
                    <Col xs={4}>
                      <div style={{borderRadius: '20px', border: '1px solid #412F8C', height: '30px'}}>
                        <center>
                          <p style={{fontSize: '12pt'}}>{cantidadAsistentes}</p>
                        </center>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col xs={12}>
                <Container style={{padding: '0 0 0 0'}}>
                  <Row>
                    <Col xs={7}>
                      <Button variant='primary3' type='button' onClick={registroAsistencia}>
                        <b style={{fontSize: '12pt'}}>Asistir</b>
                      </Button>
                    </Col>
                    <Col xs={5}></Col>
                  </Row>
                </Container>
              </Col>
              <Col xs={12} style={{paddingTop: '20px'}}>
                <p>
                  <b style={{fontSize: '16pt'}}>Ubicación</b>
                </p>
              </Col>
              <Col xs={12}>
                <MapaDetalleAnuncio ImagenPortada={ImagenPortada}></MapaDetalleAnuncio>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </Col>
  </Row>

  <Row style={{paddingTop: (bInscrito ? '50px' : '0')}}>
    
    <Col sm={12}>
      <Container>
        <Row style={{marginTop:'40px'}}>
          <Col sm={12}>
            <p style={{textAlign:'center'}}>
              <b style={{fontSize: '18pt', color: '#484848',}}>Eventos similares</b>
            </p>
          </Col>
          <Col sm={12}>
            <TopEventos2 isHome={false}></TopEventos2>
          </Col>
        </Row>
      </Container>
    </Col>
  </Row>
</Container>

    </Layout>



</>)


}

export default EventoDetalle;