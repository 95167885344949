import React from 'react';
import CrearCupon from '../CrearCupon';
import { useLocalStorage } from '../useLocalStorage';
import { useEffect } from 'react';
import { Link,useNavigate,Navigate } from "react-router-dom";


function HeaderAdm()
{
    const [idusuario, setIdusuario] = useLocalStorage('idusuario','')
    const [idtipousuario, setIdtipousuario] = useLocalStorage('idtipousuario','')
    const [destipousuario, setDestipousuario] = useLocalStorage('destipousuario','')
    


      useEffect(() => {
        //fetchApi();
      },[])

      const navigate = useNavigate();

        const handleRedirect = () => {
      
          if(window.confirm('¿Desea cerrar sesión?'))
          {
              sessionStorage.clear();
              localStorage.clear();
              navigate('/');
              window.location.reload(true);
          }
                 
      }

      const handleRedirectCrearCupones = () => {

        const UsuActivo = sessionStorage.getItem("UsuarioActivo");

        if(UsuActivo != null)
        {
            navigate('/CrearCupon');
        }else{
            alert("Debe iniciar sesión para crear cupones.")
            navigate('/');
        }
      
      }

    return (

        <header
        id="headerprincipal"
        style={{
            width: '100%',
            padding: '1.5rem', // Cambié a unidades rem (responsive)
            boxSizing: 'border-box',
            border: '1px solid black',
            background: 'black',
            position: 'fixed',
            zIndex: '1',
            display: 'flex', // Hacer que los elementos internos se distribuyan horizontalmente
            justifyContent: 'space-between', // Alineación de los elementos internos
            alignItems: 'center', // Alineación vertical de los elementos internos
        }}
        >
           <nav>
            <ul>
                <li><a >Inicio</a></li>
                <li><a onClick={handleRedirectCrearCupones}>Crear Cupones</a></li>
                <li><a onClick={handleRedirect}>Cerrar Sesión</a></li>
            </ul>
            </nav>
        </header>
   
    )


}


export default HeaderAdm;