import React from "react"
import HeaderAdm from "./HeaderAdm"
import Footer from "./Footer"

class LayoutAdm extends React.Component {
  render(){
    return (
      <>
        <HeaderAdm></HeaderAdm>
        <main>{this.props.children}</main>
        <Footer></Footer>
        
      </>
    )
  }
}
export default LayoutAdm;