import React, { useState,useEffect } from 'react';
import Layout from "./Components/Layout";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import imgLogo from '../src/img/Login/logo.svg';
import imgEscaladaNieve from '../src/img/Login/escalada_nieve.jpg';
import { Link,useNavigate,useLocation } from "react-router-dom";
import { useLocalStorage } from './useLocalStorage';
import {FaCheckCircle, FaTrash,BiFilterAlt,FaEyeSlash,FaEye } from 'react-icons/fa'; // npm i react-icons
import axios from 'axios'; // npm i axios
import Modal from 'react-bootstrap/Modal';

function CambioContrasenia()
{
    const [show, setShow] = useState(false);
    const [bEyes, setEyes] = useState(false);
    const [show2, setShow2] = useState(false);
    const [correo, setCorreo] = useState('');
    const [contraseña, setContraseña] = useState('');
    const [contraseñaConfirm, setContraseñaConfirm] = useState('');
    const [idusuario, setIdusuario] = useLocalStorage('idusuario','')
    const [idtipousuario, setIdtipousuario] = useLocalStorage('idtipousuario','')
    
    const handleClose2 = () => setShow2(false);
    const navigate = useNavigate();


    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
  
    const cambiocontraseniaf = async (correo,contraseña, contraseñaConfirm) => {
  
      if(contraseña !== contraseñaConfirm )
      {
          alert('Verificar contraseñas, deben se coincidir.')
          return;
      }
      
      const objeto = JSON.parse(correo);

      const datos = objeto.datos;

      // Obtener el valor de la propiedad 'iv'
      const iv = objeto.iv;

      //console.log(datos);
      //console.log(contraseñaConfirm);

      await fetch('https://conectate-peru.com/api/ApiSendMailActualizarContrasenia.php', {
         method: 'POST',
         body: JSON.stringify({
            correohash: datos,
            iv:iv,
            clave: contraseñaConfirm,
         }),
         headers: {
            'Content-type': 'application/json; charset=UTF-8',
         },
      })
         .then((response) => response.json())
         .then((data) => {
            //setPosts((posts) => [data, ...posts]);
  
            const respuesta = JSON.parse(data);
            console.log(respuesta);
  
            if(respuesta == 0 || correo == '' || contraseñaConfirm == '')
            {
               alert("Hubo un problema al actualizar su contraseña, Intente solicitando nuevamente una actualización nueva.");   
            }else if (respuesta != 0){
  
              //sessionStorage.setItem("UsuarioActivo",JSON.stringify(correo));
              //setIdusuario(respuesta);
  
              setContraseña('');
              setContraseñaConfirm('');
              alert('La contraseña fue actualizada correctamente');

              navigate('/');
              
              //window.location.reload(true);
            }
  
       
         })
         .catch((err) => {
            console.log(err.message);
         });
   };

  
    const handleSubmit2 = (e) => 
    {
    e.preventDefault();
    cambiocontraseniaf(correo,contraseña,contraseñaConfirm);
    };


   const handleRedirectRegistro = () =>
   {
    navigate('/Registro');
   }

   useEffect(() => 
   {
    if(idusuario != '')
    {
      ObtenerInfoUsuario(idusuario);
    }
   },[idusuario])

   useEffect(() =>
   {
     if(correo == '')
     {
       const parametro1 = searchParams.get('datos');
       setCorreo(parametro1)
     }
   },[correo])

  useEffect(() => {
    // Obtener la fecha actual
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    
    // Obtener la marca de tiempo almacenada en localStorage
    const lastOpenedDate = localStorage.getItem('lastOpenedDate');
    
    // Si no hay una marca de tiempo almacenada o si la marca de tiempo almacenada no es del mismo día que hoy, entonces es la primera vez que se abre en el día
    if (!lastOpenedDate || (new Date(lastOpenedDate).getDate() !== currentDay || new Date(lastOpenedDate).getMonth() !== currentMonth || new Date(lastOpenedDate).getFullYear() !== currentYear)) {
      // Aquí puedes realizar cualquier acción que necesites realizar cuando la página se abra por primera vez en el día
      console.log('La página se abrió por primera vez hoy.');
      
      if(!lastOpenedDate)
      {
        sessionStorage.clear();
        localStorage.clear();
      }

      // Actualizar la marca de tiempo en localStorage
      localStorage.setItem('lastOpenedDate', currentDate);
    }else{
        sessionStorage.clear();
        localStorage.clear();
    }
  }, []);



  const ObtenerInfoUsuario = async (pidusuario) =>
  {
        let fd = new FormData() 
        fd.append("pidusuario",pidusuario)
     
        
        const res3 = await axios.post('https://conectate-peru.com/api/ApiObtenerUsuariof.php', fd);
        
        
        res3.data.items.map((item, index) => {
          
          setIdtipousuario(item.idTipo_Usuario);

          if(item.idTipo_Usuario == "3") // Solo administrador
          {
            navigate('/CrearCupon');
          }else
          {
            navigate('/HomeUser');
          }
 
        })
  }



return(
  <div className="split-container">
  <div className="split-left d-none d-sm-block">
    <Container style={{ padding: '0' }}>
      <Row>
        <Col sm={12}>
          <img
            src={imgEscaladaNieve}
            style={{ width: '100%', height: '100%', objectFit: 'cover', alignItems: 'center', justifyContent: 'center' }}
            alt="Escalada en Nieve"
          />
        </Col>
      </Row>
    </Container>
  </div>
  <div className="split-right">
    <Container style={{ paddingTop: 'calc(5em + 5vw)' }}>
      <Row>
        <Col sm={12}>
          <center>
            <img src={imgLogo} alt="Logo"></img>
          </center>
        </Col>
      </Row>
      <Row style={{ paddingTop: '40px' }}>
        <Col sm={12}>
          <center>
            <p style={{ fontSize: '12pt' }}><b>Cambio de Contraseña</b></p>
          </center>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} sm={10} md={8} lg={6}>
          <Form onSubmit={handleSubmit2}>
            <Container style={{ padding: '0' }}>
              <Row>
                <Col xs={12}>
                <Form.Group controlId="exampleForm2.ControlInput2">
                    <Form.Label style={{ fontSize: '10pt' }}><b>Contraseña</b></Form.Label>
                    <Form.Control
                      type="password"
                      value={contraseña}
                      onChange={(e) => setContraseña(e.target.value)}
                      style={{ fontSize: '9pt', color: 'black' }}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="exampleForm2.ControlInput2">
                    <Form.Label style={{ fontSize: '10pt' }}><b>Confirmar Contraseña</b></Form.Label>
                    <Form.Control
                      type="password"
                      value={contraseñaConfirm}
                      onChange={(e) => setContraseñaConfirm(e.target.value)}
                      style={{ fontSize: '9pt', color: 'black' }}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                
                </Col>
              </Row>
            </Container>
            <br />
            <Container>
              <Row>
                <Col xs={12}>
                  <center>
                    <Button variant="primary3" type='submit'>
                      <b style={{ fontSize: '12pt' }}>Aceptar</b>
                    </Button>
                  </center>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={12}>
                  
                </Col>
              </Row>
            </Container>
          </Form>
        </Col>
      </Row>
    </Container>
  </div>
</div>
);

}

export default CambioContrasenia;