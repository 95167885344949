import React, { useState,useEffect,useRef } from 'react';
import Layout from "./Components/Layout";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link,useNavigate } from "react-router-dom";
import { useLocalStorage } from './useLocalStorage';
import Button from 'react-bootstrap/Button';
import imgBack from '../src/img/CrearGrupo/arrow_left.svg';
import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import imgChecked from '../src/img/CrearGrupo/checked.svg';
import imgClose from '../src/img/CrearGrupo/close.svg';


function CreandoGrupo4(){

const navigate = useNavigate();
let itop = 0;

const [items2, setItems2] = useState();
const [counter, setCounter] = useState(0);
const [isLoading, setIsLoading] = useState(false);
const [isVisible, setIsVisble] = useState('');
const [caractetisticas, setcaractetisticas] = useState();
const [vcaracteristica, setvcaracteristica] = useLocalStorage('vcaracteristica',[]);
const [vWidth, setWidth] = useState('');  
const [vHeight, setHeight] = useState('');
const [imagenSeleccionadaPerfilGrupo, setimagenSeleccionadaPerfilGrupo] = useLocalStorage('imagenSeleccionadaPerfilGrupo','');
const [imagenSeleccionadaPerfilGrupoADM, setimagenSeleccionadaPerfilGrupoADM] = useLocalStorage('imagenSeleccionadaPerfilGrupoADM','');
const [imagen, setImagen] = useLocalStorage('imagen',[]);
const [imagen2, setImagen2] = useLocalStorage('imagen2',[]);
const [vnombregrupo, setvnombregrupo] = useLocalStorage('vnombregrupo','');
const [parametro1, setparametro1] = useLocalStorage('parametro1','');
const [parametro2, setparametro2] = useLocalStorage('parametro2','');
const [parametro3, setparametro3] = useLocalStorage('parametro3','');
const [parametro4, setparametro4] = useLocalStorage('parametro4',false);
const [vplan, setvplan] = useLocalStorage('vplan','')
//const express = require("express");
//const app = express();
//const cors = require('cors');

//app.use(cors());

const getCaracteristicas = async () => {
    setIsLoading(true);
    const url2 = 'https://conectate-peru.com/api/ApiListarMaestroCbo.php';
  
    const data = JSON.stringify({
        tipo : 3,
       })
  
    const response = await fetch(url2, {
        method: 'POST',
        body: data,
        headers: {
           'Content-type': 'application/json; charset=UTF-8',
        },
     })
    const responseJSON = await response.json()
    setcaractetisticas(responseJSON.items)   
    setIsLoading(false);
  
  }

  const planes = [['Eventos y asistentes ilimitados',0,1],
                  ['Promoción de grupos y eventos a miembros interesados',1,1],
                  ['Organización simple de eventos en línea y en persona',1,1],
                  ['Asignar co organizadores ilimitados',0,1],
                  ['Tarifas de entrada y cuotas de grupo',0,1],
                  ['Plataforma para administrar una red de grupos',1,1],
                  ['Acceso a los correos electrónicos de los asistentes',1,1],
                  ['Ayuda especializada de expertos de la comunidad',0,1]]





useEffect(() => {
    //getCaracteristicas()
    //getAuthNiubiz()
    },[])

    function getAuthNiubiz(){
        
        

    }

    const handleClick1  = (e) => 
    {

        if(counter >= 0)
        {
        setCounter(counter + 4);

        //fetchApi(counter + 4);
        }
        
        //console.log(counter);
    
    }

    const handleClick2  = (e) => 
    {

        if(counter > 0)
        {
        setCounter(counter - 4);
        
        //fetchApi(counter - 4);
        }
        //console.log(counter);
        
    }

    const handleRedirectHome = () =>{

        navigate(-1);
    
      }

    const handleGrabarProgreso = () =>{
        
    }

    
   function formatCadena(cadena)
   {
      let newValor = cadena.replace(/[^A-Za-z ñ]/,"");
      //console.log(newValor);
  
      return newValor;
   }

    function btncaracteristicas(index,valor)
    {

    var x = document.getElementById("btncaract_"+index);

    if (x.className === "btncaracteristicas") 
    {
        x.className += " activo";
        setvcaracteristica(vcaracteristica => [...vcaracteristica,{id:valor}])
    } else {
        x.className = "btncaracteristicas";
        setvcaracteristica(vcaracteristica.filter(item => parseInt(item.id) !== parseInt(valor)));
    }

    
    }

    /*Funciones de imagenes*/

    function niceBytes(a)
    {
      var marker = 1024; // Change to 1000 if required
      var decimal = 3; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1
      return(a / megaBytes).toFixed(decimal);
    }




  const handleClickPlan = (tipo) => {
    setvplan(tipo);
    navigate("/Detalle");
  };

return(<>

    <Layout>
        <Container style={{paddingTop:'calc(5em + 5vw)',paddingLeft:'15px'}}>
            <Row>
                <Col sm={3}>
                    <img src={imgBack} style={{float:'right',cursor:'pointer'}} onClick={handleRedirectHome}></img>
                </Col>
                <Col sm={6}>
                <p style={{fontSize:'12pt',opacity:'0.5'}}>Casi terminamos</p>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                   
                </Col>
                <Col sm={6}>
                <Container style={{padding:'0 0 0 0'}}>
                    <Row>
                        <Col sm={6} >
                        <p style={{fontSize:'16pt'}}><b style={{color:'#412F8C'}}>Elige el Plan que más se acomode a tí</b></p>
                        </Col>
                        <Col sm={3} className='planesResponsive'>
                            <Container style={{backgroundColor:'#FAFAFA'}}>
                                <Row style={{paddingTop:'30px',borderTop:'6px solid #92BB00'}}>
                                    <Col xs={12}>
                                        <center><p><b>Conéctate Plus</b></p></center> 
                                    </Col>
                                    <Col xs={12}>
                                       <center><p ><b style={{color:'#92BB00'}}>S/ 48.00 x mes</b></p></center> 
                                    </Col>
                                    <Col xs={12} style={{width:'100%'}}>
                                        <center><p ><b style={{fontSize:'7pt'}}>Gratis el primer mes con este plan.</b></p></center> 
                                    </Col>
                                    <Col xs={12}>
                                        <p style={{width:'100%'}}></p>
                                    </Col>
           
                                    <Col xs={12}>
                                    <Button variant='primary7' onClick={(e) => {handleClickPlan(1)}}>
                                    <b>Lo quiero</b>
                                    
                                    </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col sm={3} className='planesResponsive'>
                        <Container style={{backgroundColor:'#FAFAFA'}}>
                                <Row style={{paddingTop:'30px',borderTop:'6px solid #FF2D52'}}>
                                    <Col xs={12}>
                                        <center><p><b>Conéctate Experience</b></p></center>
                                    </Col>
                                    <Col xs={12}>
                                        <center><p ><b style={{color:'#FF2D52'}}>S/ 400.00 x mes</b></p></center>
                                    </Col>
                                    <Col xs={12}>
                                        <center><p><b style={{fontSize:'7pt'}}>Por todo un año. Ahorra S/.176.00 con este plan</b></p></center>
                                    </Col>
                                    <Col xs={12}>
                                        <p style={{width:'100%'}}></p>
                                    </Col>
                                    <Col xs={12}>
                                    <Button variant='primary7' onClick={(e) => {handleClickPlan(2)}}>
                                    <b>Lo quiero</b>
                                    </Button>
                                    </Col>
                                </Row>
                        </Container>
                        </Col>
                    </Row>
                </Container>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                   
                </Col>
                <Col sm={6}>
                <Container style={{padding:'0 0 0 0'}}>
                    <Row >
                        <Col style={{paddingTop:'30px'}} sm={6} className='d-none d-sm-block'>
                        <p style={{fontSize:'9pt'}}>Creación de grupos</p>
                        </Col>
                        <Col sm={3} className='d-none d-sm-block'>
                            <Container style={{backgroundColor:'#FAFAFA'}}>
                                <Row style={{paddingTop:'30px'}}>
                                    <Col xs={12}>
                                        <center><p style={{fontSize:'9pt'}}>1 Grupo</p></center> 
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col sm={3} className='d-none d-sm-block'>
                        <Container style={{backgroundColor:'#FAFAFA'}}>
                                <Row style={{paddingTop:'30px'}}>
                                    <Col xs={12}>
                                        <center><p style={{fontSize:'9pt'}}>Ilimitados</p></center>
                                    </Col>
                                </Row>
                    </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} className='d-none d-sm-block'>
                        <p style={{fontSize:'9pt'}}>Eventos y asistentes ilimitados </p>
                        </Col>
                        <Col sm={3} className='d-none d-sm-block'>
                            <Container style={{backgroundColor:'#FAFAFA',height:'40px'}}>
                                <Row >
                                    <Col xs={12}>
                                        <center><img src={imgClose}></img></center> 
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col sm={3} className='d-none d-sm-block'>
                        <Container style={{backgroundColor:'#FAFAFA',height:'40px'}}>
                                <Row>
                                    <Col xs={12}>
                                        <center><img src={imgChecked}></img></center>
                                    </Col>
                                </Row>
                        </Container>
                        </Col>
                        </Row>
                        <Row>
                        <Col sm={6} className='d-none d-sm-block'>
                        <p style={{fontSize:'9pt'}}>Promoción de grupos y eventos a miembros interesados</p>
                        </Col>
                        <Col sm={3} className='d-none d-sm-block'>
                            <Container style={{backgroundColor:'#FAFAFA',height:'60px'}}>
                                <Row>
                                    <Col xs={12}>
                                        <center><img src={imgChecked}></img></center> 
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col sm={3} className='d-none d-sm-block'>
                        <Container style={{backgroundColor:'#FAFAFA',height:'60px'}}>
                                <Row>
                                    <Col xs={12}>
                                        <center><img src={imgChecked}></img></center>
                                    </Col>
                                </Row>
                        </Container>
                        </Col>
                        </Row>
                        <Row>
                        <Col sm={6} className='d-none d-sm-block'>
                        <p style={{fontSize:'9pt'}}>Organización simple de eventos en línea y en persona</p>
                        </Col>
                        <Col sm={3} className='d-none d-sm-block'>
                            <Container style={{backgroundColor:'#FAFAFA',height:'60px'}}>
                                <Row>
                                    <Col xs={12}>
                                        <center><img src={imgChecked}></img></center> 
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col sm={3} className='d-none d-sm-block'>
                       <Container style={{backgroundColor:'#FAFAFA',height:'60px'}}>
                                <Row >
                                    <Col xs={12}>
                                        <center><img src={imgChecked}></img></center>
                                    </Col>
                                </Row>
                        </Container>
                        </Col>
                        </Row>
                        <Row>
                        <Col sm={6} className='d-none d-sm-block'>
                        <p style={{fontSize:'9pt'}}>Asignar co organizadores ilimitados</p>
                        </Col>
                        <Col sm={3} className='d-none d-sm-block'>
                            <Container style={{backgroundColor:'#FAFAFA',height:'40px'}}>
                                <Row >
                                    <Col xs={12}>
                                        <center><img src={imgClose}></img></center> 
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col sm={3} className='d-none d-sm-block'>
                       <Container style={{backgroundColor:'#FAFAFA',height:'40px'}}>
                                <Row >
                                    <Col xs={12}>
                                        <center><img src={imgChecked}></img></center>
                                    </Col>
                                </Row>
                        </Container>
                        </Col>
                        </Row>
                        <Row>
                        <Col sm={6} className='d-none d-sm-block'>
                        <p style={{fontSize:'9pt'}}>Tarifas de entrada y cuotas de grupo</p>
                        </Col>
                        <Col sm={3} className='d-none d-sm-block'>
                            <Container style={{backgroundColor:'#FAFAFA',height:'40px'}}>
                                <Row >
                                    <Col xs={12}>
                                        <center><img src={imgClose}></img></center> 
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col sm={3} className='d-none d-sm-block'>
                        <Container style={{backgroundColor:'#FAFAFA',height:'40px'}}>
                                <Row>
                                    <Col xs={12}>
                                        <center><img src={imgChecked}></img></center>
                                    </Col>
                                </Row>
                        </Container>
                        </Col>
                        </Row>
                        <Row>
                        <Col sm={6} className='d-none d-sm-block'>
                        <p style={{fontSize:'9pt'}}>Plataforma para administrar una red de grupos</p>
                        </Col>
                        <Col sm={3} className='d-none d-sm-block'>
                            <Container style={{backgroundColor:'#FAFAFA',height:'40px'}}>
                                <Row>
                                    <Col xs={12}>
                                        <center><img src={imgChecked}></img></center> 
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col sm={3} className='d-none d-sm-block'>
                       <Container style={{backgroundColor:'#FAFAFA',height:'40px'}}>
                                <Row >
                                    <Col xs={12}>
                                        <center><img src={imgChecked}></img></center>
                                    </Col>
                                </Row>
                        </Container>
                        </Col>
                        </Row>
                        <Row>
                        <Col sm={6} className='d-none d-sm-block'>
                        <p style={{fontSize:'9pt'}}>Acceso a los correos electrónicos de los asistentes</p>
                        </Col>
                        <Col sm={3} className='d-none d-sm-block'>
                            <Container style={{backgroundColor:'#FAFAFA',height:'40px'}}>
                                <Row>
                                    <Col xs={12}>
                                        <center><img src={imgChecked}></img></center> 
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col sm={3} className='d-none d-sm-block'>
                        <Container style={{backgroundColor:'#FAFAFA',height:'40px'}}>
                                <Row >
                                    <Col xs={12}>
                                        <center><img src={imgChecked}></img></center>
                                    </Col>
                                </Row>
                        </Container>
                        </Col>
                        </Row>
                        <Row>
                        <Col sm={6} className='d-none d-sm-block'>
                        <p style={{fontSize:'9pt'}}>Ayuda especializada de expertos de la comunidad</p>
                        </Col>
                        <Col sm={3} className='d-none d-sm-block'>
                            <Container style={{backgroundColor:'#FAFAFA',height:'40px'}}>
                                <Row>
                                    <Col xs={12}>
                                        <center><img src={imgClose}></img></center> 
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col sm={3} className='d-none d-sm-block'>
                        <Container style={{backgroundColor:'#FAFAFA',height:'40px'}}>
                                <Row >
                                    <Col xs={12}>
                                        <center><img src={imgChecked}></img></center>
                                    </Col>
                                </Row>
                        </Container>
                        </Col>
                        </Row>
                        <Row style={{paddingTop:'10px'}}>
                        <Col sm={12} className='d-none d-sm-block'>
                        <p style={{fontSize:'9pt',opacity:'0.5',color:'#23A59C',textAlign:'justify'}}>*Recuerda, si colocas frases relacionados a temas sexuales e ilícitos bloquearemos tu grupo sin opción a devolución del costo de la membresia.</p>
                        </Col>
                        
                        
                        </Row>
                    
                </Container>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
              
           
        </Container>
        <Container>
            <Row>
                <Col>
                <div class="kr-embedded" kr-form-token="[GENERATED FORMTOKEN]"></div>
                </Col>
            </Row>
        </Container>
    </Layout>



</>);


}

export default CreandoGrupo4;