import React, { useState,useEffect,useRef } from 'react';
import Layout from "./Components/Layout";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link,useNavigate } from "react-router-dom";
import { useLocalStorage } from './useLocalStorage';
import Button from 'react-bootstrap/Button';
import imgLupa from '../src/img/Home/lupa.svg';
import imgSend from '../src/img/Home/send.svg';
import Calendar from './Components/Calendar';
import imgBack from '../src/img/CrearGrupo/arrow_left.svg';
import imgGym from '../src/img/CrearGrupo/gym.png';
import imgBicii from '../src/img/CrearGrupo/bicii.png';
import imgProfesioal from '../src/img/CrearGrupo/profesional.png';
import SubirFoto from './Components/SubirFoto';
import {FaCamera} from 'react-icons/fa'; // npm i react-icons
import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import LoadingSpinner from "../src/Components/LoadingSpinner";
import EventosBusqueda from './Components/EventosBusqueda';
import CanvasFoto from './Components/CanvasFoto';
import CanvasFoto2 from './Components/CanvasFoto2';



function CreandoGrupo3(){

const navigate = useNavigate();
let itop = 0;

const [items2, setItems2] = useState();
const [counter, setCounter] = useState(0);
const [isLoading, setIsLoading] = useState(false);
const [isVisible, setIsVisble] = useState('');
const [caractetisticas, setcaractetisticas] = useState();
const [vcaracteristica, setvcaracteristica] = useLocalStorage('vcaracteristica',[]);
const [vWidth, setWidth] = useState('');  
const [vHeight, setHeight] = useState('');
const [imagenSeleccionadaPerfilGrupo, setimagenSeleccionadaPerfilGrupo] = useLocalStorage('imagenSeleccionadaPerfilGrupo','');
const [imagenSeleccionadaPerfilGrupoADM, setimagenSeleccionadaPerfilGrupoADM] = useLocalStorage('imagenSeleccionadaPerfilGrupoADM','');
const [imagen, setImagen] = useLocalStorage('imagen',[]);
const [imagen2, setImagen2] = useLocalStorage('imagen2',[]);
const [vnombregrupo, setvnombregrupo] = useLocalStorage('vnombregrupo','');
const [parametro1, setparametro1] = useLocalStorage('parametro1','');
const [parametro2, setparametro2] = useLocalStorage('parametro2','');
const [parametro3, setparametro3] = useLocalStorage('parametro3','');
const [parametro4, setparametro4] = useLocalStorage('parametro4',false);
const [codigogrupoactual, setcodigogrupoactual] = useLocalStorage('codigogrupoactual','');

const getCaracteristicas = async () => {
    setIsLoading(true);
    const url2 = 'https://conectate-peru.com/api/ApiListarMaestroCbo.php';
  
    const data = JSON.stringify({
        tipo : 3,
       })
  
    const response = await fetch(url2, {
        method: 'POST',
        body: data,
        headers: {
           'Content-type': 'application/json; charset=UTF-8',
        },
     })
    const responseJSON = await response.json()
    setcaractetisticas(responseJSON.items)   
    setIsLoading(false);
  
  }


useEffect(() => {
    //getCaracteristicas()
    },[])

    const handleClick1  = (e) => 
    {

        if(counter >= 0)
        {
        setCounter(counter + 4);

        //fetchApi(counter + 4);
        }
        
        //console.log(counter);
    
    }

    const handleClick2  = (e) => 
    {

        if(counter > 0)
        {
        setCounter(counter - 4);
        
        //fetchApi(counter - 4);
        }
        //console.log(counter);
        
    }

    const handleRedirectHome = () =>{

        navigate(-1);
    
      }


    const handleGrabarProgreso = () =>{
        
        
        navigate("/CreandoGrupo4")

        
    }

    
   function formatCadena(cadena)
   {
      let newValor = cadena.replace(/[^A-Za-z ñ]/,"");
      //console.log(newValor);
  
      return newValor;
   }

    function btncaracteristicas(index,valor)
    {

    var x = document.getElementById("btncaract_"+index);

    if (x.className === "btncaracteristicas") 
    {
        x.className += " activo";
        setvcaracteristica(vcaracteristica => [...vcaracteristica,{id:valor}])
    } else {
        x.className = "btncaracteristicas";
        setvcaracteristica(vcaracteristica.filter(item => parseInt(item.id) !== parseInt(valor)));
    }

    
    }

    /*Funciones de imagenes*/

    function niceBytes(a)
    {
      var marker = 1024; // Change to 1000 if required
      var decimal = 3; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1
      return(a / megaBytes).toFixed(decimal);
    }
  
   async function convertImage(e)
  {
  
      const files = e.target.files;
      const filePeso = 0;
  
      for (let index = 0; index < files.length; index++) {
          const reader = new FileReader();
      let nWidth = 0;
      let nHeigth = 0;
      
      
      let src = URL.createObjectURL(e.target.files[index]);
      const divcanvas = document.getElementById('wimagen');
      //reader.readAsDataURL(e.target.files[0]);
  
      if(niceBytes(e.target.files[index].size) >= 32.0)
      {
          alert("Imagen excede el tamaño permitido. El peso debe ser menor a 32Mb.")
          return;
      }
  
      reader.readAsDataURL(e.target.files[index]);
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          const {
            height,
            width
          } = image;
  
          setHeight(height);
          setWidth(width);
          if(src.length > 1)
          {
            setImagen(imagen => [...imagen,{ id:Math.random() + index,src:src, height:height, width: width}])

          }
        };
      };  
      }
      

     

      const fileAnun = document.getElementById('fileAnuncio');
      fileAnun.value = '';

      
  }

  async function convertImage2(e)
  {
  
      const files = e.target.files;
      const filePeso = 0;
  
      for (let index = 0; index < files.length; index++) {
          const reader = new FileReader();
      let nWidth = 0;
      let nHeigth = 0;
      
      
      let src = URL.createObjectURL(e.target.files[index]);
      const divcanvas = document.getElementById('wimagen');
      //reader.readAsDataURL(e.target.files[0]);
  
      console.log(niceBytes(e.target.files[index].size));
  
      if(niceBytes(e.target.files[index].size) >= 32.0)
      {
          alert("Imagen excede el tamaño permitido. El peso debe ser menor a 32Mb.")
          return;
      }
  
      reader.readAsDataURL(e.target.files[index]);
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          const {
            height,
            width
          } = image;
  
          setHeight(height);
          setWidth(width);
          if(src.length > 1)
          {

            setImagen2(imagen2 => [...imagen2,{ id:Math.random() + index,src:src, height:height, width: width}])
            
          }
        };
      };  
      }
      
      const fileAnun = document.getElementById('fileAnuncio2');
      fileAnun.value = '';
      
  }
  

  const fileInputRef = useRef(null);
  const handleOpenFileClick = () => {
    fileInputRef.current.click();
  };

  const fileInputRef2 = useRef(null);
  const handleOpenFileClick2 = () => {
    fileInputRef2.current.click();
  };


return(<>

    <Layout>
        <Container style={{paddingTop:'calc(5em + 5vw)',paddingLeft:'15px'}}>
            <Row>
                <Col sm={3}>
                    <img src={imgBack} style={{float:'right',cursor:'pointer'}} onClick={handleRedirectHome}></img>
                </Col>
                <Col sm={6}>
                <p style={{fontSize:'12pt',opacity:'0.5'}}>Creando mi grupo</p>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                   
                </Col>
                <Col sm={6}>
                <p style={{fontSize:'18pt'}}><b style={{color:'#412F8C'}}>Cuéntanos un poco de que trata tu grupo</b></p>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>

            <Row>
            <Col sm={3}>
                </Col>
                <Col sm={6}>
                <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                <Form.Control
                    type="text"
                    value={parametro1}
                    onChange={(e) => setparametro1(e.target.value)}
                    placeholder='Ejemplo: Mi grupo fue creado para compartir arte con todo el mundo y poder expresarnos...'
                    required
                    style={{fontSize:'10pt',color:'black',borderTop:'0',borderTopRightRadius:'0',borderTopLeftRadius:'0',borderRight:'0',borderLeft:'0',borderBottomLeftRadius:'0',borderBottomRightRadius:'0',borderBottom:'2px solid black',outline:'none'}}
                />
                </Form.Group>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
            <Row style={{paddingTop:'40px'}}>
                <Col sm={3}>
                   
                </Col>
                <Col sm={6}>
                <p style={{fontSize:'12pt',color:'#412F8C'}}>Describe las normas de tu grupo</p>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
            <Row>
            <Col sm={3}>
                </Col>
                <Col sm={6}>
                <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                <Form.Control
                    type="text"
                    value={parametro2}
                    onChange={(e) => setparametro2(e.target.value)}
                    placeholder='Ejemplo: Los miembros no pueden publicar temas sobre otros temas...'
                    required
                    style={{fontSize:'10pt',color:'black',borderTop:'0',borderTopRightRadius:'0',borderTopLeftRadius:'0',borderRight:'0',borderLeft:'0',borderBottomLeftRadius:'0',borderBottomRightRadius:'0',borderBottom:'2px solid black',outline:'none'}}
                />
                </Form.Group>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
     
            <Row style={{paddingTop:'40px'}}>
                <Col sm={3}>
                   
                </Col>
                <Col sm={6}>
                <p style={{fontSize:'12pt',color:'#412F8C'}}>¿El grupo es cerrado?</p>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
            <Row>
            <Col sm={3}>
                </Col>
                <Col sm={6}>
                <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                <Form.Check
                    type="checkbox"
                    label='Si'
                    checked={parametro4}
                    onChange={(e) => setparametro4(e.target.checked)}
                    
                />
                </Form.Group>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
            <Row style={{paddingTop:'30px'}}>
                <Col sm={3}>
                   
                </Col>
                <Col sm={6}>
                <p style={{fontSize:'10pt',opacity:'0.5'}}>Pregunta para acceder al grupo</p>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
            <Row>
            <Col sm={3}>
                </Col>
                <Col sm={6}>
                <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                <Form.Control
                    type="text"
                    value={parametro3}
                    onChange={(e) => setparametro3(e.target.value)}
                    placeholder='Ejemplo: ¿Por qué te interesa ingresar a este grupo?'
                    required
                    style={{fontSize:'10pt',color:'black',borderTop:'0',borderTopRightRadius:'0',borderTopLeftRadius:'0',borderRight:'0',borderLeft:'0',borderBottomLeftRadius:'0',borderBottomRightRadius:'0',borderBottom:'2px solid black',outline:'none'}}
                />
                </Form.Group>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>

            <Row style={{paddingTop:'20px'}}>
                <Col sm={3}>
                    
                </Col>
                <Col sm={6}>
                <Container style={{padding:'0 0 0 0'}}>
                <Row>
                    <Col sm={9}>
                    </Col>
                    <Col sm={3}>
                    <Button variant='primary3' onClick={handleGrabarProgreso}>
                                    <b>Continuemos</b>
                                    </Button>
                    </Col>
                </Row>
                </Container>    
                </Col>
                <Col sm={3}>
                
                </Col>
            </Row>
            <Row style={{paddingTop:'20px'}}>
                <Col sm={3}>
                    
                </Col>
                <Col sm={6}>
                {/*<center><img src={imgProfesioal} style={{width:'150px'}}></img></center>*/}
                </Col>
                <Col sm={3}>
                
                </Col>
            </Row>


        </Container>
    </Layout>



</>);


}

export default CreandoGrupo3;