import React, { useState,useEffect } from 'react';
import Layout from "./Components/Layout";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link,useNavigate } from "react-router-dom";
import { useLocalStorage } from './useLocalStorage';
import Button from 'react-bootstrap/Button';
import imgLupa from '../src/img/Home/lupa.svg';
import imgSend from '../src/img/Home/send.svg';
import Calendar from './Components/Calendar';
import imgBack from '../src/img/CrearGrupo/arrow_left.svg';
import imgGym from '../src/img/CrearGrupo/gym.png';

import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import LoadingSpinner from "../src/Components/LoadingSpinner";
import EventosBusqueda from './Components/EventosBusqueda';


function CreandoGrupo(){

const navigate = useNavigate();
let itop = 0;

const [items2, setItems2] = useState();
const [counter, setCounter] = useState(0);
const [isLoading, setIsLoading] = useState(false);
const [isVisible, setIsVisble] = useState('');
const [caractetisticas, setcaractetisticas] = useState();
const [vcaracteristica, setvcaracteristica] = useState([]);
const [vcaracteristicaf, setvcaracteristicaf] = useLocalStorage('vcaracteristica',[{"id":"12"}]);

const getCaracteristicas = async () => {
    setIsLoading(true);
    const url2 = 'https://conectate-peru.com/api/ApiListarMaestroCbo.php';
  
    const data = JSON.stringify({
        tipo : 3,
       })
  
    const response = await fetch(url2, {
        method: 'POST',
        body: data,
        headers: {
           'Content-type': 'application/json; charset=UTF-8',
        },
     })
    const responseJSON = await response.json()
    setcaractetisticas(responseJSON.items)   
    setIsLoading(false);
  
  }


useEffect(() => {
    getCaracteristicas()
    },[])

    const handleClick1  = (e) => 
    {

        if(counter >= 0)
        {
        setCounter(counter + 4);

        //fetchApi(counter + 4);
        }
        
        //console.log(counter);
    
    }

    const handleClick2  = (e) => 
    {

        if(counter > 0)
        {
        setCounter(counter - 4);
        
        //fetchApi(counter - 4);
        }
        //console.log(counter);
        
    }

    const handleRedirectHome = () =>{

        navigate(-1);
    
      }

      const handleGoPaso2 = () =>{

        setvcaracteristicaf(vcaracteristica)

        navigate("/CreandoGrupo2");
    
      }

    function btncaracteristicas(index,valor)
    {

    var x = document.getElementById("btncaract_"+index);

    if (x.className === "btncaracteristicas") 
    {
        x.className += " activo";
        setvcaracteristica(vcaracteristica => [ ...vcaracteristica,{id:valor}])
    } else {
        x.className = "btncaracteristicas";
        setvcaracteristica(vcaracteristica.filter(item => parseInt(item.id) !== parseInt(valor)));
    }

    
    }


return(<>

    <Layout>
        <Container style={{paddingTop:'calc(5em + 5vw)',paddingLeft:'0'}}>
            <Row>
                <Col sm={3}>
                    <img src={imgBack} style={{float:'right',cursor:'pointer'}} onClick={handleRedirectHome}></img>
                </Col>
                <Col sm={6}>
                <p style={{fontSize:'12pt',opacity:'0.5'}}>Creando mi grupo</p>
                </Col>
                <Col sm={3}>
                    
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                   
                </Col>
                <Col sm={6}>
                <p style={{fontSize:'20pt'}}><b style={{color:'#412F8C'}}>¿Qué temas te interesan?</b></p>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>

            <Row>
            <Col sm={3}>
                </Col>
                <Col sm={6}>
                <div id='caracteristicas' className="popupcarac">
                    <ul className="popupcarac_ul">
                    {!caractetisticas ? <><LoadingSpinner></LoadingSpinner></>:
                    
                    caractetisticas.map((item,index) => {
                    
                    return(
                        <>
                        <li>
                        <div id={'btncaract_'+index} onClick={(e) => btncaracteristicas(index,item.Codigo)} className='btncaracteristicas'>
                            <p style={{textAlign:'center',fontSize:'9pt',paddingTop:'12px'}}>{item.Descripcion}</p>
                        </div>
                        </li>
                        </>
                    );

                    })
                    
                    }

                    </ul>
                </div>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
            <Row style={{paddingTop:'20px'}}>
                <Col sm={3}>
                    
                </Col>
                <Col sm={6}>
                    <hr></hr>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
            <Row style={{paddingTop:'20px'}}>
                <Col sm={3}>
                    
                </Col>
                <Col sm={6}>
                <Container style={{padding:'0 0 0 0'}}>
                <Row>
                    <Col sm={9}>
                    </Col>
                    <Col sm={3}>
                    <Button variant='primary3' onClick={handleGoPaso2}>
                                    <b>Continuemos</b>
                                    </Button>
                    </Col>
                </Row>
                </Container>    
                </Col>
                <Col sm={3}>
                
                </Col>
            </Row>
            <Row style={{paddingTop:'20px'}}>
                <Col sm={3}>
                    
                </Col>
                <Col sm={6}>
                <center>
                    <img src={imgGym} style={{width:'350px'}}></img>
                </center>
                </Col>
                <Col sm={3}>
                
                </Col>
            </Row>


        </Container>
    </Layout>



</>);


}

export default CreandoGrupo;