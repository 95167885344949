import React, { useState,useEffect, createElement } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useLocalStorage } from '../useLocalStorage';


var map;
const API_KEY = 'AIzaSyBPsQ2tRu5JrJU_9yFFhsh1tKEq1JyUsOE';

function MapaDetalleAnuncio({ImagenPortada}){

    const [mapaV, setMapa] =  useState();
    const [items, setItems] = useState();
    const [Latitud, setLatitud] = useLocalStorage('Latitud','');
    const [Longitud, setLongitud] = useLocalStorage('Longitud','');
    //const [ImagenPortada, setImagenPortada] = useLocalStorage('ImagenPortada','');
    const [fecharegistro, setfecharegistro] = useLocalStorage('fecharegistro','')
    const [tienecosto, settienecosto] = useLocalStorage('tienecosto','')
    const [precio, setprecio] = useLocalStorage('precio','')
    const [Direccion, setDireccion] = useLocalStorage('Direccion','');

    useEffect(() => {
        
        CargarMapa();
        cargarMarker();
      },[])
    


    function CargarMapa()
    {
        renderMap()
    }

    function renderMap()
    {
        const coords = { lat: -16.489689, lng: -68.11929359999999 };
        const el = document.getElementById('mapaDetalle');
    
        if (el) {
          map = new window.google.maps.Map(el, {
            zoom: 13,
            center: {
              lat: coords.lat,
              lng: coords.lng,
            },
          });
          setMapa(map);
          return map;
        } else {
          return null;
        }
      }

      function cargarMarker()
      {

        const LatitudActualSeleccion =  sessionStorage.getItem("LatitudActualSeleccion");
        const fLatitudActualSeleccion = JSON.parse(LatitudActualSeleccion);
        const LongitudActualSeleccion =  sessionStorage.getItem("LongitudActualSeleccion");
        const fLongitudActualSeleccion = JSON.parse(LongitudActualSeleccion);
        const ImagenP = sessionStorage.getItem("ImagenPortadaActualSeleccion");
        const ImagenPf = JSON.parse(ImagenP);


        let marker,i;

        let infowindow = new window.google.maps.InfoWindow();                     

        marker = new window.google.maps.Marker({
                    map: map,
                    position:  new window.google.maps.LatLng(fLatitudActualSeleccion, fLongitudActualSeleccion),
                    icon: "https://speedaixdev.com/img/MerkasaIcon.svg"
                });

        map.setCenter(marker.getPosition());
        
        infowindow.setContent("<center><img src='"+ImagenPf+"' style='height:50px;width:50px;border-radius:10px'></img><div style='width:70px;font-size:8pt;text-align: center'>"+Direccion+"</div></center>");

        infowindow.open(map, marker);

        window.google.maps.event.addListener(marker, 'mouseover', (function(marker, i) {
            return function() {

                infowindow.setContent("<img src='"+ImagenPf+"' style='height:50px;width:50px;border-radius:10px'></img><div style='width:70px'>"+Direccion+"</div>");

            infowindow.open(map, marker);
            }
        })(marker, i));
      }

      return (<>

            <div id='mapaDetalle'>
            </div>    

      </>)

}

export default MapaDetalleAnuncio;