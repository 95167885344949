import React, { useState,useEffect,useRef } from 'react';
import Layout from "./Components/Layout";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link,useNavigate } from "react-router-dom";
import { useLocalStorage } from './useLocalStorage';
import Button from 'react-bootstrap/Button';
import imgLupa from '../src/img/Home/lupa.svg';
import imgSend from '../src/img/Home/send.svg';
import Calendar from './Components/Calendar';
import imgBack from '../src/img/CrearGrupo/arrow_left.svg';
import imgGym from '../src/img/CrearGrupo/gym.png';
import imgSinEventos from '../src/img/CrearGrupo/nou.png';
import {FaCamera} from 'react-icons/fa'; // npm i react-icons

import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import LoadingSpinner from "./Components/LoadingSpinner";
import EventosBusqueda from './Components/EventosBusqueda';
import EventosDetalles from './Components/EventosDetalles';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InputGroup from 'react-bootstrap/InputGroup';
import CanvasFoto from './Components/CanvasFoto';
import TimePicker from 'react-bootstrap-time-picker';
import SearchGoogleMaps from './Components/SearchGoogleMaps';

function GrupoUpdate(){

const navigate = useNavigate();
let itop = 0;

const [items2, setItems2] = useState();
const [counter, setCounter] = useState(0);
const [isLoading, setIsLoading] = useState(false);
const [isVisible, setIsVisble] = useState('');
const [caractetisticas, setcaractetisticas] = useState();
const [vcaracteristica, setvcaracteristica] = useLocalStorage('vcaracteristica','');
const [imagenSeleccionadaPerfilGrupo, setimagenSeleccionadaPerfilGrupo] = useLocalStorage('imagenSeleccionadaPerfilGrupo','');
const [imagenSeleccionadaPerfilGrupoADM, setimagenSeleccionadaPerfilGrupoADM] = useLocalStorage('imagenSeleccionadaPerfilGrupoADM','');
const [imagen, setImagen] = useLocalStorage('imagen',[]);
const [imagen2, setImagen2] = useLocalStorage('imagen2','');
const [vnombregrupo, setvnombregrupo] = useState('')
const [parametro1, setparametro1] = useLocalStorage('parametro1','');
const [parametro2, setparametro2] = useLocalStorage('parametro2','');
const [parametro3, setparametro3] = useLocalStorage('parametro3','');
const [parametro4, setparametro4] = useLocalStorage('parametro4',false);
const [tabIndex, setTabIndex] = useLocalStorage('tabIndex',0);
const [items, setItems] = useState();
const [fecha,setfecha] = useState(new Date())
const [fecharegistro, setfecharegistro] = useLocalStorage('fecharegistro','')
const [codigogrupoactual, setcodigogrupoactual] = useLocalStorage('codigogrupoactual','');
const [tituloevento, settituloevento] = useState('')
const [descripcionevento, setdescripcionevento] = useState('')
const [fechaInicio, setfechainicio] = useState('')
const [fechaFin, setfechafin] = useState('')
const [horaini, sethoraini] = useState(0)
const [horafin, sethorafin] = useState(0)
const [direccion, setdireccion] = useState('')
const [latitud, setlatitud] = useState('')
const [longitud, setlongitud] = useState('')
const [participantes, setparticipantes] = useState(0)
const [fotoportada, setfotoportada] = useState('')
const [tienecosto, settienecosto] = useState(false)
const [notienecosto, setnotienecosto] = useState(false)
const [precio, setprecio] = useState('')
const [metodopagoyape, setmetodopagoyape] = useState(false);
const [metodopagoplin, setmetodopagoyplin] = useState(false);
const [metodopagotransferencia, setmetodopagotransferencia] = useState(false);
const [numerocuenta, setnumerocuenta] = useState('');
const [moneda, setmoneda] = useState('')
const [vWidth, setWidth] = useState('');  
const [vHeight, setHeight] = useState('');
const [idusuario, setIdusuario] = useLocalStorage('idusuario','')
const [eventoActual, setEventoActual] = useState(null)


const [vplan, setvplan] = useLocalStorage('vplan','')
const [show, setShow] = useState(false);
const [bpago, setbpago] = useLocalStorage('bpago',false);
const [valorPlan, setValorPlan] = useState(4800);
const [valorBaseFijo, setVAlorBaseFijo] = useState(48.00)
const [valorBaseFijo2, setVAlorBaseFijo2] = useState(400.00)
const [valorPlanPrecioBase, setValorPlanPrecioBase] = useState(40.68)
const [valorPlanDecimal, setValorPlanDecimal] = useLocalStorage('valorPlanDecimal',48)
const [valorPlanIgv, setValorPlanIgv] = useState(7.32)
const [valorDescuento, setValorDescuento] = useState(0)
const [couponCode, setCouponCode] = useLocalStorage('couponCode','');
const [couponActivo, setCouponActivo] = useState(0);

function isCanvasEmpty(canvas) {
    const context = canvas.getContext('2d');
    const pixelBuffer = new Uint32Array(context.getImageData(0, 0, canvas.width, canvas.height).data.buffer);
    return !pixelBuffer.some(color => color !== 0);
}



const ActualizarGrupo = async () => {

       
    if(vnombregrupo == '')
    {
        alert("Debe ingresar un nombre de grupo para continuar.");
        return;
    }

    const imgFotoPerfilGrupo = document.getElementById('canvas-foto1');
    let dataURL = "";

    if (imgFotoPerfilGrupo && !isCanvasEmpty(imgFotoPerfilGrupo)) {
               
        dataURL = imgFotoPerfilGrupo.toDataURL("image/webp");
    } else {
        dataURL = imagenSeleccionadaPerfilGrupo;
    }

    const data = JSON.stringify({
        pnombregrupo : vnombregrupo,pdescripciongrupo: parametro1,pnormasgrupo : parametro2,ppreguntasgrupo : parametro3,pgrupocerrado : (parametro4 == true ? 1 : 0),pimagengrupo:dataURL, ppimagengrupoadm:'',pidusuario:idusuario,pcouponCode:couponCode,porderId:0,pmonto:0,codigogrupoactual:codigogrupoactual
       })

    await fetch('https://conectate-peru.com/api/ApiActualizarGrupo.php', {
    method: 'POST',
    body: data,
    headers: {
        'Content-type': 'application/json; charset=UTF-8',
    },
    })
    .then((response) => response.json())
    .then((data) => {

        alert("Grupo actualizado correctamente.")
        limpiar()
        navigate(-1)
    })
    .catch((err) => {
        console.log(err.message);
    });

    
 };

  const fileInputRef = useRef(null);
  const handleOpenFileClick = () => {
    fileInputRef.current.click();
  };

  function niceBytes(a)
  {
    var marker = 1024; // Change to 1000 if required
    var decimal = 3; // Change as required
    var kiloBytes = marker; // One Kilobyte is 1024 bytes
    var megaBytes = marker * marker; // One MB is 1
    return(a / megaBytes).toFixed(decimal);
  }


  const limpiar = () => {

    //localStorage.removeItem('imagenSeleccionadaPerfilGrupoADM')
    sessionStorage.removeItem('CoordinatesLat')
    sessionStorage.removeItem('CoordinatesLng')
    sessionStorage.removeItem('address')
}

  async function convertImage(e)
  {
  
      const files = e.target.files;
      const filePeso = 0;
  
      for (let index = 0; index < files.length; index++) {
          const reader = new FileReader();
      let nWidth = 0;
      let nHeigth = 0;
      
      
      let src = URL.createObjectURL(e.target.files[index]);
      const divcanvas = document.getElementById('wimagen');
      //reader.readAsDataURL(e.target.files[0]);
  
      if(niceBytes(e.target.files[index].size) >= 32.0)
      {
          alert("Imagen excede el tamaño permitido. El peso debe ser menor a 32Mb.")
          return;
      }
  
      reader.readAsDataURL(e.target.files[index]);
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          const {
            height,
            width
          } = image;
          
          setHeight(height);
          setWidth(width);
          if(src.length > 1)
          {
            setImagen(imagen => [...imagen,{ id:Math.random() + index,src:src, height:height, width: width}])

          }
        };
      };  
      }
      

     

      const fileAnun = document.getElementById('fileAnuncio');
      fileAnun.value = '';

      
  }

  const getGrupo = async () => {

    if(codigogrupoactual != '')
    {
        let fd = new FormData() 
        fd.append("pidusuario",idusuario)
        fd.append("pid",codigogrupoactual)

        const response = await axios.post('https://conectate-peru.com/api/ApiListarGruposUsuarioId.php',fd);

        if(response.data != 0)
        {
        
        
        response.data.items.map((item,index) =>{

            const options = { year: 'numeric', month: 'long', day: 'numeric' };

            setfecha(item.FechaRegistro)

            //const fechaFormateada = fecha.toLocaleDateString('es-ES', options);

            setvnombregrupo(item.NombreGrupo)
            setparametro1(item.DescripcionGrupo)
            setparametro2(item.NormasGrupo)
            setparametro3(item.PreguntasGrupo)
            setparametro4(item.GrupoCerrado)
            setimagenSeleccionadaPerfilGrupo(item.ImagenGrupo)
            setimagenSeleccionadaPerfilGrupoADM(item.ImagenGrupoAdm)
            //setfecharegistro(fechaFormateada)
            setIdusuario(item.idusuario)

        })           
        }
    }
}

useEffect(() => {
    getGrupo()
    },[])

    const handleClick1  = (e) => 
    {

        if(counter >= 0)
        {
        setCounter(counter + 4);

        //fetchApi(counter + 4);
        }
        
        //console.log(counter);
    
    }

    const handleClick2  = (e) => 
    {

        if(counter > 0)
        {
        setCounter(counter - 4);
        
        //fetchApi(counter - 4);
        }
        //console.log(counter);
        
    }

    const handleRedirectHome = () =>{

        navigate(-1);
    
      }

      const handleGoPaso2 = () =>{

        navigate("/CreandoGrupo2");
    
      }

      const handleCLickTab = (index) =>
      {
          setTabIndex(index)
      }
   
      function soloNumeros(number)
      {
          
          //let newValor = number.replace(/[^0-9]|^E/i, "");
          let newValor = number.replace(/\D/g, "");
          
          return newValor;
      }
  
       function formatNumber(number)
       {
  
          let newValor = number.replace(/,/g, "");
  
  
          return new Intl.NumberFormat("EN-US").format(newValor);
       }

       const handleTimeChange = (time) => {
        sethoraini(time);
      };

      const handleTimeChange2 = (time) => {
        sethorafin(time);
      };

      const handleSubmit = (e) => {
        e.preventDefault();
        ActualizarGrupo();
        // Convertir el tiempo numérico en HH:mm

      };

return(<>

    <Layout>
        <Container style={{paddingTop:'calc(5em + 5vw)'}}>
            <Row>
                <Col sm={3}>
                    <img src={imgBack} style={{float:'right',cursor:'pointer'}} onClick={handleRedirectHome}></img>
                </Col>
                <Col sm={6}>
                <p style={{fontSize:'12pt',color:'#FF2D52'}}>Volver</p>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                   
                </Col>
                <Col sm={4}>
                <Container>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                            <center><b style={{fontSize:'16pt'}}>Actualizando Grupo</b></center>
                            </Col>
                        </Row>
                        <Row style={{paddingTop:'calc(1em + 1vw)'}}>
                            <Col>
                            <b style={{fontSize:'14pt',color:'#412F8C'}}>Datos del Grupo</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                            <Form.Label style={{fontSize:'10pt',color:'black'}}>Nombre del Grupo</Form.Label>
                            <Form.Control
                                type="text"
                                value={vnombregrupo}
                                onChange={(e) => setvnombregrupo(e.target.value)}
                                required
                                style={{fontSize:'9pt',color:'black'}}
                            />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                            <Form.Label style={{fontSize:'10pt',color:'black'}}>Cuéntanos un poco de que trata tu grupo</Form.Label>
                            <Form.Control
                                type="text"
                                value={parametro1}
                                onChange={(e) => setparametro1(e.target.value)}
                                required
                                style={{fontSize:'9pt',color:'black'}}
                            />
                            </Form.Group>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col sm={12}>
                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                            <Form.Label style={{fontSize:'10pt',color:'black'}}>Describe las normas de tu grupo</Form.Label>
                            <Form.Control
                                type="text"
                                value={parametro2}
                                onChange={(e) => setparametro2(e.target.value)}
                                required
                                style={{fontSize:'9pt',color:'black'}}
                            />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                            <Form.Label style={{fontSize:'10pt',color:'black'}}>¿El grupo es cerrado?</Form.Label>
                            <Form.Check
                            type="checkbox"
                            label='Si'
                            checked={parametro4}
                            onChange={(e) => setparametro4(e.target.checked)}
                            
                        />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                            <Form.Label style={{fontSize:'10pt',color:'black'}}>Pregunta para acceder al grupo</Form.Label>
                            <Form.Control
                                type="text"
                                value={parametro3}
                                onChange={(e) => setparametro3(e.target.value)}
                                required
                                style={{fontSize:'9pt',color:'black'}}
                            />
                            </Form.Group>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col sm={12}>
                            <p style={{fontSize:'10pt',color:'black'}}>Foto de perfil de grupo</p>
                            </Col>
                        </Row>
                        <Row>
                        <Col sm={4}>
                                <div className='bgperfil'>
                         
                                {!imagen ? 'Cargando imagen portada' : imagen.map( (item,index) => {
                                        /*<Canvas src={item.src} width={item.width} height={item.height} ></Canvas>*/
                                        return(<>
                                        <CanvasFoto src={item.src} width={item.width} height={item.height} index='1'></CanvasFoto>
                                        </>
                                        )
                                    }) 
                                    }
                                    <img src={imagenSeleccionadaPerfilGrupo} style={{height:'150px',width:'150px',backgroundSize: 'cover',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',index:'1',display:(imagen == null || imagen == '') ? 'block' : 'none'}}></img>

                                    <div style={{borderTop:'1px solid #9c9c9c',bottom:'0',height:'20px',width:'100%',position:'absolute',borderBottomRightRadius:'10px',borderBottomLeftRadius:'10px',background: 'linear-gradient(to right,rgba(255,255,255,0), #9c9c9c)',zIndex:'1',cursor:'pointer'}} onClick={handleOpenFileClick}>
                                        <center><FaCamera style={{cursor:'pointer'}}></FaCamera></center>
                                        <input id="fileAnuncio" type="file" ref={fileInputRef}
                                            style={{ display: 'none' }} accept="image/png,image/jpeg,image/jpe"
                                        onChange={(e) => convertImage(e)}/>
                                    </div>
                                  
                                </div>
                                <br></br>
                            </Col>
                            <Col sm={4}>
                            </Col>
                            <Col sm={4}>
                            </Col>
                        </Row>                     
                        <br></br>
                        <Row>
                            <Col sm={12}>
                            <Button variant='primary3' type='submit'>
                                    <b style={{fontSize:'12pt'}}>Actualizar Grupo</b>
                                    </Button>
                            </Col>
                        </Row>
                    </Form>
                </Container>
                </Col>
                <Col sm={4}>
                
                </Col>
            </Row>

        </Container>
    </Layout>



</>);


}

export default GrupoUpdate;