import React,{useState,useEffect} from "react";
import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Carousel from "react-bootstrap/Carousel";
import { Link,useNavigate } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import imgTagVarios from "../img/Home/tag_varios.svg";
import Button from 'react-bootstrap/Button';
import { useLocalStorage } from "../useLocalStorage";
import imgBack from '../img/CrearGrupo/arrow_left.svg';
import Layout from "../Components/Layout";

const zoomOutProperties = {
    duration: 5000,
    transitionDuration: false,
    infinite: false,
    indicators: false,
    scale: 0.4,
    arrows: true
    };

function SolicitudGrupo({ListaImagenes,Titulo,FechaRegistro})
{

const navigate = useNavigate();
const [items, setItems] = useState();
const [index, setIndex] = useState(0);
const [letrasAleatorias, setLetrasAleatorias] = useState([]);
const [bmigrupo, setmigrupo] = useLocalStorage('bmigrupo',false)
const [descripcion, setdescripcion] = useState('');
const [usuarioActivo,setusuarioActivo] = useState(() => {

  const UsuActivo = sessionStorage.getItem("UsuarioActivo");
 
  
  if(UsuActivo == null)
  {
      return;
  }
  
  const UsuActivoS  = JSON.parse(UsuActivo);

  return UsuActivoS || "";
  });

const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleRedirect = (tipo) => {
         
    if(tipo == 1)
    {
      navigate('/CrearEvento');
    }else{
      
    }
  
  }

  const handleRedirectHome = () =>{
    navigate(-1)
  }

async function fetchApi()
{
  
    //const jsonNew = JSON.parse(ListaImagenes);
    //setItems(jsonNew.items);
    generarLetrasAleatorias();
    

}

const generarLetrasAleatorias = () => {
    const letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const letrasArray = letras.split('');
    const letrasAleatoriasArray = [];

    for (let i = 0; i < 3; i++) {
      const indiceAleatorio = Math.floor(Math.random() * letrasArray.length);
      letrasAleatoriasArray.push(letrasArray[indiceAleatorio]);
    }

    setLetrasAleatorias(letrasAleatoriasArray);
  };

useEffect(() => {
    fetchApi();
    },[])

    const handleSubmit = (e) => {
    
      e.preventDefault();
      alert("Se envio al administrador del grupo su solicitud. Responderá muy pronto.");
      navigate(-1)
      
   }; 

    return (
        <>
        <Layout>
         <Container style={{paddingTop:'calc(6em + 6vw)',paddingLeft:'0',overflow:'hidden'}}>
          <Form onSubmit={handleSubmit}>
         <Row>
                <Col sm={3}>
                    <img src={imgBack} style={{float:'right',cursor:'pointer'}} onClick={handleRedirectHome}></img>
                </Col>
                <Col sm={6}>
                <p style={{fontSize:'12pt',color:'#FF2D52',cursor:'pointer'}} onClick={handleRedirectHome}>Volver</p>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
            <Row>
              <Col sm={3}>
              </Col>
              <Col sm={6}>
                <center><p><b>¿Por qué te interesa unirte a este grupo?</b></p></center>
              </Col>
              <Col sm={3}>
              </Col>
             </Row>
             <Row>
              <Col sm={3}>
              </Col>
              <Col sm={6}>
                <center> 
                  <Form.Group className="sm-3" controlId="exampleForm.ControlInput2">
                    <Form.Control
                        as="textarea" 
                        rows={9}
                        value={descripcion}
                        onChange={(e) => setdescripcion(e.target.value)}
                        placeholder="Ejm: Me interesa el arte y poder aprender má..."
                        required
                    />
                    </Form.Group></center>
              </Col>
              <Col sm={3}>
              </Col>
             </Row>
             <br></br>
             <Row>
              <Col sm={5}>
              </Col>
              <Col sm={2}>
              <Button variant='primary3' type="submit">
                                    <b style={{fontSize:'12pt'}}>Enviar</b>
                                    </Button>
              </Col>
              <Col sm={5}>
              </Col>
             </Row>
             </Form>
            </Container> 
            </Layout>
        </>
    );


}




export default SolicitudGrupo;