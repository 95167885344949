import React, { useState,useEffect } from 'react';
import Layout from "./Components/Layout";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import imgLogo from '../src/img/Login/logo.svg';
import imgEscaladaNieve from '../src/img/Login/escalada_nieve.jpg';
import { Link,redirect,useNavigate } from "react-router-dom";
import { useLocalStorage } from './useLocalStorage';
import {FaCheckCircle, FaTrash,BiFilterAlt,FaEyeSlash,FaEye } from 'react-icons/fa'; // npm i react-icons
import Modal from 'react-bootstrap/Modal';

function Registro()
{
    const [show2, setShow2] = useState(false);
    const [perfil, setPerfil] = useState('11');
    const [numerodocumento, setnumerodocumento] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [celular, setCelular] = useState('');
    const [correo, setCorreo] = useState('');
    const [contraseña, setContraseña] = useState('');
    const [respuesta, setRespuesta] = useState('');
    const [verSeleccionarPerfil, setSeleccionarPerfil] = useState(true)
    const [verSeleccionarPerfilProfesional, setSeleccionarPerfilProfesional] = useState(false)
    const [perfilSeleccionadoRegistro, setperfilSeleccionadoRegistro] = useLocalStorage('perfilSeleccionadoRegistro','1')
    const [tipoperfilseleccionadoRegistro, settipoperfilseleccionadoRegistro] = useLocalStorage('tipoperfilseleccionadoRegistro',0)
    const [idusuario, setIdusuario] = useLocalStorage('idusuario','')
    const [tipodocumento, settipodocumento] = useState('1');
    const [codigoSeguridad, setCodigoSeguridad] = useState('')
    const [digit1, setdigit1] = useState('')
    const [digit2, setdigit2] = useState('')
    const [digit3, setdigit3] = useState('')
    const [digit4, setdigit4] = useState('')
  
    const url = 'https://conectate-peru.com/api/ApiListarTipoUsuario.php'
    const [items, setItems] = useState();

    const navigate = useNavigate();

   /*registro*/

   const clear = () => {
    setPerfil('');
    setApellido('');
    setNombre('');
    setnumerodocumento('');
    setCelular('');
    setCorreo('');
    setContraseña('');
  }

   const fetchApi = async () => {
    
   
}

function generarCodigoAleatorio() {
    // Generar un número aleatorio entre 0 y 9999
    let codigo = Math.floor(Math.random() * 10000);
    
    // Asegurarse de que el código tenga 4 cifras, agregando ceros al inicio si es necesario
    codigo = codigo.toString().padStart(4, '0');
    
    // Mostrar el código en el div con id "code"
    setCodigoSeguridad(codigo);
}

    useEffect(() => {

        if(codigoSeguridad == '')
        {
            generarCodigoAleatorio()
        }

    },[codigoSeguridad])

   const RegistroAcceso = async (perfil,numerodocumento,nombre,apellido,celular,correo, contraseña) => {

    //envio de correo

    const code = [
        document.getElementById('digit1').value,
        document.getElementById('digit2').value,
        document.getElementById('digit3').value,
        document.getElementById('digit4').value
    ].join('');

    if(code != codigoSeguridad)
    {
        alert("Codigo ingresado invalido, intente nuevamente, si el problema persiste actualice la pagina y complete el formulario nuevamente.")
        return;
    }

    if(tipodocumento == '')
    {
        alert("Debe seleccionar un tipo de documento.");
        return;
    }

    if(numerodocumento == '')
    {
        return;
    }

    const data = JSON.stringify({
        perfil : 11,numerodocumento: numerodocumento,nombre : nombre,apellido : apellido,celular : celular,correo:correo, contrasenia:contraseña,tipodocumento:tipodocumento,tipousuario:1,indvalidacion:1
       })

    //console.log(data);

    await fetch('https://conectate-peru.com/api/ApiRegistro.php', {
       method: 'POST',
       body: data,
       headers: {
          'Content-type': 'application/json; charset=UTF-8',
       },
    })
       .then((response) => response.json())
       .then((data) => {
          //setPosts((posts) => [data, ...posts]);
          
          //const respuesta = JSON.parse(data);
          const respuesta = data.usuarioid;

          if(respuesta == 0)
          {
             alert("El documento ingresado ya cuenta con un usuario, puede recuperar su contraseña desde la opción 'Recuperar Contraseña'");  
             return; 
          }else{
            
                alert("Los Datos fueron registrados correctamente, puede iniciar sesión con sus credenciales."); 
                clear();
                navigate("/Login")
          }
       })
       .catch((err) => {
          console.log(err.message);
       });
 };


   function formatCadena(cadena)
   {
      let newValor = cadena.replace(/[^A-Za-z ñ]/,"");
      //console.log(newValor);
  
      return newValor;
   }
  
   function soloNumeros(number)
   {
      
      let newValor = number.replace(/\D/g, "");
      
      return newValor;
   }

   const handleSubmit = (e) => {
    
    e.preventDefault();
    RegistroAcceso(perfil,numerodocumento,nombre,apellido,celular,correo, contraseña);
    
 }; 

 const EnviarCodigoSeguridad = async () => {
  
    if(correo == '')
    {
        return;
    }

    await fetch('https://conectate-peru.com/api/ApiSendMailValidarCodigoSeguridad.php', {
       method: 'POST',
       body: JSON.stringify({
          correo: correo,
          codigovalidacion : codigoSeguridad
       }),
       headers: {
          'Content-type': 'application/json; charset=UTF-8',
       },
    })
       .then((response) => response.json())
       .then((data) => {
          //setPosts((posts) => [data, ...posts]);

          const respuesta = JSON.parse(data);
          console.log(respuesta);

          if(respuesta == 0 || correo == '')
          {
             alert("Debe ingresar correo para continuar.");   
          }else if (respuesta != 0){

            //alert("Verifique su bandeja de entrada, recibira un código de seguridad para ingresar en el siguiente paso.");   

          }

     
       })
       .catch((err) => {
          console.log(err.message);
       });
 };

 const handleClickValidarCodigo = () => {
    EnviarCodigoSeguridad()
    setShow2(true)
  };

  function moveToNext(current, nextFieldID) {
    if (current.value.length >= 1) {
        document.getElementById(nextFieldID).focus();
    }
}

function moveToPrevious(event, prevFieldID) {
    if (event.key === 'Backspace' && event.target.value === '') {
        document.getElementById(prevFieldID).focus();
    }
}

function submitCode() {
    const code = [
        document.getElementById('digit1').value,
        document.getElementById('digit2').value,
        document.getElementById('digit3').value,
        document.getElementById('digit4').value
    ].join('');
    alert('Código ingresado: ' + code);
}

const handleClose2 = () => setShow2(false);

return(
    <div className="split-container">
      <div className="split-left d-none d-sm-block">
        <Container style={{padding:'0 0 0 0'}}>
                     
                        <Row>
                            <Col sm={12}>
                            <img src={imgEscaladaNieve} style={{width:'100%',height:'100%',objectFit:'contain',alignItems:'center',justifyContent:'center'}}></img>
                            </Col>
                        </Row>
                    </Container></div>
      <div className="split-right">
        
        <Container  style={{paddingTop:'calc(2.5em + 2.5vw)'}}>
        <Row>
                            <Col sm={12}>
                                <center><img src={imgLogo}></img></center>
                            </Col>
                        </Row>
            <Row>
                <Col sm={12}>
                    <center><p style={{fontSize:'16pt'}}><b>Registrarme</b></p></center>
                </Col>
            </Row>
            <Row>

                <Col sm={12}>

                <Form>

<Container style={{paddingBottom:'20px',paddingTop:'20px'}}>

    <Row>
        <Col sm={3}>
        </Col>
        <Col sm={6}>
        <Form.Group className="sm-3" controlId="exampleForm.ControlInput2">
                <Form.Label style={{fontSize:'10pt',color:'black'}}>Tipo de Documento</Form.Label>
                <Form.Select 
                value={tipodocumento}
                onChange={(e) => settipodocumento(e.target.value)}
                style={{fontSize:'10pt',color:'black'}}
                >
                <option Select value={4}>RUC</option>
                    <option value={1}>DNI</option>
                    <option value={2}>CARNET DE EXTRANJERIA</option>
                    <option value={3}>PASAPORTE</option>
                    
                    </Form.Select>
                </Form.Group>
        </Col>
        <Col sm={3}>
        </Col>
    </Row>
    <Row>
        <Col sm={3}>
        </Col>
        <Col sm={6}>
        <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                <Form.Label style={{fontSize:'10pt',color:'black'}}>Número de documento</Form.Label>
                <Form.Control
                    type="text"
                    value={numerodocumento}
                    onChange={(e) => setnumerodocumento(soloNumeros(e.target.value))}
                    required
                    style={{fontSize:'9pt',color:'black'}}
                />
                </Form.Group>
        </Col>
        <Col sm={3}>
        </Col>
    </Row>
    <Row>
    <Col sm={3}>
        </Col>
        <Col sm={6}>
        <Form.Group className="sm-3" controlId="exampleForm.ControlInput2">
        <Form.Label style={{fontSize:'10pt',color:'black'}}>Nombre</Form.Label>
        <Form.Control
            type="text"
            value={nombre}
            onChange={(e) => setNombre(formatCadena(e.target.value))}
            minLength="4"
            required
            style={{fontSize:'9pt',color:'black'}}
        />
        </Form.Group>
        </Col>
        <Col sm={3}>
        </Col>
    </Row>
    <Row>
    <Col sm={3}>
        </Col>
        <Col sm={6}>
        <Form.Group className="sm-3" controlId="exampleForm.ControlInput3">
        <Form.Label style={{fontSize:'10pt',color:'black'}}>Apellido</Form.Label>
        <Form.Control
            type="text"
            value={apellido}
            minLength="4"
            onChange={(e) => setApellido(formatCadena(e.target.value))}
            required
            style={{fontSize:'9pt',color:'black'}}
        />
        </Form.Group>
        </Col>
        <Col sm={3}>
        </Col>
    </Row>
    <Row>
    <Col sm={3}>
        </Col>
        <Col sm={6}>
        <Form.Group className="sm-3" controlId="exampleForm.ControlInput4">
        <Form.Label style={{fontSize:'10pt',color:'black'}}>Celular</Form.Label>
        <Form.Control
            type="text"
            minLength="8"
            value={celular}
            onChange={(e) => setCelular(soloNumeros(e.target.value))}
            required
            style={{fontSize:'9pt',color:'black'}}
        />
        </Form.Group>
        </Col>
        <Col sm={3}>
        </Col>
    </Row>
    <Row>
    <Col sm={3}>
        </Col>
        <Col sm={6}>
        <Form.Group className="sm-3" controlId="exampleForm.ControlInput5">
        <Form.Label style={{fontSize:'10pt',color:'black'}}>Email</Form.Label>
        <Form.Control
            type="email"
            placeholder="nombre@dominio.com"
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
            required
            style={{fontSize:'9pt',color:'black'}}
        />
        </Form.Group>
        </Col>
        <Col sm={3}>
        </Col>
    </Row>
    <Row>
    <Col sm={3}>
        </Col>
        <Col sm={6}>
        <Form.Group className="sm-3" controlId="exampleForm.ControlInput6">
        <Form.Label style={{fontSize:'10pt',color:'black'}}>Contraseña</Form.Label>
        <Form.Control
            type="password"
            value={contraseña}
            minLength="6"
            onChange={(e) => setContraseña(e.target.value)}
            required
            style={{fontSize:'9pt',color:'black'}}
        />
        </Form.Group>
        </Col>
        <Col sm={3}>
        </Col>
    </Row>
</Container>        
<br></br>
<Container>
<Row>
<Col sm={3}>
        </Col>
    <Col sm={6}>
   <center>
   
   <Button variant="primary3" type='button' onClick={handleClickValidarCodigo}>
            <p style={{fontSize:'12pt'}}> Continuar</p>
        </Button>
    </center> 
    </Col>
    <Col sm={3}>
        </Col>
</Row>
</Container>

<Modal  centered={true}  show={show2} onHide={handleClose2}>
        <Modal.Header style={{border:'none'}} closeButton >
          <Container>
            <br></br>
            <Row>
              <Col sm={12}>
              <center><Modal.Title style={{fontSize:'15pt',height:'30px'}}><p><b>Ingrese clave de validación que se encuentra en su bandeja de entrada para terminar el registro</b></p></Modal.Title></center>
              </Col>
            </Row>
          </Container>
          <br></br>
        </Modal.Header>
        <Modal.Body style={{borderRadius:'5px'}}>
        <br></br>
        <br></br>
            <Container style={{padding:'0 0 0 0'}}>
              <Row>
                <Col sm={2}>

                </Col>
                <Col sm={8}>

                    <Container>
                        <div class="containerValidaContrasenia">
                        <div class="input-groupValidaContrasenia">
                            <input type="text" className='input_ValidaContrasenia' value={digit1} onChange={(e) => setdigit1(e.target.value)} maxlength="1" id="digit1" oninput="moveToNext(this, 'digit2')" required></input>
                            <input type="text" className='input_ValidaContrasenia' value={digit2} onChange={(e) => setdigit2(e.target.value)} maxlength="1" id="digit2" oninput="moveToNext(this, 'digit3')" onkeydown="moveToPrevious(event, 'digit1')" required></input>
                            <input type="text" className='input_ValidaContrasenia' value={digit3} onChange={(e) => setdigit3(e.target.value)} maxlength="1" id="digit3" oninput="moveToNext(this, 'digit4')" onkeydown="moveToPrevious(event, 'digit2')" required></input>
                            <input type="text" className='input_ValidaContrasenia' value={digit4} onChange={(e) => setdigit4(e.target.value)} maxlength="1" id="digit4" onkeydown="moveToPrevious(event, 'digit3')" required></input>
                        </div>
                        
                        </div>
                    </Container>
         
                </Col>
                <Col sm={2}>

                </Col>
              </Row>
            </Container>
            <Container style={{paddingTop:'20px'}}>
            <Row>
                <Col sm={4}></Col>
                <Col sm={4}>
               <center>
               <Button variant="primary3" type='button' onClick={handleSubmit}>
                      <b style={{fontSize:'9pt'}}>Aceptar</b>  
                    </Button>
                </center> 
                </Col>
                <Col sm={4}></Col>
            </Row>
        </Container>
      
          <br></br>
        </Modal.Body>
      </Modal>

</Form>


                </Col>

            </Row>
        </Container>
         
      </div>
    </div>

);

}

export default Registro;