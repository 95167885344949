import React, { useState,useEffect } from 'react';
import Layout from "./Components/Layout";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link,useNavigate } from "react-router-dom";
import { useLocalStorage } from './useLocalStorage';
import Button from 'react-bootstrap/Button';
import imgLupa from '../src/img/Home/lupa.svg';
import imgSend from '../src/img/Home/send.svg';
import Calendar from './Components/Calendar';
import imgBack from '../src/img/CrearGrupo/arrow_left.svg';
import imgGym from '../src/img/CrearGrupo/gym.png';
import imgSinEventos from '../src/img/CrearGrupo/nou.png';

import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import LoadingSpinner from "./Components/LoadingSpinner";
import EventosBusqueda from './Components/EventosBusqueda';
import EventosDetalles from './Components/EventosDetalles';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import EventosDetalleGrupo from './Components/EventosDetalleGrupo';


function ListaGrupoUsuario(){

const navigate = useNavigate();
let itop = 0;

const [items2, setItems2] = useState();
const [counter, setCounter] = useState(0);
const [isLoading, setIsLoading] = useState(false);
const [isVisible, setIsVisble] = useState('');
const [caractetisticas, setcaractetisticas] = useState();
const [vcaracteristica, setvcaracteristica] = useLocalStorage('vcaracteristica','');
const [imagenSeleccionadaPerfilGrupo, setimagenSeleccionadaPerfilGrupo] = useLocalStorage('imagenSeleccionadaPerfilGrupo','');
const [imagenSeleccionadaPerfilGrupoADM, setimagenSeleccionadaPerfilGrupoADM] = useLocalStorage('imagenSeleccionadaPerfilGrupoADM','');
const [imagen, setImagen] = useLocalStorage('imagen',[]);
const [imagen2, setImagen2] = useLocalStorage('imagen2',[]);
const [vnombregrupo, setvnombregrupo] = useLocalStorage('vnombregrupo','');
const [parametro1, setparametro1] = useLocalStorage('parametro1','');
const [parametro2, setparametro2] = useLocalStorage('parametro2','');
const [parametro3, setparametro3] = useLocalStorage('parametro3','');
const [parametro4, setparametro4] = useLocalStorage('parametro4',false);
const [tabIndex, setTabIndex] = useLocalStorage('tabIndex',0);
const [items, setItems] = useState();
const [fecha,setfecha] = useState(new Date())
const [fecharegistro, setfecharegistro] = useLocalStorage('fecharegistro','')
const [codigogrupoactual, setcodigogrupoactual] = useLocalStorage('codigogrupoactual','');
const [idusuario, setIdusuario] = useLocalStorage('idusuario','')
const [idusuarioselect, setIdusuarioselect] = useState('')
const [bmigrupo, setmigrupo] = useLocalStorage('bmigrupo',false)

const [events, setEvents] = useState([
    {
      id: 1,
      title: 'Evento 1',
      date: '2024-05-09',
      time: '10:00',
      address: 'Calle Principal 123',
      attendees: 50,
      cost: '$20',
    },
    {
      id: 2,
      title: 'Evento 2',
      date: '2024-05-10',
      time: '15:00',
      address: 'Avenida Secundaria 456',
      attendees: 30,
      cost: '$15',
    },
  ]);


const getGrupo = async () => {

        if(codigogrupoactual != '')
        {
            let fd = new FormData() 
            fd.append("pidusuario",idusuario)
            fd.append("pid",codigogrupoactual)

            const response = await axios.post('https://conectate-peru.com/api/ApiListarGruposUsuarioId.php',fd);

            if(response.data != 0)
            {
            
            
            response.data.items.map((item,index) =>{

                const options = { year: 'numeric', month: 'long', day: 'numeric' };

                setfecha(item.FechaRegistro)

                const fechaFormateada = fecha.toLocaleDateString('es-ES', options);

                setvnombregrupo(item.NombreGrupo)
                setparametro1(item.DescripcionGrupo)
                setparametro2(item.NormasGrupo)
                setparametro3(item.PreguntasGrupo)
                setparametro4(item.GrupoCerrado)
                setimagenSeleccionadaPerfilGrupo(item.ImagenGrupo)
                setimagenSeleccionadaPerfilGrupoADM(item.ImagenGrupoAdm)
                setfecharegistro(fechaFormateada)
                setIdusuarioselect(item.idusuario)

                if(parseInt(item.idusuario) == parseInt(idusuario))
                {
                    setmigrupo(true);
                }

            })           
            }
        }
  }

  const EliminarGrupo = async (id) => {

       
    

    
    const data = JSON.stringify({
        codigogrupoactual:id
       })

    await fetch('https://conectate-peru.com/api/ApiEliminarGrupo.php', {
    method: 'POST',
    body: data,
    headers: {
        'Content-type': 'application/json; charset=UTF-8',
    },
    })
    .then((response) => response.json())
    .then((data) => {

        alert("Grupo eliminado correctamente.")
        //window.location.reload(true);
    })
    .catch((err) => {
        console.log(err.message);
    });

    
 };


useEffect(() => {
    window.scrollTo(0, 0);
    //getGrupo()
    getGrupos()
    },[])


    const handleRedirectHome = () =>{

        setmigrupo(false)
        navigate(-1);
    
      }

    const handleRedirectGrupoDetalle =(id) =>
    {
        setcodigogrupoactual(id)
        navigate('/GrupoCreadoDetalle');
    }

    const handleRedirectGrupoUpdate =(id) =>
      {
          setcodigogrupoactual(id)
          navigate('/GrupoUpdate');
      }

      const handleCLickTab = (index) =>
      {
          setTabIndex(index)
      }

      const getGrupos = async() => {
       
        let fd = new FormData() 
        fd.append("pidusuario",idusuario)

        const res = await axios.post('https://conectate-peru.com/api/ApiListarGruposxUsuarioall.php',fd);
        setItems2(res.data.items);
       

     }

      const handleDelete = (id) => {
        setItems2(items2.filter(event => event.Id !== id));
        EliminarGrupo(id);
      };
   
      const handleEdit = (id,name) => {
        alert("Hubo un problema al editar el grupo: "+ name)
      };


return(<>

    <Layout>
        <Container style={{paddingTop:'calc(5em + 5vw)'}}>
            <Row>
                <Col sm={3}>
                    <img src={imgBack} style={{float:'right',cursor:'pointer'}} onClick={handleRedirectHome}></img>
                </Col>
                <Col sm={6}>
                <p style={{fontSize:'12pt',color:'#FF2D52'}}>Volver</p>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                    
                </Col>
                <Col sm={6}>
                <p style={{fontSize:"18pt",padding:'0 0 0 0',textAlign:"left"}}><b>Grupos</b></p>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>

            <Row style={{paddingTop:'20px'}}>
                <Col sm={3}>
                    
                </Col>
                <Col sm={6}>
                <Tabs id='tabprincipal' selectedIndex={tabIndex} onSelect={(index) => handleCLickTab(index)} style={{borderBottom: '1px', borderColor: 'black',fontSize:'10pt'}}>
      <TabList>
        <Tab value={1}>Admin</Tab>
        <Tab value={2}>Soy Miembro</Tab>
      </TabList>
      <TabPanel value={1}>
      <table className='styled-table'>
      <thead>
        <tr>
          <th>Titulo</th>
          <th>Fecha y Hora</th>
          <th>Descripción</th>
          <th>Miembros</th>
          <th>Costo</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
         { !items2 ? 'Sin Grupos que mostrar.' : items2.map( (item,index) => {
                
                return(
                    <tr key={item.Id}>
                    <td style={{cursor:'pointer'}} onClick={(e) => handleRedirectGrupoDetalle(item.Id)}>{item.NombreGrupo}</td>
                    <td style={{cursor:'pointer'}} onClick={(e) => handleRedirectGrupoDetalle(item.Id)}>{item.FechaCreacion}</td>
                    <td style={{cursor:'pointer'}} onClick={(e) => handleRedirectGrupoDetalle(item.Id)}>{item.DescripcionGrupo}</td>
                    <td style={{cursor:'pointer'}} onClick={(e) => handleRedirectGrupoDetalle(item.Id)}>0</td>
                    <td style={{cursor:'pointer'}} onClick={(e) => handleRedirectGrupoDetalle(item.Id)}>0</td>
                    <td>
                    <button onClick={() => handleRedirectGrupoUpdate(item.Id) } style={{marginRight:'10px'}}>Editar</button>
                    <button onClick={() => handleDelete(item.Id)}>Eliminar</button>
                    </td>
                    </tr>
                )
            })}
      </tbody>
    </table>
      </TabPanel>
      <TabPanel value={2}>
      <div style={{height:'200px'}}>
            <p>No hay datos para mostrar</p>
        </div>
      </TabPanel>
    </Tabs>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
          



        </Container>
    </Layout>



</>);


}

export default ListaGrupoUsuario;