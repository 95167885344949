import React,{useState,useEffect} from "react";
import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Carousel from "react-bootstrap/Carousel";
import { Link,useNavigate } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import imgTagVarios from "../img/Home/tag_varios.svg";
import { useLocalStorage } from "../useLocalStorage";

const zoomOutProperties = {
    duration: 5000,
    transitionDuration: false,
    infinite: false,
    indicators: false,
    scale: 0.4,
    arrows: false
    };

function Grupos({NombreGrupo,ImagenGrupoAdm,DescripcionGrupo,FechaCreacion,IdGrupo})
{


const navigate = useNavigate();
const [items, setItems] = useState();
const [index, setIndex] = useState(0);
const [letrasAleatorias, setLetrasAleatorias] = useState([]);
const [fecha, setfecha] = useState(new Date())
const [fechaFormat, setFechaformateada] = useState('')
const [codigogrupoactual, setcodigogrupoactual] = useLocalStorage('codigogrupoactual','');
const [miembros, setmiembros] = useState(0)



const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleRedirect = () => {
    
    setcodigogrupoactual(JSON.parse(IdGrupo))
    navigate('/GrupoCreadoDetalle');
  
  }


const generarLetrasAleatorias = () => {
    const letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const letrasArray = letras.split('');
    const letrasAleatoriasArray = [];

    for (let i = 0; i < 3; i++) {
      const indiceAleatorio = Math.floor(Math.random() * letrasArray.length);
      letrasAleatoriasArray.push(letrasArray[indiceAleatorio]);
    }

    setLetrasAleatorias(letrasAleatoriasArray);
  };

useEffect(() => {
    //fetchApi();

    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    setfecha(FechaCreacion)

    const fechaFormateada = fecha.toLocaleDateString('es-ES', options);

    setFechaformateada(fechaFormateada);

    generarLetrasAleatorias();
    },[])

    return (
        <>
         <Card style={{ width: 'calc(290px + 2vmin)', height:'27rem',border:'none'}}>
          <Card.Body>
            <Card.Title style={{ borderRadius:"10px",width: "calc(260px + 2vmin)",
                 height: "calc(170px + 2vmin)" }}>
            <div className="slide-container">
    
          
                  <center>
                  <LazyLoadImage
                 effect="blur"
                 src={ImagenGrupoAdm}
                 style={{ borderTopLeftRadius:"10px",borderTopRightRadius:"10px",width: "calc(260px + 2vmin)",
                 height: "calc(170px + 2vmin)",
                 objectFit: "cover" }}
                 onClick={(e) => handleRedirect()}
                 ></LazyLoadImage>
                  </center>
         
           
            </div>
            </Card.Title>
            <Card.Text style={{minHeight:'9rem',border:'none'}} onClick={(e) => handleRedirect()}>
              <Container style={{padding:'0 0 0 0'}}>
                <Row>
                  <Col  xs={12}>
                    <p style={{fontSize:"8pt",padding:'0 0 0 0',textAlign:"left"}}><b>{fechaFormat}</b></p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <p style={{fontSize:"18pt",padding:'0 0 0 0',textAlign:"left"}}><b>{NombreGrupo}</b></p>
                  </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                       
                    {letrasAleatorias.map((letra, index) => (
                        
                        (index == 0) ? <><div class="circle red"><center><p style={{padding:'5px 0 0 0'}}>{letra}</p></center></div></> : ((index == 1) ? <><div class="circle green"><center><p style={{padding:'5px 0 0 0'}}>{letra}</p></center></div></> : <><div class="circle blue"><center><p style={{padding:'5px 0 0 0'}}>{letra}</p></center></div></> )
                        
                        ))}
                    </Col>
                  <Col xs={8}>
                    <p style={{fontSize:"12pt",padding:'0 0 0 0',textAlign:"left"}}>{miembros} miembros</p>
                    </Col>
                </Row>
               
              </Container>
            </Card.Text>
            
          </Card.Body>
        </Card>
        </>
    );


}




export default Grupos;