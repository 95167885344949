import React from 'react';
import CouponForm from './Components/CouponForm';
import CouponGenerator from './Components/CouponGenerator';

import LayoutAdm from "./Components/LayoutAdm";

function CrearCupon()
{

const handleCouponGenerated = (couponCode) => {
  // Aquí puedes manejar el código generado, como almacenarlo en el estado o enviarlo a un servidor
  //alert(`Cupón generado: ${couponCode}`);
};

return(
<>
  <LayoutAdm>
      <div style={{paddingTop:'100px'}}>
        <center><h1>Generador de Cupones</h1></center>
        <CouponGenerator onCouponGenerated={handleCouponGenerated} />
        
      </div>
  </LayoutAdm>
</>

  

);

}

export default CrearCupon;