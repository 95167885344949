import React, { useState,useEffect,useRef } from 'react';
import Layout from "./Components/Layout";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link,useNavigate } from "react-router-dom";
import { useLocalStorage } from './useLocalStorage';
import Button from 'react-bootstrap/Button';
import imgLupa from '../src/img/Home/lupa.svg';
import imgSend from '../src/img/Home/send.svg';
import Calendar from './Components/Calendar';
import imgBack from '../src/img/CrearGrupo/arrow_left.svg';
import imgGym from '../src/img/CrearGrupo/gym.png';
import imgSinEventos from '../src/img/CrearGrupo/nou.png';
import {FaCamera} from 'react-icons/fa'; // npm i react-icons

import axios from 'axios'; // npm i axios
import "react-slideshow-image/dist/styles.css";
import LoadingSpinner from "./Components/LoadingSpinner";
import EventosBusqueda from './Components/EventosBusqueda';
import EventosDetalles from './Components/EventosDetalles';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InputGroup from 'react-bootstrap/InputGroup';
import CanvasFoto from './Components/CanvasFoto';
import TimePicker from 'react-bootstrap-time-picker';
import SearchGoogleMaps from './Components/SearchGoogleMaps';

function CrearEventoUpdate(){

const navigate = useNavigate();
let itop = 0;

const [items2, setItems2] = useState();
const [counter, setCounter] = useState(0);
const [isLoading, setIsLoading] = useState(false);
const [isVisible, setIsVisble] = useState('');
const [caractetisticas, setcaractetisticas] = useState();
const [vcaracteristica, setvcaracteristica] = useLocalStorage('vcaracteristica','');
const [imagenSeleccionadaPerfilGrupo, setimagenSeleccionadaPerfilGrupo] = useLocalStorage('imagenSeleccionadaPerfilGrupo','');
const [imagenSeleccionadaPerfilGrupoADM, setimagenSeleccionadaPerfilGrupoADM] = useLocalStorage('imagenSeleccionadaPerfilGrupoADM','');
const [imagen, setImagen] = useLocalStorage('imagen',[]);
const [imagen2, setImagen2] = useLocalStorage('imagen2','');
const [vnombregrupo, setvnombregrupo] = useLocalStorage('vnombregrupo','');
const [parametro1, setparametro1] = useLocalStorage('parametro1','');
const [parametro2, setparametro2] = useLocalStorage('parametro2','');
const [parametro3, setparametro3] = useLocalStorage('parametro3','');
const [parametro4, setparametro4] = useLocalStorage('parametro4',false);
const [tabIndex, setTabIndex] = useLocalStorage('tabIndex',0);
const [items, setItems] = useState();
const [fecha,setfecha] = useState(new Date())
const [fecharegistro, setfecharegistro] = useLocalStorage('fecharegistro','')
const [codigogrupoactual, setcodigogrupoactual] = useLocalStorage('codigogrupoactual','');
const [tituloevento, settituloevento] = useState('')
const [descripcionevento, setdescripcionevento] = useState('')
const [fechaInicio, setfechainicio] = useState('')
const [fechaFin, setfechafin] = useState('')
const [horaini, sethoraini] = useState(0)
const [horafin, sethorafin] = useState(0)
const [direccion, setdireccion] = useState('')
const [latitud, setlatitud] = useState('')
const [longitud, setlongitud] = useState('')
const [participantes, setparticipantes] = useState(0)
const [fotoportada, setfotoportada] = useState('')
const [tienecosto, settienecosto] = useState(false)
const [notienecosto, setnotienecosto] = useState(false)
const [precio, setprecio] = useState('')
const [metodopagoyape, setmetodopagoyape] = useState(false);
const [metodopagoplin, setmetodopagoyplin] = useState(false);
const [metodopagotransferencia, setmetodopagotransferencia] = useState(false);
const [numerocuenta, setnumerocuenta] = useState('');
const [moneda, setmoneda] = useState('')
const [vWidth, setWidth] = useState('');  
const [vHeight, setHeight] = useState('');
const [idusuario, setIdusuario] = useLocalStorage('idusuario','')
const [eventoActual, setEventoActual] = useState(null)

function isCanvasEmpty(canvas) {
    const context = canvas.getContext('2d');
    const pixelBuffer = new Uint32Array(context.getImageData(0, 0, canvas.width, canvas.height).data.buffer);
    return !pixelBuffer.some(color => color !== 0);
}

const getEvento = async () => {
        
    const vEventoActual = JSON.parse(sessionStorage.getItem("EventoActual"));

    setEventoActual(vEventoActual)

    if(vEventoActual != '')
    {
        let fd = new FormData() 
        fd.append("pid",vEventoActual)

        const response = await axios.post('https://conectate-peru.com/api/ApiListarEventosId.php',fd);

        if(response.data != 0)
        {

        response.data.items.map((item,index) =>{

            const options = { year: 'numeric', month: 'long', day: 'numeric' };

            const fechaFormateada = fecha.toLocaleDateString('es-ES', options);

            setfecharegistro(fechaFormateada)            
            settituloevento(item.Titulo)
            setdescripcionevento(item.Descripcion)
            setfechainicio(item.FechaInicio)
            setfechafin(item.FechaFin)
            sethoraini(item.HoraInicio)
            sethorafin(item.HoraFin)
            setlatitud(item.Latitud)
            setlongitud(item.Longitud)
            setparticipantes(item.Participantes)
            setmoneda(item.MonedaPrecio)
            //setImagenPortada(item.ImagenPortada)
            setnumerocuenta(item.NumeroCuenta)
            setdireccion(item.Direccion)
            setmetodopagoyape((item.Yape == 1) ? true : false)
            setmetodopagoyplin((item.Plin == 1) ? true : false)
            setmetodopagotransferencia((item.Transferencia == 1) ? true : false)
            setdireccion(item.Direccion)
            if(item.Costo == 1)
            {
                settienecosto(true)
            }else{
                setnotienecosto(true)
            }
            
            setprecio(item.Precio)
            setcodigogrupoactual(item.idGrupo)
            setIdusuario(item.idusuario)
            //sessionStorage.setItem("LatitudActualSeleccion",JSON.stringify(item.Latitud));
            //sessionStorage.setItem("LongitudActualSeleccion",JSON.stringify(item.Longitud));
            setimagenSeleccionadaPerfilGrupo(item.ImagenPortada)
            
            
        })           
        }
    }
}

const registroevento = async () => {

        if(codigogrupoactual != '')
        {   

            const horas = Math.floor(horaini / 3600);
            const minutos = Math.floor((horaini % 3600) / 60);

            const horasfin = Math.floor(horafin / 3600);
            const minutosfin = Math.floor((horafin % 3600) / 60);
            
            const horaFormateada = `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`;
            const horaFormateadafin = `${horasfin.toString().padStart(2, '0')}:${minutosfin.toString().padStart(2, '0')}`;

            const Lat = sessionStorage.getItem("CoordinatesLat");
            const Lng = sessionStorage.getItem("CoordinatesLng");
            const Dir = sessionStorage.getItem("address");

            const imgPortada = document.getElementById('canvas-foto1');
            // let context = imgFinal.getContext('2d');
            let dataURL = "";
            
            if (imgPortada && !isCanvasEmpty(imgPortada)) {
               
                dataURL = imgPortada.toDataURL("image/webp");
            } else {
                dataURL = imagenSeleccionadaPerfilGrupo;
            }
            
            const NewLat = JSON.parse(Lat); 
            const NewLng = JSON.parse(Lng); 
            const NewDir = JSON.parse(Dir); 

            const data = JSON.stringify({
                ptitulo : tituloevento,pdescripcion: descripcionevento,pfechaini : fechaInicio,pfechafin : fechaFin,phoraini : horaFormateada,phorafin:horaFormateadafin, pdireccion:NewDir,platitud: NewLat, plongitud: NewLng, pparticipantes: participantes, imagenportada: dataURL,pcosto:(tienecosto == true ? 1 : 0),pmonedaprecio:moneda,pprecio:precio,pyape:(metodopagoyape == true ? 1 : 0),pplin:(metodopagoplin == true ? 1 : 0),ptransferencia:(metodopagotransferencia == true ? 1 : 0),pnumerocuenta:numerocuenta,pidgrupo:codigogrupoactual,pidusuario:idusuario,pid:eventoActual,tipo:2
               })
        
            
               await fetch('https://conectate-peru.com/api/ApiRegistroGrupoAnuncio.php', {
                method: 'POST',
                body: data,
                headers: {
                   'Content-type': 'application/json; charset=UTF-8',
                },
             })
                .then((response) => response.json())
                .then((data) => {
                   //setPosts((posts) => [data, ...posts]);
                   
                   //const respuesta = JSON.parse(data);
                   alert("Evento actualizado correctamente.")
                   const respuesta = data;
                   limpiar()
                   navigate(-1)
                })
                .catch((err) => {
                   console.log(err.message);
                });
        }
  }

  const fileInputRef = useRef(null);
  const handleOpenFileClick = () => {
    fileInputRef.current.click();
  };

  function niceBytes(a)
  {
    var marker = 1024; // Change to 1000 if required
    var decimal = 3; // Change as required
    var kiloBytes = marker; // One Kilobyte is 1024 bytes
    var megaBytes = marker * marker; // One MB is 1
    return(a / megaBytes).toFixed(decimal);
  }


  const limpiar = () => {

    //localStorage.removeItem('imagenSeleccionadaPerfilGrupoADM')
    sessionStorage.removeItem('CoordinatesLat')
    sessionStorage.removeItem('CoordinatesLng')
    sessionStorage.removeItem('address')
}

  async function convertImage(e)
  {
  
      const files = e.target.files;
      const filePeso = 0;
  
      for (let index = 0; index < files.length; index++) {
          const reader = new FileReader();
      let nWidth = 0;
      let nHeigth = 0;
      
      
      let src = URL.createObjectURL(e.target.files[index]);
      const divcanvas = document.getElementById('wimagen');
      //reader.readAsDataURL(e.target.files[0]);
  
      if(niceBytes(e.target.files[index].size) >= 32.0)
      {
          alert("Imagen excede el tamaño permitido. El peso debe ser menor a 32Mb.")
          return;
      }
  
      reader.readAsDataURL(e.target.files[index]);
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          const {
            height,
            width
          } = image;
          
          setHeight(height);
          setWidth(width);
          if(src.length > 1)
          {
            setImagen(imagen => [...imagen,{ id:Math.random() + index,src:src, height:height, width: width}])

          }
        };
      };  
      }
      

     

      const fileAnun = document.getElementById('fileAnuncio');
      fileAnun.value = '';

      
  }


useEffect(() => {
    getEvento()
    },[])

    const handleClick1  = (e) => 
    {

        if(counter >= 0)
        {
        setCounter(counter + 4);

        //fetchApi(counter + 4);
        }
        
        //console.log(counter);
    
    }

    const handleClick2  = (e) => 
    {

        if(counter > 0)
        {
        setCounter(counter - 4);
        
        //fetchApi(counter - 4);
        }
        //console.log(counter);
        
    }

    const handleRedirectHome = () =>{

        navigate(-1);
    
      }

      const handleGoPaso2 = () =>{

        navigate("/CreandoGrupo2");
    
      }

      const handleCLickTab = (index) =>
      {
          setTabIndex(index)
      }
   
      function soloNumeros(number)
      {
          
          //let newValor = number.replace(/[^0-9]|^E/i, "");
          let newValor = number.replace(/\D/g, "");
          
          return newValor;
      }
  
       function formatNumber(number)
       {
  
          let newValor = number.replace(/,/g, "");
  
  
          return new Intl.NumberFormat("EN-US").format(newValor);
       }

       const handleTimeChange = (time) => {
        sethoraini(time);
      };

      const handleTimeChange2 = (time) => {
        sethorafin(time);
      };

      const handleSubmit = (e) => {
        e.preventDefault();
        registroevento();
        // Convertir el tiempo numérico en HH:mm
        
        
        


      };

return(<>

    <Layout>
        <Container style={{paddingTop:'calc(5em + 5vw)'}}>
            <Row>
                <Col sm={3}>
                    <img src={imgBack} style={{float:'right',cursor:'pointer'}} onClick={handleRedirectHome}></img>
                </Col>
                <Col sm={6}>
                <p style={{fontSize:'12pt',color:'#FF2D52'}}>Volver</p>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                   
                </Col>
                <Col sm={4}>
                <Container>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                            <center><b style={{fontSize:'16pt'}}>Vamos a crear un evento</b></center>
                            </Col>
                        </Row>
                        <Row style={{paddingTop:'calc(1em + 1vw)'}}>
                            <Col>
                            <b style={{fontSize:'14pt',color:'#412F8C'}}>Datos del evento</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                            <Form.Label style={{fontSize:'10pt',color:'black'}}>Titulo del evento</Form.Label>
                            <Form.Control
                                type="text"
                                value={tituloevento}
                                onChange={(e) => settituloevento(e.target.value)}
                                required
                                style={{fontSize:'9pt',color:'black'}}
                            />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                            <Form.Label style={{fontSize:'10pt',color:'black'}}>Descripción del evento</Form.Label>
                            <Form.Control
                                as="textarea" 
                                rows={5}
                                value={descripcionevento}
                                onChange={(e) => setdescripcionevento(e.target.value)}
                                required
                                style={{fontSize:'9pt',color:'black'}}
                            />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                            <Form.Label style={{fontSize:'10pt',color:'black'}}>Fecha Inicio</Form.Label>
                            <Form.Control
                                type="Date"
                                value={fechaInicio}
                                onChange={(e) => setfechainicio(e.target.value)}
                                required
                                style={{fontSize:'9pt',color:'black'}}
                            />
                            </Form.Group>
                            </Col>
                            <Col sm={6}>
                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                            <Form.Label style={{fontSize:'10pt',color:'black'}}>Fecha Fin</Form.Label>
                            <Form.Control
                                type="Date"
                                value={fechaFin}
                                onChange={(e) => setfechafin(e.target.value)}
                                required
                                style={{fontSize:'9pt',color:'black'}}
                            />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                            <Form.Label style={{fontSize:'10pt',color:'black'}}>Hora Inicio</Form.Label>
                            <TimePicker
                                start="00:00"
                                end="23:59"
                                step={15}
                                value={horaini}
                                onChange={handleTimeChange}
                                style={{fontSize:'9pt',color:'black'}}
                                />
                            </Form.Group>
                            </Col>
                            <Col sm={6}>
                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                            <Form.Label style={{fontSize:'10pt',color:'black'}}>Hora Fin</Form.Label>
                            <TimePicker
                                start="00:00"
                                end="23:59"
                                step={15}
                                value={horafin}
                                onChange={handleTimeChange2}
                                style={{fontSize:'9pt',color:'black'}}
                                />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                            <Form.Label style={{fontSize:'10pt',color:'black'}}>Dirección</Form.Label>
                            <SearchGoogleMaps></SearchGoogleMaps>
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                            <Form.Label style={{fontSize:'10pt',color:'black'}}>Límite de participantes</Form.Label>
                            <Form.Control
                                type="number"
                                value={participantes}
                                onChange={(e) => setparticipantes(soloNumeros(e.target.value))}
                                required
                                style={{fontSize:'9pt',color:'black'}}
                            />
                            </Form.Group>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col sm={12}>
                            <p style={{fontSize:'8pt'}}>Foto de portada del evento</p>
                            </Col>
                        </Row>
                        <Row>
                        <Col sm={4}>
                                <div className='bgperfil'>
                         
                                {!imagen ? 'Cargando imagen portada' : imagen.map( (item,index) => {
                                        /*<Canvas src={item.src} width={item.width} height={item.height} ></Canvas>*/
                                        return(<>
                                        <CanvasFoto src={item.src} width={item.width} height={item.height} index='1'></CanvasFoto>
                                        </>
                                        )
                                    }) 
                                    }
                                    <img src={imagenSeleccionadaPerfilGrupo} style={{height:'150px',width:'150px',backgroundSize: 'cover',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',index:'1',display:(imagen == null || imagen == '') ? 'block' : 'none'}}></img>

                                    <div style={{borderTop:'1px solid #9c9c9c',bottom:'0',height:'20px',width:'100%',position:'absolute',borderBottomRightRadius:'10px',borderBottomLeftRadius:'10px',background: 'linear-gradient(to right,rgba(255,255,255,0), #9c9c9c)',zIndex:'1',cursor:'pointer'}} onClick={handleOpenFileClick}>
                                        <center><FaCamera style={{cursor:'pointer'}}></FaCamera></center>
                                        <input id="fileAnuncio" type="file" ref={fileInputRef}
                                            style={{ display: 'none' }} accept="image/png,image/jpeg,image/jpe"
                                        onChange={(e) => convertImage(e)}/>
                                    </div>
                                  
                                </div>
                                <br></br>
                            </Col>
                            <Col sm={4}>
                            </Col>
                            <Col sm={4}>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                            <p style={{fontSize:'14pt',color:'#412F8C'}}>¿El evento tendra costo?</p>
                            </Col>
                            <Col sm={12}>
                                <Container>
                                    <Row>
                                    <Col sm={2} style={{float:'left'}}>
                                    <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                                    <Form.Check
                                        type="checkbox"
                                        label='Si'
                                        checked={tienecosto}
                                        onChange={(e) => settienecosto(e.target.checked)}
                                        style={{fontSize:'11pt',color:'black'}}
                                    />
                                    </Form.Group>
                                    </Col>
                                    <Col sm={2} style={{float:'left'}}>
                                    <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                                    <Form.Check
                                        type="checkbox"
                                        label='No'
                                        checked={notienecosto}
                                        onChange={(e) => setnotienecosto(e.target.checked)}
                                        style={{fontSize:'11pt',color:'black'}}
                                    />
                                    </Form.Group>
                                    </Col>
                                    <Col sm={8}>
                                    </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                            <Form.Group>
                        <Form.Label style={{fontSize:'10pt',color:'black'}}>Precio</Form.Label>
                        <InputGroup className="sm-3">
                        <Container style={{padding:'0 0 0 calc(0.4em + 0.4vw)'}}>
                            <Row>
                                <Col sm={2} style={{padding:'0 0 0 0'}}>
                                <Form.Select 
                                    value={moneda}
                                    onChange={(e) => setmoneda(e.target.value)}
                                    className='tipoMOneda'
                                >
                                <option Select value='0'>Seleccione</option>
                                <option value='S/.'>S/.</option>
                                <option value='$'>$</option>
                                </Form.Select>
                                </Col>
                                <Col sm={10} style={{padding:'0 0 0 0'}} >
                                <Form.Control
                                    value={precio}
                                    type='text'
                                    maxLength='9'
                                    onChange={(e) => setprecio(formatNumber(soloNumeros(e.target.value)))}
                                    style={{fontSize:'10pt',color:'black'}}
                                    //required={(visibleProyecto=='visible'?false:true)}
                                    />
                                </Col>
                            </Row>
                        </Container>                        
                        </InputGroup>
                        </Form.Group>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                           
                            <Col sm={12}>
                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                            <Form.Check
                                type="checkbox"
                                label='Yape'
                                checked={metodopagoyape}
                                onChange={(e) => setmetodopagoyape(e.target.checked)}
                                style={{fontSize:'10pt',color:'black'}}
                            />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                            <Form.Check
                                type="checkbox"
                                label='Plin'
                                checked={metodopagoplin}
                                onChange={(e) => setmetodopagoyplin(e.target.checked)}
                                style={{fontSize:'10pt',color:'black'}}
                            />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                            <Form.Check
                                type="checkbox"
                                label='Transferencia'
                                checked={metodopagotransferencia}
                                onChange={(e) => setmetodopagotransferencia(e.target.checked)}
                                style={{fontSize:'10pt',color:'black'}}
                            />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                            <Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
                            <Form.Label style={{fontSize:'10pt',color:'black'}}>Numero de cuenta CC/CCI</Form.Label>
                            <Form.Control
                                type="text"
                                value={numerocuenta}
                                onChange={(e) => setnumerocuenta(e.target.value)}
                                disabled={!metodopagotransferencia}
                                style={{fontSize:'10pt',color:'black'}}
                            />
                            </Form.Group>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col sm={12}>
                            <Button variant='primary3' type='submit'>
                                    <b style={{fontSize:'12pt'}}>Actualizar Evento</b>
                                    </Button>
                            </Col>
                        </Row>
                        
                        {/*<Row>
                            <Col sm={12}>
                                <center>
                                    <a style={{fontSize:'12pt',cursor:'pointer'}}>Cancelar</a>
                                </center>
                            </Col>
                        </Row>*/}
                    </Form>
                </Container>
                </Col>
                <Col sm={4}>
                
                </Col>
            </Row>

        </Container>
    </Layout>



</>);


}

export default CrearEventoUpdate;